import { FC, ReactElement, useState } from "react";

type OwnProps = {
  defaultHour?: string;
  defaultMinute?: string;
  onChange: (hour: string, minute: string) => void;
};

const TimeSelector: FC<OwnProps> = (props): ReactElement => {
  const { defaultHour, defaultMinute, onChange } = props;
  const [hour, setHour] = useState(defaultHour ?? "00");
  const [minute, setMinute] = useState(defaultMinute ?? "00");

  const hours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );

  const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHour(e.target.value);
    onChange(e.target.value, minute);
  };

  const handleMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMinute(e.target.value);
    onChange(hour, e.target.value);
  };

  return (
    <div className="flex space-x-2 items-center">
      <select
        className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        value={hour}
        onChange={handleHourChange}
      >
        {hours.map((h) => (
          <option key={h} value={h}>
            {h}
          </option>
        ))}
      </select>
      <span className="text-gray-400">:</span>
      <select
        className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        value={minute}
        onChange={handleMinuteChange}
      >
        {minutes.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeSelector;

import { ConnectedProps, connect } from "react-redux";
import { FC, ReactElement, useState } from "react";
import { openModal, showNotification } from "../../redux/currentApp/slice";

import EditNote from "./EditNote";
import { FormNote } from "../../models/Form/FormNote";
import SafeHTMLText from "../texts/SafeHTMLText";
import UserAvatar from "../users/UserAvatar";
import { formatISODateStr } from "../../utils/dates";
import { getAvatarUrl } from "../../utils/user";
import { useTranslation } from "react-i18next";

const mapDispatchToProps = {
  openModal,
  notify: showNotification
};

type OwnProps = {
  documentId: string;
  note: FormNote;
  canEdit: boolean;
};

const NoteItem: FC<OwnProps & ReduxProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { documentId, note, canEdit = false, openModal, notify } = props;
  const { createdBy, body, createdAt, modifiedAt } = note;

  const [isEditing, setIsEditing] = useState(false);

  const fullName = `${createdBy.name.first} ${createdBy.name.last}`;
  const avatarUrl =
    createdBy?.holaMember?.avatarUrl != null
      ? getAvatarUrl(createdBy.holaMember)
      : undefined;

  const openDeleteResourceNoteModal = () => {
    openModal({
      modal: {
        component: "DeleteNoteModal",
        props: {
          documentId,
          note,
          notify
        }
      }
    });
  };

  return (
    <li key={note._id}>
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          <div
            className={`h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white flex items-center justify-center`}
          >
            <UserAvatar name={createdBy.name} pictureUrl={avatarUrl} size={6} />
          </div>
        </div>
        <div className="w-full">
          <div className="text-sm">
            <span className="font-medium text-gray-900">{fullName}</span>
          </div>
          <div className="mt-1 text-sm text-gray-700">
            {!isEditing ? (
              <SafeHTMLText html={body} />
            ) : (
              <EditNote
                documentId={documentId}
                note={note}
                onCancel={() => setIsEditing(false)}
                notify={notify}
              />
            )}
          </div>
          <div className="mt-2 space-x-2 text-sm flex items-center">
            <span className="font-medium text-gray-500">
              <time
                className="ml-1 font-medium text-gray-900"
                dateTime={createdAt}
              >
                {formatISODateStr(createdAt)}
              </time>
            </span>
            {modifiedAt && (
              <>
                <span className="font-medium text-gray-500 mx-2">&middot;</span>
                <span className="font-medium text-gray-500">
                  {t("common.datetime.edited")}{" "}
                  <time
                    className="ml-1 font-medium text-gray-900"
                    dateTime={modifiedAt}
                  >
                    {formatISODateStr(modifiedAt)}
                  </time>
                </span>
              </>
            )}
            {canEdit && (
              <div className="flex items-center">
                <span className="font-medium text-gray-500 mx-2">&middot;</span>
                <button
                  type="button"
                  className="font-medium text-gray-900"
                  onClick={() => setIsEditing(!isEditing)}
                >
                  {t("common.actions.edit")}
                </button>
                <span className="font-medium text-gray-500 mx-2">&middot;</span>
                <button
                  type="button"
                  className="font-medium text-gray-900"
                  onClick={openDeleteResourceNoteModal}
                >
                  {t("common.actions.delete")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(NoteItem);

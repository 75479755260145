import Axios, { CancelTokenSource } from "axios";

import { call, put, takeLatest } from "@redux-saga/core/effects";

import { FunctionalCompetence, GenericCompetence } from "../../models/Form/BaseCompetence";
import { DevelopmentActionTheme } from "../../models/Form/BaseDevelopmentAction";
import { ObjectiveType } from "../../models/Form/BaseObjective";
import { ValueType } from "../../models/Form/BaseValue";
import { Cycle } from "../../models/Form/Cycle";
import { getFunctionalCompetences, getGenericCompetences } from "../../services/CompetenceService";
import { getCyclesList } from "../../services/CycleService";
import { getDevelopmentActionThemes } from "../../services/DevActionService";
import { getObjectiveTypes } from "../../services/ObjectiveService";
import { getValueTypes } from "../../services/ValueService";
import {
  loadCyclesList, loadCyclesListError, loadCyclesListSuccess, loadDevelopmentActionThemes,
  loadDevelopmentActionThemesError, loadDevelopmentActionThemesSuccess, loadFunctionalCompetences,
  loadFunctionalCompetencesError, loadFunctionalCompetencesSuccess, loadGenericCompetences,
  loadGenericCompetencesError, loadGenericCompetencesSuccess, loadObjectivesTypes,
  loadObjectivesTypesError, loadObjectivesTypesSuccess, loadValueTypes, loadValueTypesError,
  loadValueTypesSuccess
} from "./slice";

function* getCyclesListSaga(action: ReturnType<typeof loadCyclesList>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();

  try {
    const data: Cycle[] = yield call(getCyclesList, signal.token);

    // Filter out cycles that are older than 2021
    const currentCycles = data.filter((cycle) => {
      return cycle.year >= 2021;
    });

    yield put(loadCyclesListSuccess({ data: currentCycles }));
  } catch (error: any) {
    yield put(loadCyclesListError(error));
  }
}

function* getObjectivesTypesSaga(
  action: ReturnType<typeof loadObjectivesTypes>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();

  try {
    const data: ObjectiveType[] = yield call(getObjectiveTypes, signal.token);

    yield put(loadObjectivesTypesSuccess({ data }));
  } catch (error: any) {
    yield put(loadObjectivesTypesError(error));
  }
}

function* getGenericCompetencesSaga(
  action: ReturnType<typeof loadGenericCompetences>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { profileId } = action.payload;

  try {
    const data: GenericCompetence[] = yield call(
      getGenericCompetences,
      signal.token,
      profileId
    );

    yield put(loadGenericCompetencesSuccess({ data }));
  } catch (error: any) {
    yield put(loadGenericCompetencesError(error));
  }
}

function* getFunctionalCompetencesSaga(
  action: ReturnType<typeof loadFunctionalCompetences>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { profileId } = action.payload;

  try {
    const data: FunctionalCompetence[] = yield call(
      getFunctionalCompetences,
      signal.token,
      profileId
    );

    yield put(loadFunctionalCompetencesSuccess({ data }));
  } catch (error: any) {
    yield put(loadFunctionalCompetencesError(error));
  }
}

function* getDevelopmentActionThemesSaga(
  action: ReturnType<typeof loadDevelopmentActionThemes>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();

  try {
    const data: DevelopmentActionTheme[] = yield call(
      getDevelopmentActionThemes,
      signal.token
    );

    yield put(loadDevelopmentActionThemesSuccess({ data }));
  } catch (error: any) {
    yield put(loadDevelopmentActionThemesError(error));
  }
}

function* getValueTypesSaga(action: ReturnType<typeof loadValueTypes>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();

  try {
    const data: ValueType[] = yield call(getValueTypes, signal.token);

    yield put(loadValueTypesSuccess({ data }));
  } catch (error: any) {
    yield put(loadValueTypesError(error));
  }
}

export default function* currentAppSaga() {
  yield takeLatest(loadCyclesList.type, getCyclesListSaga);
  yield takeLatest(loadObjectivesTypes.type, getObjectivesTypesSaga);
  yield takeLatest(loadGenericCompetences.type, getGenericCompetencesSaga);
  yield takeLatest(
    loadFunctionalCompetences.type,
    getFunctionalCompetencesSaga
  );
  yield takeLatest(
    loadDevelopmentActionThemes.type,
    getDevelopmentActionThemesSaga
  );
  yield takeLatest(loadValueTypes.type, getValueTypesSaga);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorAction } from "../../models/Actions";
import { FunctionalCompetence, GenericCompetence } from "../../models/Form/BaseCompetence";
import { DevelopmentActionTheme } from "../../models/Form/BaseDevelopmentAction";
import { ObjectiveType } from "../../models/Form/BaseObjective";
import { ValueType } from "../../models/Form/BaseValue";
import { Cycle } from "../../models/Form/Cycle";
import { ModalConfig } from "../../models/Modal";
import { NotificationConfig } from "../../models/Notification";
import { RequestStatus } from "../../models/Request";

interface CurrentAppState {
  status: RequestStatus;
  error?: Error;

  // Modals
  modal?: ModalConfig;

  // Notifications
  notification?: NotificationConfig;

  // Cycles
  cycles?: Cycle[];
  currentCycle?: Cycle;

  // Common document data
  objectiveTypes?: ObjectiveType[];
  genericCompetences?: GenericCompetence[];
  functionalCompetences?: FunctionalCompetence[];
  developmentActionThemes?: DevelopmentActionTheme[];
  valueTypes?: ValueType[];
}

const initialState: CurrentAppState = {
  status: RequestStatus.IDLE
};

const currentAppSlice = createSlice({
  name: "currentApp",
  initialState,
  reducers: {
    loadCyclesList(state) {
      state.status = RequestStatus.PENDING;
    },
    loadCyclesListSuccess(state, action: PayloadAction<{ data: Cycle[] }>) {
      const { data } = action.payload;
      state.cycles = data;
      state.currentCycle = data[0];
      state.status = RequestStatus.SUCCESS;
    },
    loadCyclesListError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    },
    setCurrentCycle(state, action: PayloadAction<{ cycle: Cycle }>) {
      const { cycle } = action.payload;
      state.currentCycle = cycle;
    },

    // Modals
    openModal(state, action: PayloadAction<{ modal: ModalConfig }>) {
      const { modal } = action.payload;
      state.modal = modal;
    },
    closeModal(state) {
      state.modal = undefined;
    },

    // Notifications
    showNotification(state, action: PayloadAction<NotificationConfig>) {
      state.notification = action.payload;
    },
    closeNotification(state) {
      state.notification = undefined;
    },

    // Common document data
    loadObjectivesTypes(state) {
      state.status = RequestStatus.PENDING;
    },
    loadObjectivesTypesSuccess(
      state,
      action: PayloadAction<{ data: ObjectiveType[] }>
    ) {
      const { data } = action.payload;
      state.objectiveTypes = data;
      state.status = RequestStatus.SUCCESS;
    },
    loadObjectivesTypesError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    },
    loadGenericCompetences(
      state,
      action: PayloadAction<{ profileId: string }>
    ) {
      state.status = RequestStatus.PENDING;
    },
    loadGenericCompetencesSuccess(
      state,
      action: PayloadAction<{ data: GenericCompetence[] }>
    ) {
      const { data } = action.payload;
      state.genericCompetences = data;
      state.status = RequestStatus.SUCCESS;
    },
    loadGenericCompetencesError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    },
    loadFunctionalCompetences(
      state,
      action: PayloadAction<{ profileId: string }>
    ) {
      state.status = RequestStatus.PENDING;
    },
    loadFunctionalCompetencesSuccess(
      state,
      action: PayloadAction<{ data: FunctionalCompetence[] }>
    ) {
      const { data } = action.payload;
      state.functionalCompetences = data;
      state.status = RequestStatus.SUCCESS;
    },
    loadFunctionalCompetencesError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    },
    loadDevelopmentActionThemes(state) {
      state.status = RequestStatus.PENDING;
    },
    loadDevelopmentActionThemesSuccess(
      state,
      action: PayloadAction<{ data: DevelopmentActionTheme[] }>
    ) {
      const { data } = action.payload;
      state.developmentActionThemes = data;
      state.status = RequestStatus.SUCCESS;
    },
    loadDevelopmentActionThemesError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    },
    loadValueTypes(state) {
      state.status = RequestStatus.PENDING;
    },
    loadValueTypesSuccess(state, action: PayloadAction<{ data: ValueType[] }>) {
      const { data } = action.payload;
      state.valueTypes = data;
      state.status = RequestStatus.SUCCESS;
    },
    loadValueTypesError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    }
  }
});

export const {
  loadCyclesList,
  loadCyclesListSuccess,
  loadCyclesListError,
  setCurrentCycle,
  openModal,
  closeModal,
  loadObjectivesTypes,
  loadObjectivesTypesSuccess,
  loadObjectivesTypesError,
  showNotification,
  closeNotification,
  loadGenericCompetences,
  loadGenericCompetencesSuccess,
  loadGenericCompetencesError,
  loadFunctionalCompetences,
  loadFunctionalCompetencesSuccess,
  loadFunctionalCompetencesError,
  loadDevelopmentActionThemes,
  loadDevelopmentActionThemesSuccess,
  loadDevelopmentActionThemesError,
  loadValueTypes,
  loadValueTypesSuccess,
  loadValueTypesError
} = currentAppSlice.actions;

export default currentAppSlice.reducer;

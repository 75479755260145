import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/solid";

const EmptyNotesList: FC = (): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "notes"
  });

  return (
    <div className="text-center">
      <ChatBubbleLeftEllipsisIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {t("noNotes")}
      </h3>
    </div>
  );
};

export default EmptyNotesList;

import { FC, ReactElement } from "react";

type OwnProps = {
  name: {
    first: string;
    last: string;
  };
  pictureUrl?: string;
  showName?: boolean;
  showStatus?: boolean;
  size?: number;
  additionalText?: string;
};

const UserAvatar: FC<OwnProps> = (props): ReactElement => {
  const {
    name,
    pictureUrl,
    showName = false,
    showStatus = false,
    size = 6,
    additionalText
  } = props;
  const fullName = `${name.first} ${name.last}`;
  const initials = `${name.first[0]}${name.last[0]}`;

  return (
    <div className="flex items-center gap-x-3">
      {pictureUrl != null ? (
        <span className="relative inline-block">
          <img
            className={`h-${size} w-${size} rounded-full bg-gray-50 ring-2 ring-white`}
            src={pictureUrl}
            alt={fullName}
          />
          {showStatus && (
            <span className="absolute bottom-0 right-0 block h-1.5 w-1.5 rounded-full bg-green-500 ring-2 ring-white" />
          )}
        </span>
      ) : (
        <div className="relative inline-block">
          <div
            className={`h-${size} w-${size} rounded-full bg-gray-50 ring-2 ring-white flex items-center justify-center`}
          >
            <span
              className="text-sm font-medium leading-5 text-gray-500"
              title={fullName}
            >
              {initials}
            </span>
          </div>
          {showStatus && (
            <span className="absolute bottom-0 right-0 block h-1.5 w-1.5 rounded-full bg-green-500 ring-2 ring-white" />
          )}
        </div>
      )}
      <div>
        {showName && (
          <p className="text-sm font-medium leading-5 text-gray-900">
            {fullName}
          </p>
        )}
        {additionalText && (
          <p className="text-xs font-medium leading-5 text-gray-500">
            {additionalText}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserAvatar;

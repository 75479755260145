import { createSelector } from "reselect";

import { RequestStatus } from "../../models/Request";
import { RootState } from "../../store/store";

const selectCurrentUserState = (state: RootState) => state.currentUser;

export const selectCurrentUser = createSelector(
  selectCurrentUserState,
  (state) => state.data
);

export const selectCurrentUserStatus = createSelector(
  selectCurrentUserState,
  (state) => state.status
);

export const isLoadingCurrentUser = createSelector(
  selectCurrentUserState,
  (state) => state.status === RequestStatus.PENDING
);

export const selectCurrentUserError = createSelector(
  selectCurrentUserState,
  (state) => state.error || state.status === RequestStatus.ERROR
);

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { useActivityLogs } from "../../hooks/activities/useActivityLogs";
import { ActivityLogResourceType } from "../../models/Logs/ActivityLog";
import ActivityLogItem from "./ActivityLogItem";

type OwnProps = {
  documentId: string;
  resourceType?: ActivityLogResourceType;
  resourceId?: string;
  className?: string;
};

const ActivityLogsList: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", { keyPrefix: "activities" });
  const {
    documentId,
    resourceType,
    resourceId,
    className = "lg:col-span-1 lg:col-start-3",
  } = props;

  const { isLoading, data: activities = [] } = useActivityLogs(
    documentId,
    resourceType,
    resourceId
  );

  return (
    <section aria-labelledby="timeline-title" className={className}>
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          {t("title")}
        </h2>

        {/* Activity Feed */}
        <div className="mt-6 flow-root max-h-96 overflow-y-auto scrollbar-hide">
          <ul className="-mb-8">
            {activities.map((item, index) => (
              <ActivityLogItem
                key={item._id}
                activity={item}
                isLast={index === activities.length - 1}
              />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ActivityLogsList;

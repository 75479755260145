import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";

import { useAddCoach } from "../../hooks/coachs/useAddCoach";
import { useCoachs } from "../../hooks/coachs/useCoachs";
import { Employee } from "../../models/Employee/Employee";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import WarningBanner from "../banners/WarningBanner";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";
import EmployeeSelector from "../selectors/EmployeeSelector";

const mapDispatchToProps = {
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  excludeIds: string[];
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const AddDocumentCoachModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "form"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { documentId, excludeIds, notify, closeModal } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [relatedCoach, setRelatedCoach] = useState<Employee | null>(null);

  const { data: coachs = [] } = useCoachs();
  const mutation = useAddCoach(documentId);

  useEffect(() => {
    if (mutation.isPending) return;

    if (mutation.isError) {
      setErrorMessage(mutation.error.message);
    }

    if (mutation.isSuccess) {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.requestSent"),
        message: t("addCoach.messages.success")
      });

      // Cleanup
      closeModal();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    mutation.isPending,
    mutation.isSuccess,
    mutation.isError,
    mutation.error,
    closeModal,
    queryClient,
    documentId,
    notify,
    t,
    tCommon
  ]);

  const handleCoachSelection = (item: Employee) => {
    if (excludeIds.includes(item._id)) {
      setErrorMessage(t("addCoach.messages.alreadyAdded"));
      return;
    }

    setRelatedCoach(item);
  };

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="sm:flex sm:items-start">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t("addCoach.title")}
              </h2>
              <p className="mt-1 mb-2 text-sm leading-6 text-gray-600">
                {t("addCoach.subtitle")}
              </p>
              <WarningBanner message={t("addCoach.warning")} />
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/* Coach selector */}
                <div className="col-span-full">
                  <label
                    htmlFor="objectiveType"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("addCoach.fields.coach")}
                  </label>
                  <EmployeeSelector
                    employeeOptions={coachs as Employee[]}
                    defaultLabel={tCommon("selectors.coach")}
                    onSelectEmployee={handleCoachSelection}
                  />
                </div>
              </div>
              {errorMessage != null && (
                <div className="mt-5">
                  <ErrorBanner message={errorMessage} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className={classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
              mutation.isPending ? "cursor-not-allowed opacity-50 ml-2" : ""
            )}
            disabled={mutation.isPending}
            onClick={() => {
              if (relatedCoach != null) {
                mutation.mutate(relatedCoach._id);
              }
            }}
          >
            {mutation.isPending && <Spinner size="small" />}
            {tCommon("actions.save")}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModal()}
          >
            {tCommon("actions.cancel")}
          </button>
        </div>
      </>
    </Modal>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(AddDocumentCoachModal);

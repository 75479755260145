import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { TagIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";

import { BaseValue } from "../../models/Form/BaseValue";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import {
  isLoadingUpdateDocumentValue,
  selectUpdateDocumentValueError,
  selectUpdateDocumentValueStatus
} from "../../redux/currentDocument/values/selectors";
import {
  clearUpdateDocumentValueStatus,
  updateDocumentValue
} from "../../redux/currentDocument/values/slice";
import { selectCurrentEmployeeLang } from "../../redux/currentEmployee/selectors";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import InputText from "../forms/InputText";
import InputTextArea from "../forms/InputTextArea";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";
import ValueTypeSelector from "../selectors/ValueTypeSelector";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingUpdateDocumentValue,
  status: selectUpdateDocumentValueStatus,
  formError: selectUpdateDocumentValueError,
  lang: selectCurrentEmployeeLang
});

const mapDispatchToProps = {
  updateValue: updateDocumentValue,
  clearStatus: clearUpdateDocumentValueStatus,
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  value: BaseValue;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const EditValueModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    value,
    isLoading,
    status,
    formError,
    lang,
    updateValue,
    clearStatus,
    notify,
    closeModal
  } = props;
  const { relatedType, title, description } = value;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("update.messages.success")
      });

      // Cleanup
      closeModal();
      clearStatus();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    isLoading,
    status,
    formError,
    closeModal,
    clearStatus,
    notify,
    queryClient,
    t,
    tCommon,
    documentId
  ]);

  // Component state
  const initialState = {
    title: title ?? "",
    description: description ?? ""
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .max(140, "Must be 140 characters or less")
      .required(t("form.errors.title")),
    description: Yup.string()
      .max(5000, "Must be 5000 characters or less")
      .required(t("form.errors.description"))
  });

  return (
    <Modal closeModal={closeModal}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (!relatedType) {
            setErrorMessage(t("form.errors.relatedType"));
            return;
          }

          updateValue({
            formUUID: documentId,
            valueId: value._id,
            data: {
              relatedType: relatedType._id,
              ...values
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="sm:flex sm:items-start">
              <div className="space-y-12 w-full">
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {t("update.title")}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    {t("update.subtitle")}
                  </p>
                  <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    {/* DevAction theme selector */}
                    <div className="col-span-full col-start-1">
                      <label
                        htmlFor="objectiveType"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t("fields.type")}
                      </label>
                      <ValueTypeSelector
                        lang={lang!}
                        defaultValue={relatedType}
                      />
                      {relatedType && (
                        <ul className="mt-3">
                          {relatedType.behaviors.map((behavior) => (
                            <li
                              key={behavior._id}
                              className="flex items-center"
                            >
                              <TagIcon
                                className="h-5 w-5 text-green-500"
                                aria-hidden="true"
                              />
                              <span className="ml-2 text-sm text-gray-900 italic">
                                {behavior.name[lang ?? "fr"]}
                              </span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    {/* Title */}
                    <div className="col-span-full">
                      <InputText label={t("fields.title")} name="title" />
                    </div>

                    {/* Short description */}
                    <div className="col-span-full">
                      <InputTextArea
                        label={t("fields.description")}
                        name="description"
                        helperText={t("form.helpers.description")}
                      />
                    </div>
                  </div>
                  {errorMessage != null && (
                    <div className="mt-5">
                      <ErrorBanner message={errorMessage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
                  isSubmitting ? "cursor-not-allowed opacity-50 ml-2" : ""
                )}
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size="small" />}
                {tCommon("actions.save")}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => closeModal()}
              >
                {tCommon("actions.cancel")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(EditValueModal);

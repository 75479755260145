import { ConnectedProps, connect } from "react-redux";
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import {
  isLoadingCurrentTeamlist,
  isLoadingTeamManagers,
  selectCurrentTeamlist,
  selectSelectedTeamManager,
  selectTeamManagers
} from "../../redux/currentTeamlist/selectors";
import {
  loadCurrentTeamlist,
  loadTeamManagers,
  setSelectedTeamManager
} from "../../redux/currentTeamlist/slice";

import ActionButton from "../../components/buttons/ActionButton";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import CycleSelector from "../../components/selectors/CycleSelector";
import { Employee } from "../../models/Employee/Employee";
import EmployeeSelector from "../../components/selectors/EmployeeSelector";
import { Phase } from "../../models/Form/BaseForm";
import Spinner from "../../components/loaders/Spinner";
import SubNavBar from "../../components/navigation/SubNavBar";
import SubSectionHeader from "../../components/headings/SubSectionHeader";
import TeamlistTable from "../../components/teamlist/TeamlistTable";
import { createStructuredSelector } from "reselect";
import { downloadFormMetrics } from "../../services/DownloadsService";
import { selectCurrentCycle } from "../../redux/currentApp/selectors";
import { selectCurrentEmployee } from "../../redux/currentEmployee/selectors";
import { statusLinks } from "../../utils/links";
import { useTranslation } from "react-i18next";

const mapStateToProps = createStructuredSelector({
  employee: selectCurrentEmployee,
  currentCycle: selectCurrentCycle,
  teamlist: selectCurrentTeamlist,
  teamManagers: selectTeamManagers,
  currentManager: selectSelectedTeamManager,
  isLoadingTeamlist: isLoadingCurrentTeamlist,
  isLoadingManagers: isLoadingTeamManagers
});

const mapDispatchToProps = {
  loadCurrentTeamlist,
  loadTeamManagers,
  setSelectedTeamManager
};

type OwnProps = ReduxProps;

const TeamList: FC<OwnProps> = (props): ReactElement => {
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const { t } = useTranslation();
  const {
    employee,
    currentCycle,
    teamlist,
    teamManagers,
    currentManager,
    loadCurrentTeamlist,
    loadTeamManagers,
    setSelectedTeamManager,
    isLoadingTeamlist,
    isLoadingManagers
  } = props;

  useEffect(() => {
    if (currentCycle?._id != null && teamManagers == null) {
      loadTeamManagers({
        cycleId: currentCycle._id
      });
    }
  }, [teamManagers, currentCycle?._id, loadTeamManagers]);

  useEffect(() => {
    if (currentManager == null && employee?.isManager && teamManagers != null) {
      setSelectedTeamManager({ manager: employee });
    }
  }, [currentManager, teamManagers, employee, setSelectedTeamManager]);

  useEffect(() => {
    if (currentCycle?._id != null && currentManager?._id != null) {
      loadCurrentTeamlist({
        employeeId: currentManager._id,
        cycleId: currentCycle._id
      });
    }
  }, [currentCycle?._id, currentManager?._id, loadCurrentTeamlist]);

  const directReports = useMemo(() => {
    return teamlist?.filter(
      (item) => item.relatedEmployer.identifiant !== 999999
    );
  }, [teamlist]);

  const externals = useMemo(() => {
    return teamlist?.filter(
      (item) => item.relatedEmployer.identifiant === 999999
    );
  }, [teamlist]);

  const downloadFile = (file: Blob, filename: string) => {
    const url = URL.createObjectURL(file);
    if (linkRef.current) {
      linkRef.current.href = url;
      linkRef.current.download = filename;
      linkRef.current.click();
    }
  };

  const handleDownloadFormMetrics = async () => {
    if (!currentCycle?._id || !currentManager?._id) return;

    setIsDownloading(true);
    const phase = currentCycle?.prOpened ? Phase.review : Phase.target;
    const data = await downloadFormMetrics(
      currentCycle?._id,
      phase,
      currentManager?._id
    );

    if (data) {
      const manager = currentManager?.name.first.toLowerCase();
      const filename = `form_data_${manager}_${phase}_${currentCycle?.year}.xlsx`;
      downloadFile(data, filename);
    }
    setIsDownloading(false);
  };

  return (
    <>
      {isLoadingTeamlist || isLoadingManagers ? (
        <div className="text-center m-10">
          <Spinner />
        </div>
      ) : (
        <>
          <SubNavBar
            breadcrumbs={[
              t("navigation.teamlist"),
              t("common.breadcrumps.overview")
            ]}
          />
          <main className="flex flex-col mx-auto max-w-7xl">
            {/* Filters: cycle */}
            <div className="py-4 px-4 sm:px-6 lg:px-8">
              <div className="flex items-center space-x-5">
                <CycleSelector
                  lang={employee?.lang ?? "en"}
                  currentCycle={currentCycle}
                />
                <EmployeeSelector
                  employeeOptions={teamManagers || []}
                  selectedEmployee={currentManager}
                  defaultLabel={t("common.selectors.teams")}
                  onSelectEmployee={(selection: Employee) =>
                    setSelectedTeamManager({ manager: selection })
                  }
                />
                <div className="mt-2">
                  <ActionButton
                    label={t("common.actions.download")}
                    onClick={handleDownloadFormMetrics}
                    icon={ArrowDownTrayIcon}
                    disabled={isDownloading}
                  />
                  <a ref={linkRef} style={{ display: "none" }} />
                </div>
              </div>
            </div>

            {/* Documents metrics */}
            {/* <div className="border-t border-white/10 pt-11">
              <h2 className="px-4 text-base font-semibold leading-7 sm:px-6 lg:px-8">
                {t("metrics.title")}
              </h2>
              <div className="px-4 sm:px-6 lg:px-8">
                <DocumentListMetrics metrics={metrics} />
              </div>
            </div> */}

            {/* My direct reports */}
            <div className="border-t border-white/10">
              <div className="pt-4 px-4 sm:px-6 lg:px-8">
                <SubSectionHeader
                  title={t("teamlist.directReports", {
                    count: directReports?.length ?? 0
                  })}
                  link={statusLinks?.[employee?.lang ?? "fr"]}
                  linkLabel={t("myPerformance.infoLinkLabel")}
                />
              </div>
              <div className="mb-6 px-4 sm:px-6 lg:px-8">
                <TeamlistTable teamlist={directReports} />
              </div>
            </div>

            {/* External */}
            {externals?.length ? (
              <div className="border-t border-white/10 pt-11 mb-6">
                <h2 className="px-4 text-base font-semibold leading-7 sm:px-6 lg:px-8">
                  {t("teamlist.externals", { count: externals?.length ?? 0 })}
                </h2>
                <div className="mt-6 px-4 sm:px-6 lg:px-8">
                  <TeamlistTable teamlist={externals} readOnly={true} />
                </div>
              </div>
            ) : null}
          </main>
        </>
      )}
    </>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(TeamList);

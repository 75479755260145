import { LangField } from "../LangField";
import { PersonName } from "../User";

export enum CompetenceType {
  FUNCTIONAL = "functional",
  GENERIC = "generic",
  SPECIFIC = "soft-skill"
}

export type GenericCompetence = {
  _id: string;
  name: LangField;
  definition: LangField;
  behaviors: LangField;
};

export type FunctionalCompetence = {
  _id: string;
  name: LangField;
  definition: LangField;
  behaviors: LangField;
  isActive: boolean;
};

export type BaseCompetence = {
  _id: string;

  // Basic informations
  relatedType: CompetenceType;
  genericCompetence?: GenericCompetence;
  functionalCompetence?: FunctionalCompetence;

  // Additional informations
  title: string;
  options?: {
    link?: string;
  };

  // Comments
  description: string;
  comments: string;

  // Notes
  notes: string[];

  // Internal management
  createdBy: {
    _id: string;
    name: PersonName;
  };
  createdAt: string;
  modifiedAt: string;
  isActive: boolean;
};

export type RawBaseCompetence = {
  // Basic informations
  relatedType: string;
  genericCompetence?: string;
  functionalCompetence?: string;

  // Additional informations
  title: string;
  options?: {
    link?: string;
  };

  // Comments
  description: string;
  comments?: string;
};

import { FC, ReactElement } from "react";

import { classNames } from "../../../utils/styles";

type OwnProps = {
  progress: number;
};

const CourseProgressionStatus: FC<OwnProps> = (props): ReactElement => {
  const { progress } = props;

  return (
    <span
      className={classNames(
        "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium",
        progress < 50 ? "bg-gray-100 text-gray-600" : "",
        progress >= 50 && progress < 80 ? "bg-yellow-100 text-yellow-800" : "",
        progress >= 80 ? "bg-green-100 text-green-700" : ""
      )}
    >
      {progress}%
    </span>
  );
};

export default CourseProgressionStatus;

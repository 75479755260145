import { createSelector } from "reselect";

import { RequestStatus } from "../../models/Request";
import { RootState } from "../../store/store";

const selectCurrentEmployeeState = (state: RootState) => state.currentEmployee;

export const selectCurrentEmployee = createSelector(
  selectCurrentEmployeeState,
  (state) => state.data
);

export const selectCurrentEmployeeId = createSelector(
  selectCurrentEmployee,
  (employee) => employee?._id
);

export const selectEmployeeDocuments = createSelector(
  selectCurrentEmployeeState,
  (state) => state.documents
);

export const isLoadingEmployeeDocuments = createSelector(
  selectCurrentEmployeeState,
  (state) => state.status === RequestStatus.PENDING
);

export const selectCurrentEmployeeLang = createSelector(
  [selectCurrentEmployee],
  (employee) => employee?.lang
);

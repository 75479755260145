import { AxiosResponse, CancelToken } from "axios";

import { UserData } from "../models/User";
import Api from "../utils/api";

export const getCurrentUser = async (
  cancelToken: CancelToken
): Promise<UserData> => {
  const url = `/users/me`;

  try {
    return await Api.get(url, {
      cancelToken
    }).then((response: AxiosResponse) => {
      const userData: UserData = response.data.data;

      return userData;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";

import { useNotifyStakeholders } from "../../hooks/form/useNotifyStakeholders";
import { FormRole } from "../../models/Form/BaseForm";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";

const mapDispatchToProps = {
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  role: FormRole;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const DocumentNotifyModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "form"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { documentId, role, notify, closeModal } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const mutation = useNotifyStakeholders(documentId);

  useEffect(() => {
    if (mutation.isPending) return;

    if (mutation.isError) {
      setErrorMessage(mutation.error.message);
    }

    if (mutation.isSuccess) {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.notificationSent"),
        message: t("notify.messages.success")
      });

      // Cleanup
      closeModal();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    documentId,
    mutation.isPending,
    mutation.isSuccess,
    mutation.isError,
    mutation.error,
    closeModal,
    queryClient,
    notify,
    t,
    tCommon
  ]);

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="sm:flex sm:items-start">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t(`notify.title.${role}`)}
              </h2>
              <p className="mt-1 mb-2 text-sm leading-6 text-gray-600">
                {t(`notify.subtitle.${role}`)}
              </p>
              {errorMessage != null && (
                <div className="mt-5">
                  <ErrorBanner message={errorMessage} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className={classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
              mutation.isPending ? "cursor-not-allowed opacity-50 ml-2" : ""
            )}
            disabled={mutation.isPending}
            onClick={() => {
              mutation.mutate();
            }}
          >
            {mutation.isPending && <Spinner size="small" />}
            {t("notify.confirmButton")}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModal()}
          >
            {tCommon("actions.cancel")}
          </button>
        </div>
      </>
    </Modal>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DocumentNotifyModal);

import { createSelector } from "reselect";

import { RequestStatus } from "../../../models/Request";
import { RootState } from "../../../store/store";
import { createDocumentDevAction, deleteDocumentDevAction, updateDocumentDevAction } from "./slice";

const selectCurrentDocumentDevActionsState = (state: RootState) =>
  state.currentDocument.developmentActions;

export const selectCurrentDocumentDevActions = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) => state.developmentActions
);

export const isLoadingCreateDocumentDevAction = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) =>
    state.actionStatuses[createDocumentDevAction.type]?.status ===
    RequestStatus.PENDING
);

export const selectCreateDocumentDevActionStatus = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) => state.actionStatuses[createDocumentDevAction.type]?.status
);

export const selectCreateDocumentDevActionError = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) => state.actionStatuses[createDocumentDevAction.type]?.error
);

export const isLoadingUpdateDocumentDevAction = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) =>
    state.actionStatuses[updateDocumentDevAction.type]?.status ===
    RequestStatus.PENDING
);

export const selectUpdateDocumentDevActionStatus = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) => state.actionStatuses[updateDocumentDevAction.type]?.status
);

export const selectUpdateDocumentDevActionError = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) => state.actionStatuses[updateDocumentDevAction.type]?.error
);

export const isLoadingDeleteDocumentDevAction = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) =>
    state.actionStatuses[deleteDocumentDevAction.type]?.status ===
    RequestStatus.PENDING
);

export const selectDeleteDocumentDevActionStatus = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) => state.actionStatuses[deleteDocumentDevAction.type]?.status
);

export const selectDeleteDocumentDevActionError = createSelector(
  selectCurrentDocumentDevActionsState,
  (state) => state.actionStatuses[deleteDocumentDevAction.type]?.error
);

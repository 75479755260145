import { useQuery } from "@tanstack/react-query";

import { getCoachsList } from "../../services/EmployeeService";

export const useCoachs = () => {
  return useQuery({
    queryKey: ["coachs"],
    queryFn: ({ signal }) => getCoachsList(signal)
  });
};

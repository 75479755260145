import Axios, { CancelTokenSource } from "axios";

import { call, put, takeLatest } from "@redux-saga/core/effects";

import { BaseValue } from "../../../models/Form/BaseValue";
import { createNewValue, deleteValue, updateValue } from "../../../services/ValueService";
import {
  createDocumentValue, createDocumentValueError, createDocumentValueSuccess, deleteDocumentValue,
  deleteDocumentValueError, deleteDocumentValueSuccess, updateDocumentValue,
  updateDocumentValueError, updateDocumentValueSuccess
} from "./slice";

function* createDocumentValueSaga(
  action: ReturnType<typeof createDocumentValue>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, data } = action.payload;

  try {
    const response: BaseValue = yield call(
      createNewValue,
      signal.token,
      formUUID,
      data
    );

    yield put(createDocumentValueSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      createDocumentValueError({
        error: message,
        payload: message
      })
    );
  }
}

function* updateDocumentValueSaga(
  action: ReturnType<typeof updateDocumentValue>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, valueId, data } = action.payload;

  try {
    const response: BaseValue = yield call(
      updateValue,
      signal.token,
      formUUID,
      valueId,
      data
    );

    yield put(updateDocumentValueSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      updateDocumentValueError({
        error: message,
        payload: message
      })
    );
  }
}

function* deleteDocumentValueSaga(
  action: ReturnType<typeof deleteDocumentValue>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, valueId } = action.payload;

  try {
    yield call(deleteValue, signal.token, formUUID, valueId);

    yield put(deleteDocumentValueSuccess({ valueId }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      deleteDocumentValueError({
        error: message,
        payload: message
      })
    );
  }
}

export default function* currentDocumentValueSaga() {
  yield takeLatest(createDocumentValue.type, createDocumentValueSaga);
  yield takeLatest(updateDocumentValue.type, updateDocumentValueSaga);
  yield takeLatest(deleteDocumentValue.type, deleteDocumentValueSaga);
}

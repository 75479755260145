import { useQuery } from "@tanstack/react-query";

import { getUserCourseRegistrations } from "../../../services/RiseUpService";

export const useUserCourseRegistrations = (memberId: string, year?: number) => {
  return useQuery({
    queryKey: ["courses", memberId, year],
    queryFn: ({ signal }) => getUserCourseRegistrations(signal, memberId, year)
  });
};

import { Employee } from "../Employee/Employee";
import { BaseForm } from "./BaseForm";

export const enum CoachRequestStatus {
  "PENDING" = "pending",
  "ACCEPTED" = "accepted",
  "REJECTED" = "rejected"
}

export interface CoachRequest {
  _id: string;

  // Basic data
  relatedForm: BaseForm;
  relatedEmployee: Employee;
  relatedCoach: Employee;

  // Request data
  askedBy: Employee;
  requestComment: string;
  status: CoachRequestStatus;
  answeredAt?: string;

  // Internal data
  requestUUID: string;
  createdAt: string;
  modifiedAt: string;
  isActive: boolean;
}

export interface ShortCoachRequest {
  _id: string;

  // Basic data
  relatedCoach: Employee;

  // Request data
  status: CoachRequestStatus;
}

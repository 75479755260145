import { FC, ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import { useUserCourseRegistrations } from "../../hooks/integrations/riseUp/userUserCourseRegistrations";
import { RiseUpCourseRegistration } from "../../models/integrations/RiseUp/RiseUpCourseRegistration";
import { formatISODateStr } from "../../utils/dates";
import { classNames } from "../../utils/styles";
import CourseProgressionStatus from "../integrations/riseUp/CourseProgressionStatus";

type OwnProps = {
  memberId: string;
  defaultValue?: RiseUpCourseRegistration;
  exclude?: string[];
  onSelectItem?: (item: RiseUpCourseRegistration) => void;
};

const UserCourseRegistrationSelector: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { defaultValue = null, memberId, exclude = [], onSelectItem } = props;

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState<RiseUpCourseRegistration | null>(
    defaultValue
  );

  // Get only registration for the current year
  const currentYear = new Date().getFullYear();
  const { data: registrations = [] } = useUserCourseRegistrations(
    memberId,
    currentYear
  );

  const filteredList: RiseUpCourseRegistration[] = useMemo(() => {
    const filtered = registrations.filter(
      (registration) => !exclude.includes(registration._id)
    );

    return query === ""
      ? filtered
      : filtered.filter((registration) => {
          const title = registration.relatedCourse.title.toLowerCase();
          return title.includes(query.toLowerCase());
        });
  }, [query, registrations, exclude]);

  const onChangeHandler = (item: RiseUpCourseRegistration) => {
    setSelected(item);

    if (onSelectItem) onSelectItem(item);
  };

  return (
    <Combobox as="div" value={selected} onChange={onChangeHandler}>
      <div className="relative">
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: RiseUpCourseRegistration) =>
            selected != null
              ? selected?.relatedCourse.title
              : t("common.selectors.courses.title")
          }
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredList.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredList.map((registration) => (
              <ComboboxOption
                key={registration._id}
                value={registration}
                className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                {({ focus, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >
                      {registration.relatedCourse.title}{" "}
                      <CourseProgressionStatus
                        progress={registration.progress}
                      />
                    </span>
                    <span
                      className={classNames(
                        "truncate text-gray-500 text-xs",
                        focus ? "text-indigo-200" : "text-gray-500"
                      )}
                    >
                      {t("common.riseUp.subscribedOn")}{" "}
                      {formatISODateStr(registration.subscribedate)}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          focus ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
};

export default UserCourseRegistrationSelector;

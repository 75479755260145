import { AxiosResponse } from 'axios';

import { RiseUpCourse } from '../models/integrations/RiseUp/RiseUpCourse';
import { RiseUpCourseRegistration } from '../models/integrations/RiseUp/RiseUpCourseRegistration';
import API from '../utils/api';

/**
 * Retrieves all courses from the RiseUp service.
 *
 * @param signal - The AbortSignal used to cancel the request.
 * @returns A Promise that resolves to an array of RiseUpCourse objects.
 * @throws If an error occurs during the request.
 */
export const getAllCourses = async (
  signal: AbortSignal,
): Promise<RiseUpCourse[]> => {
  const url = `/integrations/riseup/courses`;

  try {
    return await API.get(url, {
      signal,
    }).then((response: AxiosResponse) => {
      const courses: RiseUpCourse[] = response.data.data;

      return courses;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Retrieves a given course from the RiseUp service.
 *
 * @param signal - The AbortSignal used to cancel the request.
 * @param courseId - The ID of the course.
 * @returns A Promise that resolves to a RiseUpCourse object.
 * @throws If an error occurs during the request.
 */
export const getCourse = async (
  signal: AbortSignal,
  courseId: string,
): Promise<RiseUpCourse> => {
  const url = `/integrations/riseup/courses/${courseId}`;

  try {
    return await API.get(url, {
      signal,
    }).then((response: AxiosResponse) => {
      const course: RiseUpCourse = response.data.data;

      return course;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
}

/**
 * Retrieves the course registrations for a specific user.
 * 
 * @param signal - The AbortSignal used to cancel the request.
 * @param memberId - The ID of the member.
 * @param year - The year to filter the course registrations by.
 * @returns A Promise that resolves to an array of RiseUpCourseRegistration objects representing the user's course registrations.
 * @throws If an error occurs during the request.
 */
export const getUserCourseRegistrations = async (
  signal: AbortSignal,
  memberId: string,
  year?: number,
): Promise<RiseUpCourseRegistration[]> => {
  const baseUrl = `/integrations/riseup/members/${memberId}/courses`;
  const url = year ? `${baseUrl}?year=${year}` : baseUrl;

  try {
    return await API.get(url, {
      signal,
    }).then((response: AxiosResponse) => {
      const courses: RiseUpCourseRegistration[] = response.data.data;

      return courses;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
}
import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useResourceNotes } from "../../hooks/notes/useResourceNotes";
import { ActionMode } from "../../models/Actions";
import { NotificationConfig } from "../../models/Notification";
import { User } from "../../models/User";
import UserAvatar from "../users/UserAvatar";
import AddNewNote from "./AddNewNote";
import EmptyNotesList from "./EmptyNotesList";
import NoteItem from "./NoteItem";

type OwnProps = {
  currentUser: User;
  employeeId: string;
  documentId: string;
  resourceType: string;
  resourceId: string;
  mode: ActionMode;
  notify: (notification: NotificationConfig) => void;
};

const NotesList: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", { keyPrefix: "notes" });
  const {
    currentUser,
    documentId,
    employeeId,
    resourceType,
    resourceId,
    mode
  } = props;

  const { data: notes = [] } = useResourceNotes(
    documentId,
    resourceType,
    resourceId
  );

  // Sort notes by creation date in descending order
  const sortedNotes = useMemo(
    () =>
      [...notes].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      ),
    [notes]
  );

  return (
    <section aria-labelledby="notes-title">
      <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
              {t("title")}
            </h2>
          </div>
          <div className="px-4 py-6 sm:px-6">
            <ul className="space-y-8">
              {sortedNotes.length ? (
                sortedNotes.map((note) => (
                  <NoteItem
                    key={note._id}
                    documentId={documentId}
                    note={note}
                    canEdit={
                      mode === ActionMode.EDIT &&
                      employeeId === note.createdBy._id
                    }
                  />
                ))
              ) : (
                <EmptyNotesList />
              )}
            </ul>
          </div>
        </div>
        {mode === ActionMode.EDIT && (
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                <UserAvatar
                  name={currentUser.name}
                  pictureUrl={currentUser.avatarUrl}
                  size={8}
                />
              </div>
              <div className="min-w-0 flex-1">
                <AddNewNote {...props} />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default NotesList;

import { FC, ReactElement } from "react";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import { RiseUpCourseRegistration } from "../../../models/integrations/RiseUp/RiseUpCourseRegistration";
import CourseProgressionStatus from "./CourseProgressionStatus";
import { formatISODateStr } from "../../../utils/dates";
import { useTranslation } from "react-i18next";
import { AcademicCapIcon, ClockIcon } from "@heroicons/react/24/outline";

type OwnProps = {
  courseRegistration: RiseUpCourseRegistration;
  onRemoveItem?: (courseRegistration: RiseUpCourseRegistration) => void;
};

const CourseRegistrationItem: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { courseRegistration, onRemoveItem } = props;

  return (
    <li
      key={courseRegistration._id}
      className="flex items-center justify-between gap-x-6 py-5"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            {courseRegistration.relatedCourse.title}
          </p>
          <CourseProgressionStatus progress={courseRegistration.progress} />
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p className="whitespace-nowrap">
            {t("common.riseUp.subscribedOn")}{" "}
            {formatISODateStr(courseRegistration?.subscribedate)}
          </p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          <p className="truncate">
            {courseRegistration.relatedCourse.trainingtype}
          </p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          <p className="truncate">
            <ClockIcon className="h-4 w-4 inline-block -mt-0.5 mr-1" />
            {courseRegistration.totaltime} mins
          </p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          <p className="truncate">
            <AcademicCapIcon className="h-4 w-4 inline-block -mt-0.5 mr-1" />
            {courseRegistration?.score ?? "-"} / 100
          </p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <a
          href={`https://partena-professional.riseup.ai/Catalog/training/${courseRegistration.relatedCourse.courseId}`}
          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
          target="_blank"
          rel="noopener noreferrer"
        >
          {tCommon("riseUp.viewOnRiseUp")}
        </a>
        {onRemoveItem && (
          <Menu as="div" className="relative flex-none">
            <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <MenuItem>
                <span
                  className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                  onClick={() => onRemoveItem(courseRegistration)}
                >
                  {tCommon("actions.unlinkCourse")}
                </span>
              </MenuItem>
            </MenuItems>
          </Menu>
        )}
      </div>
    </li>
  );
};

export default CourseRegistrationItem;

import { useQuery } from "@tanstack/react-query";

import { getFormMeetings } from "../../services/MeetingService";

export const useMeetings = (formUUID: string, enabled: boolean = true) => {
  return useQuery({
    queryKey: ["meetings", formUUID],
    queryFn: ({ signal }) => getFormMeetings(signal, formUUID),
    enabled
  });
};

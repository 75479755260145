import Axios, { CancelTokenSource } from "axios";

import { call, put, takeLatest } from "@redux-saga/core/effects";

import { BaseForm } from "../../models/Form/BaseForm";
import { getEmployeeDocuments } from "../../services/EmployeeService";
import {
  loadEmployeeDocuments, loadEmployeeDocumentsError, loadEmployeeDocumentsSuccess
} from "./slice";

function* getEmployeeDocumentsSaga(
  action: ReturnType<typeof loadEmployeeDocuments>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { employeeId, cycleId } = action.payload;

  try {
    const data: BaseForm[] = yield call(
      getEmployeeDocuments,
      signal.token,
      employeeId,
      cycleId
    );

    yield put(loadEmployeeDocumentsSuccess({ data }));
  } catch (error: any) {
    yield put(loadEmployeeDocumentsError(error));
  }
}

export default function* currentEmployeeSaga() {
  yield takeLatest(loadEmployeeDocuments.type, getEmployeeDocumentsSaga);
}

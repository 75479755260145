import { FC, ReactElement } from "react";

import { ClockIcon, XCircleIcon } from "@heroicons/react/20/solid";

import { Employee } from "../../models/Employee/Employee";
import { CoachRequestStatus } from "../../models/Form/CoachRequest";
import { getAvatarUrl } from "../../utils/user";
import UserAvatar from "../users/UserAvatar";

type OwnProps = {
  coach: Employee;
  status?: CoachRequestStatus;
};

const DocumentCoachItem: FC<OwnProps> = (props): ReactElement => {
  const { coach, status } = props;
  const { name, holaMember } = coach;

  return (
    <>
      <UserAvatar name={name} pictureUrl={getAvatarUrl(holaMember)} showName />
      {status && status === CoachRequestStatus.PENDING && (
        <ClockIcon
          className="ml-1 h-5 w-5 text-orange-400"
          aria-hidden="true"
        />
      )}
      {!status && (
        <button
          type="button"
          className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </>
  );
};

export default DocumentCoachItem;

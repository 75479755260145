import { useMutation } from "@tanstack/react-query";

import { removeOKRFromObjective } from "../../../services/ObjectiveService";

export const useRemoveOKRFromObjective = (
  formUUID: string,
  objectiveId: string
) => {
  return useMutation({
    mutationFn: (okrId: string) =>
      removeOKRFromObjective(formUUID, objectiveId, okrId),
  });
};

import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import { DocumentCareer } from "../../models/Form/DocumentCareer";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import {
  isLoadingUpdateCurrentDocument,
  selectUpdateCurrentDocumentError,
  selectUpdateCurrentDocumentStatus
} from "../../redux/currentDocument/selectors";
import {
  clearUpdateDocumentStatus,
  updateDocument
} from "../../redux/currentDocument/slice";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import InputTextArea from "../forms/InputTextArea";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingUpdateCurrentDocument,
  status: selectUpdateCurrentDocumentStatus,
  formError: selectUpdateCurrentDocumentError
});

const mapDispatchToProps = {
  updateForm: updateDocument,
  clearStatus: clearUpdateDocumentStatus,
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  career: DocumentCareer;
  isManager: boolean;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const EditCareerModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "career"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    career,
    isManager,
    isLoading,
    status,
    formError,
    updateForm,
    clearStatus,
    notify,
    closeModal
  } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("update.messages.success")
      });

      // Cleanup
      closeModal();
      clearStatus();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    isLoading,
    status,
    formError,
    closeModal,
    clearStatus,
    notify,
    queryClient,
    t,
    tCommon,
    documentId
  ]);

  // Component state
  const initialState = isManager
    ? { comment: career?.manager?.comment ?? "" }
    : {
        comment1: career?.employee?.comment1 ?? "",
        comment2: career?.employee?.comment2 ?? "",
        comment3: career?.employee?.comment3 ?? "",
        comment4: career?.employee?.comment4 ?? ""
      };

  const validationSchema = Yup.object(
    isManager
      ? {
          comment: Yup.string().max(5000, "Must be 5000 characters or less")
        }
      : {
          comment1: Yup.string().max(5000, "Must be 5000 characters or less"),
          comment2: Yup.string().max(5000, "Must be 5000 characters or less"),
          comment3: Yup.string().max(5000, "Must be 5000 characters or less"),
          comment4: Yup.string().max(5000, "Must be 5000 characters or less")
        }
  );

  return (
    <Modal closeModal={closeModal}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          updateForm({
            formUUID: documentId,
            data: {
              career: {
                ...(isManager
                  ? {
                      manager: {
                        ...values
                      }
                    }
                  : {
                      employee: {
                        ...values
                      }
                    })
              }
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="sm:flex sm:items-start">
              <div className="space-y-12 w-full">
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {t("update.title")}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    {t("update.subtitle")}
                  </p>
                  <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    {isManager ? (
                      <>
                        {/* Manager's comment */}
                        <div className="col-span-full">
                          <InputTextArea
                            label={t("fields.comments")}
                            name="comment"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Question 1 */}
                        <div className="col-span-full">
                          <InputTextArea
                            label={t("questions.question1")}
                            name="comment1"
                            helperText={t("questions.question1Helper")}
                          />
                        </div>
                        {/* Question 2 */}
                        <div className="col-span-full">
                          <InputTextArea
                            label={t("questions.question2")}
                            name="comment2"
                          />
                        </div>
                        {/* Question 3 */}
                        <div className="col-span-full">
                          <InputTextArea
                            label={t("questions.question3")}
                            name="comment3"
                            helperText={t("questions.question3Helper")}
                          />
                        </div>
                        {/* Question 4 */}
                        <div className="col-span-full">
                          <InputTextArea
                            label={t("questions.question4")}
                            name="comment4"
                          />
                          <p
                            className="mt-2 text-xs text-gray-500"
                            id={"comment4-description"}
                            dangerouslySetInnerHTML={{
                              __html: t("questions.question4Helper")
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {errorMessage != null && (
                    <div className="mt-5">
                      <ErrorBanner message={errorMessage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
                  isSubmitting ? "cursor-not-allowed opacity-50 ml-2" : ""
                )}
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size="small" />}
                {tCommon("actions.save")}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => closeModal()}
              >
                {tCommon("actions.cancel")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(EditCareerModal);

import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type OwnProps = {
  label: string;
  disabled?: boolean;
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  onClick: () => void;
};

const WarningButton: FC<OwnProps> = (props): ReactElement => {
  const {
    disabled = false,
    label,
    icon: Icon,
    onClick
  } = props;

  return (
    <button
      type="button"
      className={classNames(
        `inline-flex items-center justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`,
        disabled ? "opacity-50 cursor-not-allowed" : ""
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && <Icon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
      {label}
    </button>
  );
};

export default WarningButton;

import Axios, { CancelTokenSource } from "axios";

import { call, put, takeLatest } from "@redux-saga/core/effects";

import { Employee } from "../../models/Employee/Employee";
import { BaseForm } from "../../models/Form/BaseForm";
import { getTeamByCycle, getTeamlist } from "../../services/TeamlistService";
import { setCurrentCycle } from "../currentApp/slice";
import {
  loadCurrentTeamlist, loadCurrentTeamlistError, loadCurrentTeamlistSuccess, loadTeamManagers,
  loadTeamManagersError, loadTeamManagersSuccess
} from "./slice";

function* getTeamlistSaga(action: ReturnType<typeof loadCurrentTeamlist>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { employeeId, cycleId } = action.payload;

  try {
    const data: BaseForm[] = yield call(
      getTeamlist,
      signal.token,
      employeeId,
      cycleId
    );

    yield put(loadCurrentTeamlistSuccess({ data }));
  } catch (error: any) {
    yield put(loadCurrentTeamlistError(error));
  }
}

function* fetchTeamManagersSaga(action: ReturnType<typeof setCurrentCycle>) {
  const { cycle } = action.payload;

  yield put(loadTeamManagers({ cycleId: cycle._id }));
}

function* getTeamManagersSaga(action: ReturnType<typeof loadTeamManagers>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { cycleId } = action.payload;

  try {
    const data: Employee[] = yield call(getTeamByCycle, signal.token, cycleId);

    yield put(loadTeamManagersSuccess({ data }));
  } catch (error: any) {
    yield put(loadTeamManagersError(error));
  }
}

export default function* currentTeamlistSaga() {
  yield takeLatest(loadCurrentTeamlist.type, getTeamlistSaga);
  yield takeLatest(loadTeamManagers.type, getTeamManagersSaga);

  // Listen for changes to the current cycle
  yield takeLatest(setCurrentCycle.type, fetchTeamManagersSaga);
}

import { AxiosResponse } from "axios";

import { HolaOKR } from "../models/integrations/HolaSpirit/HolaOKR";
import API from "../utils/api";

export const getMemberOKRs = async (
  signal: AbortSignal,
  memberId: string
): Promise<HolaOKR[]> => {
  const url = `/integrations/hola/members/${memberId}/okrs`;

  try {
    return await API.get(url, {
      signal,
    }).then((response: AxiosResponse) => {
      const okrs: HolaOKR[] = response.data.data;

      return okrs;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

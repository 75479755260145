import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";

import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import {
  isLoadingDeleteDocumentObjective, selectDeleteDocumentObjectiveError,
  selectDeleteDocumentObjectiveStatus
} from "../../redux/currentDocument/objectives/selectors";
import {
  clearDeleteDocumentObjectiveStatus, deleteDocumentObjective
} from "../../redux/currentDocument/objectives/slice";
import { baseUrl } from "../../routes";
import ErrorBanner from "../banners/ErrorBanner";
import ActionButton from "../buttons/ActionButton";
import DefaultButton from "../buttons/DefaultButton";
import Modal from "../modal/Modal";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingDeleteDocumentObjective,
  status: selectDeleteDocumentObjectiveStatus,
  error: selectDeleteDocumentObjectiveError
});

const mapDispatchToProps = {
  deleteObjective: deleteDocumentObjective,
  clearStatus: clearDeleteDocumentObjectiveStatus,
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  objectiveId: string;
  closeModal: () => void;
}

const RemoveObjectiveModal: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "objectives"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    objectiveId,
    isLoading,
    status,
    error,
    clearStatus,
    deleteObjective,
    notify,
    closeModal
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (error) {
      setErrorMessage(error);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.deleteSuccess"),
        message: t("delete.messages.success")
      });

      // Cleanup
      closeModal();
      clearStatus();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });

      // Redirect to Objectives table
      navigate(`${baseUrl}/performance/${documentId}/objectives`);
    }
  }, [
    isLoading,
    status,
    error,
    closeModal,
    clearStatus,
    documentId,
    notify,
    t,
    tCommon,
    queryClient,
    navigate
  ]);

  const onDeleteHandler = () => {
    deleteObjective({ formUUID: documentId, objectiveId });
  };

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                {t("delete.title")}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t("delete.confirmation")}
                </p>
              </div>
              {errorMessage != null && (
                <div className="mt-5">
                  <ErrorBanner message={errorMessage} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <ActionButton
            label={t("actions.delete")}
            onClick={onDeleteHandler}
            customColor="red"
          />
          <DefaultButton
            label={tCommon("actions.cancel")}
            onClick={() => closeModal()}
            customClass="mr-3"
          />
        </div>
      </>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(RemoveObjectiveModal);

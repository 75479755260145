export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
}

export type NotificationConfig = {
  type: NotificationType;
  title: string;
  message: string;
  duration?: number;
};

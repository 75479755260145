import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./en/translation.json";
import translationFR from "./fr/translation.json";
import translationNL from "./nl/translation.json";

export const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
  // lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",
  debug: true,
  resources,
});

import { Employee } from "../Employee/Employee";
import { EmployeeFunction } from "../Employee/EmployeeFunction";
import { Employer } from "../Employee/Employer";
import { BaseCompetence } from "./BaseCompetence";
import { BaseDevelopmentAction } from "./BaseDevelopmentAction";
import { BaseObjective } from "./BaseObjective";
import { BaseValue } from "./BaseValue";
import { Cycle } from "./Cycle";
import { DocumentCareer } from "./DocumentCareer";
import { FormState } from "./FormState";

export enum Phase {
  "target" = "target",
  "review" = "review"
}

export enum FormRole {
  "employee" = "employee",
  "manager" = "manager",
  "coach" = "coach"
}

export interface BaseForm {
  _id: string;
  relatedCycle: Cycle;
  relatedPhase: Phase;
  relatedEmployee: Employee;
  relatedManager: Employee;
  performanceCoachs: Employee[];
  relatedFunction: EmployeeFunction;
  relatedEmployer: Employer;
  relatedState: FormState;

  // Contents
  objectives: BaseObjective[];
  competences: BaseCompetence[];
  developmentActions: BaseDevelopmentAction[];
  values: BaseValue[];

  // Career
  career?: DocumentCareer;

  // Additional data
  developmentComment?: string;
  linkedFile?: string;

  // General Comments
  managerComments?: string;
  employeeComments?: string;

  // Notifications
  notifications?: {
    employee?: string;
    manager?: string;
    coach?: string;
  };
  lastDiscussionDate?: string;

  // Internal data
  formUUID: string;
  createdAt: string;
  modifiedAt: string;
  isActive: boolean;
  isTemporarilyOutOfService: boolean;
}

export type RawBaseForm = {
  // Career
  career?: DocumentCareer;
} & RawBaseFormConclusion;

export type RawBaseFormConclusion = {
  // Additional data
  developmentComment?: string;
  linkedFile?: string;

  // General Comments
  managerComments?: string;
  employeeComments?: string;
};

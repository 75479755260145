import { connect, ErrorMessage, Field, getIn } from "formik";
import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type OwnProps = {
  label: string;
  name: string;
  placeholder?: string;
  isPercent?: boolean;
  disabled?: boolean;
};

const InputNumber: FC<OwnProps> = (props): ReactElement => {
  const {
    formik,
    label,
    name,
    placeholder = "0.00",
    isPercent = false,
    disabled = false,
  } = props;
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);

  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <Field name={name}>
        {({ field }) => (
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              {...field}
              type="number"
              id={name}
              className={classNames(
                "block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                disabled ? "text-slate-600 cursor-not-allowed" : "",
                touch && error
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                  : ""
              )}
              disabled={disabled}
              placeholder={placeholder}
              aria-describedby={`${name}-error`}
            />
            {isPercent && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span
                  className="text-gray-500 sm:text-sm"
                  id={`${name}-percent`}
                >
                  %
                </span>
              </div>
            )}
          </div>
        )}
      </Field>
      <ErrorMessage
        name={name}
        component="p"
        className="mt-2 text-xs text-red-600"
        id={`${name}-error`}
      />
    </>
  );
};

export default connect<OwnProps, any>(InputNumber);

import { SupportedLanguages } from "../LangField";
import { PersonName } from "../User";

export enum MeetingType {
  ONE_ON_ONE = "1-o-1",
  COACHING = "coaching"
}

export type FormMeeting = {
  _id: string;
  relatedForm: string;
  meetingType: MeetingType;
  meetingDate: string;
  participants: {
    _id: string;
    name: PersonName;
    lang: SupportedLanguages;
    holaMember?: {
      avatarUrl?: string;
    };
  }[];

  // Content
  body: string;

  // Internal management
  createdBy: {
    _id: string;
    name: PersonName;
    lang: SupportedLanguages;
    holaMember?: {
      avatarUrl?: string;
    };
  };
  createdAt: string;
  modifiedAt: string;
  isActive: boolean;
};

import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BaseForm } from "../../models/Form/BaseForm";
import { parseISODateStr } from "../../utils/dates";
import { classNames } from "../../utils/styles";

// const statuses: Record<number, string> = {
//   1: "text-blue-600 bg-blue-50 ring-blue-500/10",
//   2: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
//   3: "text-green-700 bg-green-50 ring-green-600/20",
// };

const statuses: Record<string, string> = {
  onTrack: "text-green-700 bg-green-50 ring-green-600/20",
  inProgress: "text-blue-600 bg-blue-50 ring-blue-500/10",
  toLaunch: "text-red-700 bg-red-50 ring-red-600/10",
  noStatus: "text-gray-400 bg-gray-50 ring-gray-300/10"
};

type OwnProps = {
  document: BaseForm;
  lastMeetingDate?: string;
};

const DocumentStatus: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "common.statuses"
  });

  const { document, lastMeetingDate } = props;
  const { objectives, competences, developmentActions } = document;

  const statusScore: number = useMemo(() => {
    let malus = 0;
    const devActions = developmentActions?.length ?? 0;
    const total = objectives.length + competences.length + devActions;

    if (total === 0) {
      malus = 10;
    }

    // If the document has been discussed, we add a bonus to the score
    const meetingDate =
      lastMeetingDate != null ? parseISODateStr(lastMeetingDate) : null;
    const bonus = total > 3 && meetingDate ? 20 : 0;

    // const date = new Date(modifiedAt);
    // Compute number of days between now and the last modification date
    // const diff = Math.floor(
    //   (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 30)
    // );

    // if (diff > 30) {
    //   malus = 5;
    // }

    // Return computed weighted scores
    return total + bonus - malus;
  }, [
    objectives,
    competences,
    developmentActions,
    // modifiedAt,
    lastMeetingDate
  ]);

  const statusLabel = useMemo(() => {
    if (document.relatedEmployer.identifiant === 999999) {
      return "noStatus";
    }

    if (statusScore <= 3) {
      return "toLaunch";
    } else if (statusScore <= 20) {
      return "inProgress";
    } else {
      return "onTrack";
    }
  }, [statusScore, document.relatedEmployer.identifiant]);

  return (
    <span
      className={classNames(
        statuses[statusLabel],
        "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
      )}
    >
      {t(statusLabel)}
    </span>
  );
};

export default DocumentStatus;

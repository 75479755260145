import { PersonName } from "../models/User";

export const getFullName = (name: PersonName) => {
  return `${name.first} ${name.last}`;
};

export const getInitials = (name: PersonName) => {
  return `${name.first[0]}${name.last[0]}`;
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};

export const sanitize = (str: string): string => {
  if (!str) return "";

  const html: any = {
    "\n": "<br>",
    "&lt;br&gt;": "<br>",
    "&#39;": "'",
    "&para;": "&nbsp;", // show whitespace diffs without polluting the rest of the text with the paragraph symbol
    "&amp;": "&",
    "&gt;": ">",
    "&lt": "<"
  };
  const re = new RegExp(Object.keys(html).join("|"), "g");

  return str.replace(re, (char: string) => html[char]);
};

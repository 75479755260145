import { FC, ReactElement } from "react";

import { ListBulletIcon, PaperClipIcon, XCircleIcon } from "@heroicons/react/20/solid";

import { HolaOKR } from "../../../models/integrations/HolaSpirit/HolaOKR";

type OwnProps = {
  okr: HolaOKR;
  onRemoveOKR?: (okr: HolaOKR) => void;
};

const OKRItem: FC<OwnProps> = (props): ReactElement => {
  const { okr, onRemoveOKR } = props;

  return (
    <li
      key={okr._id}
      className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
    >
      <div className="flex w-0 flex-1 items-center">
        <PaperClipIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 w-64 flex-1 truncate">{okr.title}</span>
      </div>
      <div className="mx-4 flex flex-shrink-0 items-center">
        <ListBulletIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 w-0">{okr.results.length ?? 0}</span>
      </div>
      <div className="ml-4 flex-shrink-0">
        <a
          href={`https://app.holaspirit.com/o/5c7543395454800a08264d62/okrs/list/mine#okr-${okr._id}`}
          className="font-medium text-blue-600 hover:text-blue-500"
          target="_blank"
          rel="noreferrer noopener"
        >
          View on Hola Spirit
        </a>
      </div>
      {onRemoveOKR && (
        <button
          type="button"
          className="ml-4 flex-shrink-0 p-1 text-gray-400 hover:text-gray-500"
          onClick={() => onRemoveOKR(okr)}
        >
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </li>
  );
};

export default OKRItem;

import { useMutation } from "@tanstack/react-query";

import { addOKRToObjective } from "../../../services/ObjectiveService";

export const useAddOKRToObjective = (formUUID: string, objectiveId: string) => {
  return useMutation({
    mutationFn: (okrId: string) =>
      addOKRToObjective(formUUID, objectiveId, okrId),
  });
};

import { AxiosResponse, CancelToken } from "axios";

import { Coach } from "../models/Employee/Employee";
import { BaseForm } from "../models/Form/BaseForm";
import { CoachRequest } from "../models/Form/CoachRequest";
import API, { ApiV1 } from "../utils/api";

export const getEmployeeDocuments = async (
  cancelToken: CancelToken,
  employeeId: string,
  cycleId: string
): Promise<BaseForm[]> => {
  const url = `/employees/${employeeId}/documents?cycle=${cycleId}`;

  try {
    return await API.get(url, {
      cancelToken
    }).then((response: AxiosResponse) => {
      const documents: BaseForm[] = response.data.data;

      return documents;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const getCoachsList = async (signal: AbortSignal): Promise<Coach[]> => {
  const url = "/coachs";

  try {
    return await ApiV1.get(url, {
      signal
    }).then((response: AxiosResponse) => {
      const coachs: Coach[] = response.data.data;

      return coachs;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const getUserCoachingRequests = async (
  signal: AbortSignal,
  employeeId: string,
  cycleId?: string
): Promise<CoachRequest[]> => {
  const url = `/employees/${employeeId}/coachrequests?cycle=${cycleId}`;

  try {
    return await API.get(url, {
      signal
    }).then((response: AxiosResponse) => {
      const requests: CoachRequest[] = response.data.data;

      return requests;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

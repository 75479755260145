import { useMutation } from "@tanstack/react-query";

import { removeCourseRegistrationFromDevAction } from "../../../services/DevActionService";

export const useRemoveCourseRegistrationFromDevAction = (
  formUUID: string,
  devActionId: string
) => {
  return useMutation({
    mutationFn: (registrationId: string) =>
      removeCourseRegistrationFromDevAction(
        formUUID,
        devActionId,
        registrationId
      )
  });
};

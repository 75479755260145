import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CoachRequestStatus } from "../../models/Form/CoachRequest";
import { updateCoachRequestStatus } from "../../services/CoachRequestService";

export const useAnswerCoachRequest = (requestId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (status: CoachRequestStatus) =>
      updateCoachRequestStatus(requestId, status),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user-coach-request"]
      });
    }
  });
};

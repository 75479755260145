import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { RocketLaunchIcon } from "@heroicons/react/24/outline";

import SubSectionHeader from "../../../../components/headings/SubSectionHeader";
import EmptyObjectivesList from "../../../../components/objectives/EmptyObjectivesList";
import ObjectivesTable from "../../../../components/objectives/ObjectivesTable";
import { ActionMode } from "../../../../models/Actions";
import { Employee } from "../../../../models/Employee/Employee";
import { openModal } from "../../../../redux/currentApp/slice";
import { selectCurrentDocumentObjectives } from "../../../../redux/currentDocument/objectives/selectors";
import { selectCurrentDocumentMode } from "../../../../redux/currentDocument/selectors";
import { objectiveLinks } from "../../../../utils/links";

const mapStateToProps = createStructuredSelector({
  objectives: selectCurrentDocumentObjectives,
  mode: selectCurrentDocumentMode
});

const mapDispatchToProps = {
  openModal
};

interface OwnProps {
  documentId: string;
  employee: Employee;
  isManager: boolean;
}

const PerformanceObjectives: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "objectives"
  });
  const {
    documentId,
    objectives = [],
    employee,
    isManager,
    mode,
    openModal
  } = props;

  const openAddNewObjectiveModal = () => {
    openModal({
      modal: {
        component: "AddObjectiveModal",
        props: {
          employeeId: employee?._id,
          documentId,
          isManager
        }
      }
    });
  };

  return (
    <div className="my-10 mx-auto max-w-7xl">
      <SubSectionHeader
        title={t("title")}
        link={objectiveLinks?.[employee?.lang ?? "fr"]}
        linkLabel={t("infoLinkLabel")}
        icon={
          <RocketLaunchIcon
            className="h-6 w-6 text-teal-500"
            aria-hidden="true"
          />
        }
        actionLabel={t("actions.add")}
        onActionClick={
          mode === ActionMode.EDIT
            ? () => {
                openAddNewObjectiveModal();
              }
            : undefined
        }
      />
      <ObjectivesTable
        documentId={documentId}
        objectives={objectives}
        lang={employee?.lang}
      />
      {objectives?.length === 0 && (
        <EmptyObjectivesList
          onClick={
            mode === ActionMode.EDIT
              ? () => {
                  openAddNewObjectiveModal();
                }
              : undefined
          }
        />
      )}
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceObjectives);

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { FireIcon } from "@heroicons/react/24/outline";

import InfoBanner from "../../../../components/banners/InfoBanner";
import SubSectionHeader from "../../../../components/headings/SubSectionHeader";
import EmptyValuesList from "../../../../components/values/EmptyValuesList";
import ValuesTable from "../../../../components/values/ValuesTable";
import { ActionMode } from "../../../../models/Actions";
import { Employee } from "../../../../models/Employee/Employee";
import { openModal } from "../../../../redux/currentApp/slice";
import { selectCurrentDocumentMode } from "../../../../redux/currentDocument/selectors";
import { selectCurrentDocumentValues } from "../../../../redux/currentDocument/values/selectors";
import { valueLinks } from "../../../../utils/links";

const mapStateToProps = createStructuredSelector({
  values: selectCurrentDocumentValues,
  mode: selectCurrentDocumentMode
});

const mapDispatchToProps = {
  openModal
};

interface OwnProps {
  documentId: string;
  employee: Employee;
}

const PerformanceValues: FC<OwnProps & ReduxProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const { documentId, employee, values = [], mode, openModal } = props;

  const openAddValueModal = () => {
    openModal({
      modal: {
        component: "AddValueModal",
        props: {
          documentId
        }
      }
    });
  };

  return (
    <div className="my-10 mx-auto max-w-7xl">
      <SubSectionHeader
        title={t("title")}
        link={valueLinks?.[employee?.lang ?? "fr"]}
        linkLabel={t("infoLinkLabel")}
        icon={<FireIcon className="h-6 w-6 text-rose-500" aria-hidden="true" />}
        // actionLabel={t("actions.add")}
        // onActionClick={
        //   mode === ActionMode.EDIT
        //     ? () => {
        //         openAddValueModal();
        //       }
        //     : undefined
        // }
      />
      <div className="my-5">
        <InfoBanner
          title={t("infoBanner.title")}
          message={t("infoBanner.message")}
          isHtml
        />
      </div>
      <ValuesTable
        documentId={documentId}
        values={values}
        lang={employee.lang}
      />
      {values.length === 0 && (
        <EmptyValuesList
          onClick={
            mode === ActionMode.EDIT ? () => openAddValueModal() : undefined
          }
        />
      )}
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceValues);

import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { PlusIcon } from "@heroicons/react/20/solid";

import { HolaOKR } from "../../../models/integrations/HolaSpirit/HolaOKR";
import ActionButton from "../../buttons/ActionButton";
import DefaultButton from "../../buttons/DefaultButton";
import MemberOKRSelector from "../../selectors/MemberOKRSelector";
import OKRItem from "./OKRItem";

type OwnProps = {
  okrs: HolaOKR[];
  label: string;
  memberId?: string;
  emptyLabel?: string;
  onLinkOKR?: (okr: HolaOKR) => void;
  onUnlinkOKR?: (okr: HolaOKR) => void;
};

const LinkedOKRsList: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const {
    memberId,
    okrs,
    label,
    emptyLabel = t("common.emptyStates.okrs"),
    onLinkOKR,
    onUnlinkOKR,
  } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-l font-medium text-indigo-600">{label}</h3>
        <div className="mt-3 sm:ml-4 sm:mt-0">
          <ActionButton
            label={t("common.actions.addOKR")}
            onClick={() => setIsEditing(true)}
            disabled={!memberId || isEditing || !onLinkOKR}
            icon={PlusIcon}
          />
        </div>
      </div>
      <dd className="mt-3 text-sm text-gray-900">
        {okrs.length ? (
          <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
            {okrs.map((okr) => (
              <OKRItem key={okr._id} okr={okr} onRemoveOKR={onUnlinkOKR} />
            ))}
          </ul>
        ) : (
          <p className="font-medium text-grey-500">{emptyLabel}</p>
        )}
      </dd>
      {memberId && isEditing && onLinkOKR && (
        <div className="flex items-center space-between mt-3">
          <div className="w-full mr-2">
            <MemberOKRSelector
              memberId={memberId}
              onSelectItem={(item) => {
                onLinkOKR(item);
                setIsEditing(false);
              }}
              exclude={okrs.map((okr) => okr._id)}
            />
          </div>
          <DefaultButton
            label={t("common.actions.cancel")}
            onClick={() => setIsEditing(false)}
          />
        </div>
      )}
    </>
  );
};

export default LinkedOKRsList;

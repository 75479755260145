import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { createStructuredSelector } from "reselect";

import { BaseCompetence } from "../../../models/Form/BaseCompetence";
import { BaseDevelopmentAction } from "../../../models/Form/BaseDevelopmentAction";
import { BaseObjective } from "../../../models/Form/BaseObjective";
import { BaseValue } from "../../../models/Form/BaseValue";
import { openModal, showNotification } from "../../../redux/currentApp/slice";
import {
  selectCurrentDocumentCompetences
} from "../../../redux/currentDocument/competences/selectors";
import {
  selectCurrentDocumentDevActions
} from "../../../redux/currentDocument/devActions/selectors";
import {
  selectCurrentDocumentObjectives
} from "../../../redux/currentDocument/objectives/selectors";
import {
  selectCurrentDocument, selectCurrentDocumentMode
} from "../../../redux/currentDocument/selectors";
import { selectCurrentDocumentValues } from "../../../redux/currentDocument/values/selectors";
import { selectCurrentEmployeeId } from "../../../redux/currentEmployee/selectors";
import { selectCurrentUser } from "../../../redux/currentUser/selectors";
import PerformanceCompetenceDetail from "./competences/PerformanceCompetenceDetail";
import PerformanceDevActionDetail from "./devActions/PerformanceDevActionDetail";
import PerformanceObjectiveDetail from "./objectives/PerformanceObjectiveDetail";
import PerformanceValueDetail from "./values/PerformanceValueDetail";

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  employeeId: selectCurrentEmployeeId,
  document: selectCurrentDocument,
  objectives: selectCurrentDocumentObjectives,
  competences: selectCurrentDocumentCompetences,
  developmentActions: selectCurrentDocumentDevActions,
  values: selectCurrentDocumentValues,
  mode: selectCurrentDocumentMode
});

const mapDispatchToProps = {
  notify: showNotification,
  openModal
};

type OwnProps = ReduxProps;

const PerformanceEntityDetail: FC<OwnProps> = (props) => {
  const navigate = useNavigate();
  const {
    document,
    currentUser,
    employeeId,
    objectives,
    competences,
    developmentActions,
    values,
    ...actions
  } = props;
  const { section, id: entityId } = useParams();

  if (!currentUser || !employeeId || !document) return null;
  switch (section) {
    case "objectives": {
      if (!objectives) {
        navigate(`/performance/${document.formUUID}/objectives`);
        return;
      }
      const objective = objectives.find(
        (o: BaseObjective) => o._id === entityId
      );
      if (!objective) return null;
      return (
        <PerformanceObjectiveDetail
          document={document}
          objective={objective}
          currentUser={currentUser}
          employeeId={employeeId}
          {...actions}
        />
      );
    }
    case "competences": {
      if (!competences) {
        navigate(`/performance/${document.formUUID}/competences`);
        return;
      }
      const competence = competences.find(
        (o: BaseCompetence) => o._id === entityId
      );
      if (!competence) return null;
      return (
        <PerformanceCompetenceDetail
          document={document}
          competence={competence}
          currentUser={currentUser}
          employeeId={employeeId}
          {...actions}
        />
      );
    }
    case "development-actions": {
      if (!developmentActions) {
        navigate(`/performance/${document.formUUID}/development-actions`);
        return;
      }
      const devAction = developmentActions.find(
        (o: BaseDevelopmentAction) => o._id === entityId
      );
      if (!devAction) return null;
      return (
        <PerformanceDevActionDetail
          document={document}
          devAction={devAction}
          currentUser={currentUser}
          employeeId={employeeId}
          {...actions}
        />
      );
    }
    case "lead": {
      if (!values) {
        navigate(`/performance/${document.formUUID}/lead`);
        return;
      }
      const value = values.find((o: BaseValue) => o._id === entityId);
      if (!value) return null;
      return (
        <PerformanceValueDetail
          document={document}
          value={value}
          currentUser={currentUser}
          employeeId={employeeId}
          {...actions}
        />
      );
    }
    default:
      return <div>Overview</div>;
  }
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceEntityDetail);

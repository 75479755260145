import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { DocumentCareer } from "../../../../models/Form/DocumentCareer";

type OwnProps = {
  career?: DocumentCareer;
};

const PerformanceCareerDetail: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "career"
  });
  const { career } = props;

  return (
    <section aria-labelledby="career-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="career-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {t("meta.title")}
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("questions.question1")}</p>
                <span className="text-xs text-gray-500 font-normal">
                  {t("questions.question1Helper")}
                </span>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {career?.employee?.comment1 ?? "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("questions.question2")}</p>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {career?.employee?.comment2 ?? "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("questions.question3")}</p>
                <span className="text-xs text-gray-500 font-normal">
                  {t("questions.question3Helper")}
                </span>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {career?.employee?.comment3 ?? "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("questions.question4")}</p>
                <span
                  className="text-xs text-gray-500 font-normal"
                  dangerouslySetInnerHTML={{
                    __html: t("questions.question4Helper")
                  }}
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {career?.employee?.comment4 ?? "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-3 border-t border-gray-200" />
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("fields.comments")}</p>
                {/* <span className="text-xs text-gray-500 font-normal">
                  {t("questions.question3Helper")}
                </span> */}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {career?.manager?.comment ?? "N/A"}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};

export default PerformanceCareerDetail;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { set } from "../../../../public/components/moment/src/lib/moment/get-set";
import { ErrorAction } from "../../models/Actions";
import { Employee } from "../../models/Employee/Employee";
import { BaseForm } from "../../models/Form/BaseForm";
import { RequestStatus } from "../../models/Request";

interface CurrentTeamlistState {
  status: RequestStatus;
  data?: BaseForm[];
  teamManagers?: Employee[];
  selectedTeamManager?: Employee;
  error?: Error;
  actionStatuses: {
    [key: string]: {
      status: RequestStatus;
      error?: string;
    };
  };
}

const initialState: CurrentTeamlistState = {
  status: RequestStatus.IDLE,
  actionStatuses: {}
};

const currentUserSlice = createSlice({
  name: "currentTeamlist",
  initialState,
  reducers: {
    // Load the current teamlist for the given employee and cycle.
    loadCurrentTeamlist(
      state,
      action: PayloadAction<{ employeeId: string; cycleId: string }>
    ) {
      state.actionStatuses[loadCurrentTeamlist.type] = {
        status: RequestStatus.PENDING
      };
    },
    loadCurrentTeamlistSuccess(
      state,
      action: PayloadAction<{ data: BaseForm[] }>
    ) {
      const { data } = action.payload;
      state.data = data;
      state.actionStatuses[loadCurrentTeamlist.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    loadCurrentTeamlistError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.actionStatuses[loadCurrentTeamlist.type] = {
        status: RequestStatus.ERROR
      };
    },

    // Load the team managers for the given cycle.
    loadTeamManagers(state, action: PayloadAction<{ cycleId: string }>) {
      state.actionStatuses[loadTeamManagers.type] = {
        status: RequestStatus.PENDING
      };
    },
    loadTeamManagersSuccess(
      state,
      action: PayloadAction<{ data: Employee[] }>
    ) {
      const { data } = action.payload;
      state.teamManagers = data;
      state.actionStatuses[loadTeamManagers.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    loadTeamManagersError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.actionStatuses[loadTeamManagers.type] = {
        status: RequestStatus.ERROR
      };
    },

    // Set the selected team manager.
    setSelectedTeamManager(
      state,
      action: PayloadAction<{ manager: Employee }>
    ) {
      const { manager } = action.payload;
      state.selectedTeamManager = manager;
    }
  }
});

export const {
  loadCurrentTeamlist,
  loadCurrentTeamlistSuccess,
  loadCurrentTeamlistError,
  loadTeamManagers,
  loadTeamManagersSuccess,
  loadTeamManagersError,
  setSelectedTeamManager
} = currentUserSlice.actions;

export default currentUserSlice.reducer;

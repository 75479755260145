import { useQuery } from "@tanstack/react-query";

import { getResourceNotes } from "../../services/NoteService";

export const useResourceNotes = (
  formUUID: string,
  resourceType: string,
  resourceId: string
) => {
  return useQuery({
    queryKey: ["notes", formUUID, resourceType, resourceId],
    queryFn: ({ signal }) =>
      getResourceNotes(signal, formUUID, resourceType, resourceId),
  });
};

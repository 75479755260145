import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { EyeIcon, PauseIcon, StopIcon } from "@heroicons/react/24/outline";

import { BaseForm } from "../../models/Form/BaseForm";
import { baseUrl } from "../../routes";
import { formatISODateStr } from "../../utils/dates";
import { getFullName, getInitials } from "../../utils/strings";
import DocumentMetrics from "../documents/DocumentMetrics";
import TeamlistStatus from "./TeamlistStatus";

type OwnProps = {
  teamlist?: BaseForm[];
  readOnly?: boolean;
};

const TeamlistTable: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { teamlist, readOnly = false } = props;

  return (
    <>
      {teamlist != null ? (
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300 text-left">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="w-full sm:w-4/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  {t("teamlist.table.headers.name")}
                </th>
                <th
                  scope="col"
                  className="lg:w-3/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("teamlist.table.headers.jobTitle")}
                </th>
                <th
                  scope="col"
                  className="lg:w-2/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("teamlist.table.headers.status")}
                </th>
                <th
                  scope="col"
                  className="lg:w-3/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {t("teamlist.table.headers.metrics")}
                </th>
                <th
                  scope="col"
                  className="lg:w-1/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {/* {t("teamlist.table.headers.warning")} */}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-white/5 bg-white">
              {teamlist.map((item) => (
                <tr
                  key={item.formUUID}
                  className={
                    item.isTemporarilyOutOfService || !item.isActive
                      ? "opacity-50"
                      : ""
                  }
                >
                  <td className="whitespace-nowrap py-5 px-4 text-sm">
                    <div className="flex items-center">
                      <div className="h-11 w-11 flex-shrink-0">
                        {item.relatedEmployee.avatarUrl != null ? (
                          <img
                            className="h-11 w-11 rounded-full"
                            src={item.relatedEmployee.avatarUrl}
                            alt={"Employee avatar"}
                          />
                        ) : (
                          <div className="h-11 w-11 rounded-full bg-gray-50 ring-2 ring-white flex items-center justify-center">
                            {!item.isActive ? (
                              <StopIcon className="h-6 w-6 text-red-600" />
                            ) : item.isTemporarilyOutOfService ? (
                              <PauseIcon className="h-6 w-6 text-orange-600" />
                            ) : (
                              <span className="text-sm font-medium leading-5 text-gray-500">
                                {getInitials(item.relatedEmployee.name)}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">
                          {readOnly ? (
                            <span>
                              {getFullName(item.relatedEmployee.name)}
                            </span>
                          ) : (
                            <Link
                              to={`${baseUrl}/performance/${item.formUUID}`}
                            >
                              {getFullName(item.relatedEmployee.name)}
                            </Link>
                          )}
                        </div>
                        <div className="mt-1 text-xs text-gray-500">
                          {item.relatedEmployee.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="hidden py-4 pl-0 pr-4 text-sm sm:table-cell sm:pr-8">
                    <div className="text-gray-900">
                      {
                        item.relatedFunction.description[
                          item.relatedEmployee.lang ?? "fr"
                        ]
                      }
                    </div>
                    {item.relatedEmployee.freeField6 ? (
                      <div className="mt-1 text-xs text-gray-500">
                        <time dateTime={item.relatedEmployee.freeField6}>
                          {formatISODateStr(item.relatedEmployee.freeField6)}
                        </time>
                        {" - "}
                        {item.relatedEmployee.freeField7 ? (
                          <time dateTime={item.relatedEmployee.freeField7}>
                            {formatISODateStr(item.relatedEmployee.freeField7)}
                          </time>
                        ) : (
                          "Present"
                        )}
                      </div>
                    ) : null}
                  </td>
                  <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                    <TeamlistStatus document={item} />
                  </td>
                  <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                    <DocumentMetrics document={item} />
                  </td>
                  <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                    {!(
                      readOnly ||
                      item.isTemporarilyOutOfService ||
                      !item.isActive
                    ) && (
                      <Link
                        to={`${baseUrl}/performance/${item.formUUID}`}
                        className="hidden px-2.5 py-1.5 text-sm font-semibold text-gray-900 sm:block"
                      >
                        <EyeIcon className="h-6 w-6 hover:text-indigo-600" />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default TeamlistTable;

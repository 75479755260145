import { FC, ReactElement, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import TopNavBarNew from "../../components/navigation/TopNavBarNew";
import { selectCurrentUser } from "../../redux/currentUser/selectors";
import { getFullName } from "../../utils/strings";

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

type OwnProps = ReduxProps;

const Layout: FC<OwnProps> = (props): ReactElement => {
  const { currentUser } = props;

  // Init Intercom
  useEffect(() => {
    if (currentUser != null && (window as any).Intercom != null) {
      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "k4uzh9lg",
        name: getFullName(currentUser.name),
        email: currentUser.email,
        created_at: Date.parse(currentUser.createdAt) / 1000,
        is_manager: currentUser.isManager,
        lang: `${currentUser.lang}`,
        user_hash: currentUser.intercomUserHash
      });
    }
  }, [currentUser]);

  return currentUser != null ? (
    <div className="min-h-full">
      <TopNavBarNew />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/auth/login" replace={true} />
  );
};

const withConnect = connect(mapStateToProps, null);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(Layout);

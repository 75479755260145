import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { TagIcon } from "@heroicons/react/20/solid";

import { BaseValue } from "../../../../models/Form/BaseValue";
import { SupportedLanguages } from "../../../../models/LangField";

type OwnProps = {
  value: BaseValue;
  lang: SupportedLanguages;
};

const ValueMeta: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const { value, lang = "fr" } = props;

  return (
    <section aria-labelledby="applicant-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {t("meta.title")}
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.type")}
              </dt>
              <dd className="mt-1 text-xl font-semibold text-indigo-600">
                {value.relatedType.name[lang]}
              </dd>
              <span className="mt-1 text-lg italic text-grey-600">
                {value.relatedType.definition[lang]}
              </span>
            </div>
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.behaviors")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul>
                  {value.relatedType.behaviors.map((behavior) => (
                    <li key={behavior._id} className="flex items-center">
                      <TagIcon
                        className="h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="ml-2 text-sm text-gray-900 italic">
                        {behavior.name[lang]}
                      </span>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.description")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {value.description}
              </dd>
            </div>
            {value.comments && (
              <div className="sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.comments")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{value.comments}</dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default ValueMeta;

import { FC, Fragment, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { MeetingType } from "../../models/Form/FormMeeting";
import { classNames } from "../../utils/styles";

type MeetingTypeOption = {
  key: MeetingType;
  name: string;
  description: string;
};

type OwnProps = {
  defaultType?: MeetingType;
  onChange: (newType: MeetingType) => void;
};

const MeetingTypeSelector: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", { keyPrefix: "meetings" });
  const { defaultType, onChange } = props;

  const options: MeetingTypeOption[] = Object.values(MeetingType).map(
    (type) => ({
      key: type,
      name: t(`types.${type}.title`),
      description: t(`types.${type}.description`)
    })
  );

  const [selected, setSelected] = useState(
    options.find((o) => o.key === defaultType) ?? options[0]
  );

  const onSelectType = (newType: MeetingTypeOption) => {
    setSelected(newType);
    onChange(newType.key);
  };

  return (
    <Listbox value={selected} onChange={onSelectType}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.key}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.name}
                          </p>
                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            active ? "text-indigo-200" : "text-gray-500",
                            "mt-2"
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                      // <>
                      //   <span
                      //     className={classNames(
                      //       selected ? "font-semibold" : "font-normal",
                      //       "block truncate"
                      //     )}
                      //   >
                      //     {option.name}
                      //   </span>

                      //   {selected ? (
                      //     <span
                      //       className={classNames(
                      //         active ? "text-white" : "text-indigo-600",
                      //         "absolute inset-y-0 right-0 flex items-center pr-4"
                      //       )}
                      //     >
                      //       <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      //     </span>
                      //   ) : null}
                      // </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default MeetingTypeSelector;

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import { TrophyIcon } from "@heroicons/react/24/outline";

import { BaseForm } from "../../models/Form/BaseForm";

type OwnProps = {
  document: BaseForm;
};

const DocumentBadge: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { document } = props;
  const { objectives, competences, developmentActions } = document;

  const isComplete =
    objectives.length > 2 &&
    competences.length > 2 &&
    developmentActions.length > 1;

  const id = `document-badge-${Math.random().toString(36).substring(7)}`;

  return (
    <>
      {isComplete ? (
        <TrophyIcon
          className="h-5 w-5 text-green-500"
          aria-hidden="true"
          data-tooltip-id={id}
          data-tooltip-content={t("metrics.badge.complete")}
        />
      ) : null}
      {isComplete ? <Tooltip id={id} /> : null}
    </>
  );
};

export default DocumentBadge;

import EditCareerModal from '../career/EditCareerModal';
import AddDocumentCoachModal from '../coach/AddDocumentCoachModal';
import AddCompetenceModal from '../competences/AddCompetenceModal';
import EditCompetenceModal from '../competences/EditCompetenceModal';
import RemoveCompetenceModal from '../competences/RemoveCompetenceModal';
import EditConclusionModal from '../conclusion/EditConclusionModal';
import AddDevActionModal from '../devActions/AddDevActionModal';
import EditDevActionModal from '../devActions/EditDevActionModal';
import RemoveDevActionModal from '../devActions/RemoveDevActionModal';
import DocumentNotifyModal from '../documents/DocumentNotifyModal';
import CourseDetailModal from '../integrations/riseUp/CourseDetailModal';
import DeleteMeetingModal from '../meetings/DeleteMeetingModal';
import MeetingDateModal from '../meetings/MeetingDateModal';
import DeleteNoteModal from '../notes/DeleteNoteModal';
import AddObjectiveModal from '../objectives/AddObjectiveModal';
import CompleteObjectiveModal from '../objectives/CompleteObjectiveModal';
import EditObjectiveModal from '../objectives/EditObjectiveModal';
import RemoveObjectiveModal from '../objectives/RemoveObjectiveModal';
import AddValueModal from '../values/AddValueModal';
import EditValueModal from '../values/EditValueModal';
import RemoveValueModal from '../values/RemoveValueModal';

export const MODALS_LIST: any = {
  // Objectives
  AddObjectiveModal,
  EditObjectiveModal,
  CompleteObjectiveModal,
  RemoveObjectiveModal,

  // Competences
  AddCompetenceModal,
  EditCompetenceModal,
  RemoveCompetenceModal,

  // Development actions
  AddDevActionModal,
  EditDevActionModal,
  RemoveDevActionModal,

  // Values
  AddValueModal,
  EditValueModal,
  RemoveValueModal,

  // Form
  EditCareerModal,
  EditConclusionModal,
  AddDocumentCoachModal,
  DocumentNotifyModal,

  // Meetings
  MeetingDateModal,
  DeleteMeetingModal,

  // Notes
  DeleteNoteModal,

  // Course catalog
  CourseDetailModal,
};

import { connect, ErrorMessage, Field, getIn } from "formik";
import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type OwnProps = {
  label: string;
  name: string;
  placeholder?: string;
  isOptional?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const InputText: FC<OwnProps> = (props): ReactElement => {
  const {
    formik,
    label,
    name,
    placeholder = "",
    isOptional = false,
    disabled = false,
    helperText,
  } = props;
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);

  return (
    <>
      <div className="flex justify-between">
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        {isOptional && (
          <span
            className="text-sm leading-6 text-gray-500"
            id="short-description-optional"
          >
            Optional
          </span>
        )}
      </div>
      <Field name={name}>
        {({ field }) => (
          <div className="relative mt-2 rounded-md">
            <input
              {...field}
              type="text"
              id={name}
              disabled={disabled}
              className={classNames(
                "block w-full rounded-md border-0 py-1.5 pr-12 shadow-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                disabled ? "text-slate-600 cursor-not-allowed" : "",
                touch && error
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                  : ""
              )}
              placeholder={placeholder}
              aria-describedby={`${name}-error`}
            />
            {helperText && (
              <p
                className="mt-2 text-xs text-gray-500"
                id={`${name}-description`}
              >
                {helperText}
              </p>
            )}
          </div>
        )}
      </Field>
      <ErrorMessage
        name={name}
        component="p"
        className="mt-2 text-xs text-red-600"
        id={`${name}-error`}
      />
    </>
  );
};

export default connect<OwnProps, any>(InputText);

import { AxiosResponse, CancelToken } from "axios";

import { Cycle } from "../models/Form/Cycle";
import { ApiV1 } from "../utils/api";

export const getCyclesList = async (
  cancelToken: CancelToken
): Promise<Cycle[]> => {
  const url = `/cycles`;

  try {
    return await ApiV1.get(url, {
      cancelToken,
    }).then((response: AxiosResponse) => {
      const data: Cycle[] = response.data.data;

      return data;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  AdjustmentsHorizontalIcon,
  CalendarIcon,
  EyeIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";

import { useMeetings } from "../../hooks/meetings/useMeetings";
import { BaseForm } from "../../models/Form/BaseForm";
import { baseUrl } from "../../routes";
import { formatISODateStr } from "../../utils/dates";
import { getAvatarUrl } from "../../utils/user";
import UserAvatar from "../users/UserAvatar";
import DocumentMetrics from "./DocumentMetrics";
import DocumentStatus from "./DocumentStatus";

type OwnProps = {
  document: BaseForm;
  readOnly?: boolean;
};

const DocumentListItem: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { document, readOnly = false } = props;

  // Fetch related meetings
  const { data: meetings, isLoading } = useMeetings(document.formUUID);
  const lastMeeting = useMemo(() => {
    if (meetings == null || meetings.length === 0) return null;
    return meetings[0];
  }, [meetings]);

  return (
    <li
      key={document._id}
      className="flex flex-wrap items-center justify-between gap-x-6 py-5 rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6 mb-3"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          {document.relatedPhase === "target" ? (
            <AdjustmentsHorizontalIcon className="h-6 w-6 text-blue-500" />
          ) : (
            <PencilSquareIcon className="h-6 w-6 text-orange-500" />
          )}
          <p className="text-sm font-semibold leading-6 text-gray-900 uppercase">
            {document.relatedPhase === "target" ? "target setting" : "review"}
          </p>
          <DocumentStatus document={document} />
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p className="whitespace-nowrap">
            {t("common.datetime.created")}{" "}
            <time dateTime={document.createdAt}>
              {formatISODateStr(document.createdAt)}
            </time>
          </p>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="whitespace-nowrap">
            {t("common.datetime.updated")}{" "}
            <time dateTime={document.modifiedAt}>
              {formatISODateStr(document.modifiedAt)}
            </time>
          </p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <dt className="sr-only">Manager</dt>
        <dd key={document.relatedManager._id}>
          <UserAvatar
            name={document.relatedManager.name}
            pictureUrl={getAvatarUrl(document.relatedManager.holaMember)}
            showName
          />
        </dd>
      </div>
      {!isLoading && lastMeeting && (
        <div className="flex flex-none items-center gap-x-4">
          <dt className="mt-0.5">
            <span className="sr-only">Upcoming meeting</span>
            <CalendarIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </dt>
          <dd>
            <time dateTime={lastMeeting.meetingDate}>
              {formatISODateStr(lastMeeting.meetingDate, "dd/MM/yy h:mm a")}
            </time>
          </dd>
        </div>
      )}
      <DocumentMetrics document={document} />
      {!readOnly && (
        <Link
          to={`${baseUrl}/performance/${document.formUUID}`}
          className="hidden px-2.5 py-1.5 text-sm font-semibold text-gray-900 sm:block"
          aria-disabled={readOnly}
        >
          <EyeIcon className="h-6 w-6 hover:text-indigo-600" />
        </Link>
      )}
    </li>
  );
};

export default DocumentListItem;

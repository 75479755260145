import { CoachRequestStatus } from "../models/Form/CoachRequest";
import API from "../utils/api";

export const updateCoachRequestStatus = async (
  requestId: string,
  status: CoachRequestStatus
): Promise<true> => {
  const url = `/coachrequests/${requestId}/${status}`;

  try {
    await API.patch(url, {});

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteMeeting } from "../../services/MeetingService";

export const useDeleteMeeting = (formUUID: string, meetingId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    // mutationKey: ["notes", formUUID, resourceType, resourceId, noteId],
    mutationFn: () => deleteMeeting(formUUID, meetingId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["meetings", formUUID]
      });
      queryClient.invalidateQueries({
        queryKey: ["activities", formUUID]
      });
    }
  });
};

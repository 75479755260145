import axios, { AxiosInstance } from "axios";
import { useNavigate } from "react-router";

const getCSRFToken = (): string => {
  const element = document.querySelector('meta[name~="csrf-token"]');

  return element ? element.getAttribute("content") || "" : "";
};

const AxiosClient: AxiosInstance = axios.create({
  baseURL: `${window.location.origin}/api/v2/`,
  withCredentials: true,
  headers: { "X-CSRF-Token": getCSRFToken() },
  adapter: axios.defaults.adapter!
});

export const ApiV1: AxiosInstance = axios.create({
  baseURL: `${window.location.origin}/api/v1/`,
  withCredentials: true,
  headers: { "X-CSRF-Token": getCSRFToken() },
  adapter: axios.defaults.adapter!
});

// Interceptors
AxiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // If using React Router
      const navigate = useNavigate();
      navigate("/auth/login");
    }

    return Promise.reject(error);
  }
);

ApiV1.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // If using React Router
      const navigate = useNavigate();
      navigate("/auth/login");
    }

    return Promise.reject(error);
  }
);

export default AxiosClient;

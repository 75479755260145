import { useQuery } from "@tanstack/react-query";

import { getUserCoachingRequests } from "../../services/EmployeeService";
import { getFormCoachingRequests } from "../../services/FormService";

export const useFormCoachRequests = (formUUID: string) => {
  return useQuery({
    queryKey: ["form-coach-request", formUUID],
    queryFn: ({ signal }) => getFormCoachingRequests(signal, formUUID)
  });
};

export const useUserCoachRequests = (employeeId: string, cycleId?: string) => {
  return useQuery({
    queryKey: ["user-coach-request", employeeId, cycleId],
    queryFn: ({ signal }) =>
      getUserCoachingRequests(signal, employeeId, cycleId),
    enabled: employeeId != null && cycleId != null
  });
};

import { FC, ReactElement } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectModalConfig } from "../../redux/currentApp/selectors";
import { closeModal } from "../../redux/currentApp/slice";
import { MODALS_LIST } from "./ModalList";

const mapStateToProps = createStructuredSelector({
  modal: selectModalConfig,
});

const mapDispatchToProps = {
  closeModal,
};

type ModalContainerProps = ReduxProps;

const ModalContainer: FC<ModalContainerProps> = (
  props: ModalContainerProps
): ReactElement => {
  const { modal, closeModal } = props;

  if (modal) {
    const { component, props: modalProps } = modal;

    const ModalComponent: FC<any> = MODALS_LIST[component];
    const componentProps: any = { ...modalProps, closeModal };

    return <ModalComponent {...componentProps} />;
  }

  return <></>;
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(ModalContainer);

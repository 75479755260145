import { all } from "@redux-saga/core/effects";

import currentAppSaga from "../redux/currentApp/sagas";
import currentDocumentCompetenceSaga from "../redux/currentDocument/competences/sagas";
import currentDocumentDevActionSaga from "../redux/currentDocument/devActions/sagas";
import currentDocumentObjectiveSaga from "../redux/currentDocument/objectives/sagas";
import currentDocumentSaga from "../redux/currentDocument/sagas";
import currentDocumentValueSaga from "../redux/currentDocument/values/sagas";
import currentEmployeeSaga from "../redux/currentEmployee/sagas";
import currentTeamlistSaga from "../redux/currentTeamlist/sagas";
import currentUserSaga from "../redux/currentUser/sagas";

export default function* rootSaga() {
  yield all([
    currentAppSaga(),
    currentUserSaga(),
    currentEmployeeSaga(),
    currentTeamlistSaga(),
    currentDocumentSaga(),
    currentDocumentObjectiveSaga(),
    currentDocumentCompetenceSaga(),
    currentDocumentDevActionSaga(),
    currentDocumentValueSaga()
  ]);
}

import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { PlusIcon } from "@heroicons/react/24/outline";

import { useFormCoachRequests } from "../../hooks/coachRequests/useCoachRequests";
import { Employee } from "../../models/Employee/Employee";
import { openModal } from "../../redux/currentApp/slice";
import DocumentCoachItem from "./DocumentCoachItem";

const mapDispatchToProps = {
  openModal
};

type OwnProps = {
  documentId: string;
  performanceCoachs: Employee[];
  canAddCoach?: boolean;
} & ReduxProps;

const DocumentCoachsList: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    performanceCoachs,
    canAddCoach = false,
    openModal
  } = props;
  const coachIds = performanceCoachs.map((coach) => coach._id);

  const { data: requests = [] } = useFormCoachRequests(documentId);

  const coachRequests = useMemo(() => {
    return requests.filter(
      (request) => !coachIds.includes(request.relatedCoach._id)
    );
  }, [coachIds, requests]);

  const openAddDocumentCoachModal = () => {
    openModal({
      modal: {
        component: "AddDocumentCoachModal",
        props: {
          documentId,
          excludeIds: coachIds
        }
      }
    });
  };

  return (
    <div>
      <ul className="mt-2">
        {performanceCoachs.map((coach) => (
          <li
            className="flex items-center justify-between py-3"
            key={coach._id}
          >
            <DocumentCoachItem coach={coach} />
          </li>
        ))}
        {coachRequests.map((request) => (
          <li
            className="flex items-center justify-between py-3"
            key={request._id}
          >
            <DocumentCoachItem
              coach={request.relatedCoach}
              status={request.status}
            />
          </li>
        ))}
        {coachIds.length < 2 && canAddCoach && (
          <li className="flex items-center justify-between py-2">
            <button
              type="button"
              className="group -ml-1 flex items-center rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={openAddDocumentCoachModal}
            >
              <span className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              </span>
              <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                {t("actions.add")}
              </span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DocumentCoachsList);

import { Navigate, RouteObject } from "react-router-dom";

import Layout from "./views/layout";
import MyPerformance from "./views/myPerformance";
import Performance from "./views/performance";
import PerformanceDetail from "./views/performance/components/PerformanceDetail";
import PerformanceEntityDetail from "./views/performance/components/PerformanceEntityDetail";
import PerformanceOverview from "./views/performance/components/PerformanceOverview";
import TeamList from "./views/teamlist";

export const baseUrl = "/spa";

const routes: RouteObject[] = [
  {
    path: baseUrl,
    element: <Layout />,
    children: [
      {
        path: `${baseUrl}/my-performance`,
        element: <MyPerformance />
      },
      {
        path: `${baseUrl}/performance/:documentId`,
        element: <Performance />,
        children: [
          { index: true, element: <PerformanceOverview /> },
          {
            path: `${baseUrl}/performance/:documentId/:section`,
            element: <PerformanceDetail />
          },
          {
            path: `${baseUrl}/performance/:documentId/:section/:id`,
            element: <PerformanceEntityDetail />
          }
        ]
      },
      {
        path: `${baseUrl}/teamlist`,
        element: <TeamList />
      },
      { path: "*", element: <Navigate to={`${baseUrl}/my-performance`} /> }
    ]
  },
  { path: "*", element: <Navigate to={`${baseUrl}/my-performance`} /> }
];

export default routes;

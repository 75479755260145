import { FC, ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import Spinner from "../../components/loaders/Spinner";
import SubNavBar from "../../components/navigation/SubNavBar";
import { FormRole } from "../../models/Form/BaseForm";
import { selectCurrentCycle } from "../../redux/currentApp/selectors";
import {
  loadFunctionalCompetences,
  loadGenericCompetences,
  openModal
} from "../../redux/currentApp/slice";
import {
  isLoadingCurrentDocument,
  selectCurrentDocument
} from "../../redux/currentDocument/selectors";
import { loadDocument } from "../../redux/currentDocument/slice";
import { selectCurrentEmployee } from "../../redux/currentEmployee/selectors";
import { selectCurrentUser } from "../../redux/currentUser/selectors";
import { guideLinks } from "../../utils/links";
import PerformanceHeaderNew from "./components/PerformanceHeaderNew";
import PerformanceInnerNav from "./components/PerformanceInnerNav";

const infoLinks = {
  target:
    "https://performance-help.synapze.be/en/articles/6883861-wizard-target-setting-2023-",
  review:
    "https://performance-help.synapze.be/en/articles/6885383-faq-performance-review-2022-"
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentEmployee: selectCurrentEmployee,
  currentCycle: selectCurrentCycle,
  isLoading: isLoadingCurrentDocument,
  document: selectCurrentDocument
});

const mapDispatchToProps = {
  loadDocument,
  loadGenericCompetences,
  loadFunctionalCompetences,
  openModal
};

type OwnProps = ReduxProps;

const Performance: FC<OwnProps> = (props): ReactElement => {
  const { t: tCommon } = useTranslation();
  const { documentId } = useParams();

  const {
    isLoading,
    document,
    currentUser,
    currentEmployee,
    loadDocument,
    loadGenericCompetences,
    loadFunctionalCompetences,
    openModal
  } = props;

  // Define roles
  const currentRole = useMemo(() => {
    const isOwner = currentEmployee?._id === document?.relatedEmployee._id;
    const isManager = currentEmployee?._id === document?.relatedManager._id;
    const isCoach = document?.performanceCoachs?.some(
      (coach) => coach._id === currentEmployee?._id
    );

    if (isOwner) return FormRole.employee;
    if (isManager) return FormRole.manager;
    if (isCoach) return FormRole.coach;
    return undefined;
  }, [currentEmployee, document]);

  useEffect(() => {
    if (documentId != null) {
      loadDocument({
        formUUID: documentId
      });
    }
  }, [loadDocument, documentId]);

  useEffect(() => {
    if (document != null) {
      const { relatedProfile: profileId } = document.relatedFunction;
      loadGenericCompetences({ profileId });
      loadFunctionalCompetences({ profileId });
    }
  }, [document, loadGenericCompetences, loadFunctionalCompetences]);

  return (
    <>
      {isLoading || document == null ? (
        <div className="text-center m-10">
          <Spinner />
        </div>
      ) : (
        <>
          <SubNavBar
            breadcrumbs={[
              "Performance",
              `${document.relatedCycle.year}`,
              document.relatedPhase
            ]}
            linkUrl={
              document.relatedPhase === "target"
                ? guideLinks?.[currentEmployee?.lang ?? "fr"]
                : `${infoLinks["review"]}${currentEmployee?.lang ?? "fr"}`
            }
            linkLabel={
              document.relatedPhase === "target"
                ? tCommon("navigation.guide")
                : "FAQ"
            }
          />
          <main className="flex flex-col">
            <div className="mx-auto w-full max-w-7xl flex-grow lg:flex xl:px-6">
              <div className="min-w-0 flex-1 flex">
                {/* Document header */}
                <PerformanceHeaderNew
                  currentUser={currentUser}
                  document={document}
                  role={currentRole}
                  openModal={openModal}
                />

                {/* Nav + Main content */}
                <div className="min-w-0 flex-1 py-4 px-2">
                  <PerformanceInnerNav />
                  <Outlet />
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(Performance);

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import { AcademicCapIcon, UsersIcon } from "@heroicons/react/24/outline";

import { MeetingType } from "../../models/Form/FormMeeting";

type OwnProps = {
  type: MeetingType;
};

const MeetingTypeIcon: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", { keyPrefix: "meetings" });
  const { type } = props;

  const id = `meeting-type-${Math.random().toString(36).substring(7)}`;

  return (
    <>
      {type === MeetingType.ONE_ON_ONE ? (
        <UsersIcon
          className="h-5 w-5 text-gray-400 inline-block mr-1"
          data-tooltip-id={id}
          data-tooltip-content={t(`types.${type}.title`)}
        />
      ) : (
        <AcademicCapIcon
          className="h-5 w-5 text-gray-400 inline-block mr-1"
          data-tooltip-id={id}
          data-tooltip-content={t(`types.${type}.title`)}
        />
      )}
      <Tooltip id={id} />
    </>
  );
};

export default MeetingTypeIcon;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  CreateMeetingPayload,
  createNewMeeting
} from "../../services/MeetingService";

export const useAddMeeting = (formUUID: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    // mutationKey: ["notes", formUUID, resourceType, resourceId],
    mutationFn: (payload: CreateMeetingPayload) =>
      createNewMeeting(formUUID, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["meetings", formUUID]
      });
      queryClient.invalidateQueries({
        queryKey: ["activities", formUUID]
      });
    }
  });
};

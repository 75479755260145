import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createNewResourceNote } from "../../services/NoteService";

export const useAddResourceNote = (
  formUUID: string,
  resourceType: string,
  resourceId: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    // mutationKey: ["notes", formUUID, resourceType, resourceId],
    mutationFn: (payload: { body: string }) =>
      createNewResourceNote(formUUID, resourceType, resourceId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notes", formUUID, resourceType, resourceId]
      });
      queryClient.invalidateQueries({
        queryKey: ["activities", formUUID, resourceType, resourceId]
      });
    }
  });
};

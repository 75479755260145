import { FC, ReactElement } from "react";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type OwnProps = {
  message: string;
  title?: string;
  isHtml?: boolean;
};

const WarningBanner: FC<OwnProps> = (props): ReactElement => {
  const { message, title, isHtml = false } = props;

  return (
    <div className="rounded-md bg-orange-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-orange-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {title && (
            <h3 className="text-sm font-medium text-orange-700">{title}</h3>
          )}
          <div className="mt-2 text-sm text-orange-700">
            {isHtml ? (
              <div dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              <p>{message}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningBanner;

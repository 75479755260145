import "./App.css";

import { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import Spinner from "./components/loaders/Spinner";
import ModalContainer from "./components/modal/ModalContainer";
import TopBannerNew from "./components/navigation/TopBannerNew";
import NotificationContainer from "./components/notification/NotificationContainer";
import { appIsInitialized } from "./redux/currentApp/selectors";
import {
  loadCyclesList,
  loadDevelopmentActionThemes,
  loadObjectivesTypes,
  loadValueTypes
} from "./redux/currentApp/slice";
import {
  isLoadingCurrentUser,
  selectCurrentUser
} from "./redux/currentUser/selectors";
import { loadCurrentUser } from "./redux/currentUser/slice";
import routes from "./routes";

const mapStateToProps = createStructuredSelector({
  isLoadingUser: isLoadingCurrentUser,
  currentUser: selectCurrentUser,
  appInitialized: appIsInitialized
});

const mapDispatchToProps = {
  loadCurrentUser,
  loadCyclesList,
  loadObjectivesTypes,
  loadDevelopmentActionThemes,
  loadValueTypes
};

type AppProps = ReduxProps;

const App: FC<AppProps> = (props) => {
  const {
    currentUser,
    isLoadingUser,
    appInitialized,
    loadCurrentUser,
    loadCyclesList,
    loadObjectivesTypes,
    loadDevelopmentActionThemes,
    loadValueTypes
  } = props;

  useEffect(() => {
    if (!isLoadingUser && currentUser == null) {
      loadCurrentUser();
    }
  }, [currentUser, loadCurrentUser, isLoadingUser]);

  useEffect(() => {
    if (!isLoadingUser && currentUser != null) {
      loadCyclesList();
      loadObjectivesTypes();
      loadDevelopmentActionThemes();
      loadValueTypes();
    }
  }, [
    currentUser,
    loadCyclesList,
    loadObjectivesTypes,
    loadDevelopmentActionThemes,
    loadValueTypes,
    isLoadingUser
  ]);

  let element = useRoutes(routes);

  return currentUser == null && !appInitialized ? (
    <Spinner />
  ) : (
    <>
      <TopBannerNew />
      {element}
      <ModalContainer />
      <NotificationContainer />
    </>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(App);

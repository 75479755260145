import { AxiosResponse } from "axios";

import { Phase } from "../models/Form/BaseForm";
import API from "../utils/api";

export const downloadFormMetrics = async (
  cycleId: string,
  phase: Phase,
  managerId: string
): Promise<Blob> => {
  const url = "/downloads/forms";

  try {
    return await API.post(
      url,
      { cycle: cycleId, phase, manager: managerId },
      {
        responseType: "blob"
      }
    ).then((response: AxiosResponse) => {
      const file = response.data;

      return file;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

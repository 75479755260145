import { FC, ReactElement } from "react";

import {
  CubeTransparentIcon, FireIcon, PuzzlePieceIcon, RocketLaunchIcon
} from "@heroicons/react/24/outline";

import { BaseForm } from "../../models/Form/BaseForm";

type OwnProps = {
  document: BaseForm;
};

const DocumentMetrics: FC<OwnProps> = (props): ReactElement => {
  const { document } = props;

  return (
    <dl className="flex flex-none gap-x-8 sm:w-auto">
      <div className="flex w-full gap-x-2.5">
        <dt>
          <span className="sr-only">Total objectives</span>
          <RocketLaunchIcon
            className="h-6 w-6 text-teal-500"
            aria-hidden="true"
          />
        </dt>
        <dd className="text-sm leading-6 text-gray-900">
          {document?.objectives?.length ?? 0}
        </dd>
        <dt>
          <span className="sr-only">Total competences</span>
          <PuzzlePieceIcon
            className="h-6 w-6 text-orange-400"
            aria-hidden="true"
          />
        </dt>
        <dd className="text-sm leading-6 text-gray-900">
          {document?.competences?.length ?? 0}
        </dd>
        <dt>
          <span className="sr-only">Total development actions</span>
          <CubeTransparentIcon
            className="h-6 w-6 text-violet-500"
            aria-hidden="true"
          />
        </dt>
        <dd className="text-sm leading-6 text-gray-900">
          {document?.developmentActions?.length ?? 0}
        </dd>
        <dt>
          <span className="sr-only">Total Lead dimensions</span>
          <FireIcon className="h-6 w-6 text-rose-500" aria-hidden="true" />
        </dt>
        <dd className="text-sm leading-6 text-gray-900">
          {document?.values?.length ?? 0}
        </dd>
      </div>
    </dl>
  );
};

export default DocumentMetrics;

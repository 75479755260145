import { useMutation } from "@tanstack/react-query";

import { addCourseRegistrationToDevAction } from "../../../services/DevActionService";

export const useAddCourseRegistrationToDevAction = (
  formUUID: string,
  devActionId: string
) => {
  return useMutation({
    mutationFn: (registrationId: string) =>
      addCourseRegistrationToDevAction(formUUID, devActionId, registrationId)
  });
};

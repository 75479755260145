import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import { useDeleteMeeting } from "../../hooks/meetings/useDeleteMeeting";
import { FormMeeting } from "../../models/Form/FormMeeting";
import {
  NotificationConfig,
  NotificationType
} from "../../models/Notification";
import Modal from "../modal/Modal";

interface OwnProps {
  documentId: string;
  meeting: FormMeeting;
  notify: (notification: NotificationConfig) => void;
  closeModal: () => void;
}

const DeleteMeetingModal: FC<OwnProps> = (props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "meetings.delete"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { documentId, meeting, closeModal, notify } = props;

  const mutation = useDeleteMeeting(documentId, meeting._id);

  const onDeleteHandler = () => {
    // Mutate the note
    mutation.mutate();

    // Notify
    notify({
      type: NotificationType.SUCCESS,
      title: tCommon("notifications.deleteSuccess"),
      message: t("messages.success")
    });

    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                {t("title")}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{t("confirmation")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            onClick={onDeleteHandler}
          >
            {t("cta")}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModal()}
          >
            {tCommon("actions.cancel")}
          </button>
        </div>
      </>
    </Modal>
  );
};

export default DeleteMeetingModal;

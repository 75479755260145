import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import { RawBaseFormConclusion } from "../../models/Form/BaseForm";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import {
  isLoadingUpdateCurrentDocument, selectUpdateCurrentDocumentError,
  selectUpdateCurrentDocumentStatus
} from "../../redux/currentDocument/selectors";
import { clearUpdateDocumentStatus, updateDocument } from "../../redux/currentDocument/slice";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import InputText from "../forms/InputText";
import InputTextArea from "../forms/InputTextArea";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingUpdateCurrentDocument,
  status: selectUpdateCurrentDocumentStatus,
  formError: selectUpdateCurrentDocumentError
});

const mapDispatchToProps = {
  updateForm: updateDocument,
  clearStatus: clearUpdateDocumentStatus,
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  conclusion: RawBaseFormConclusion;
  isOwner: boolean;
  isManager: boolean;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const EditConclusionModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "conclusion"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    conclusion,
    isOwner,
    isManager,
    isLoading,
    status,
    formError,
    updateForm,
    clearStatus,
    notify,
    closeModal
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.editSuccess"),
        message: t("update.messages.success")
      });

      // Cleanup
      closeModal();
      clearStatus();
      queryClient.invalidateQueries({
        queryKey: ["activities", documentId]
      });
    }
  }, [
    isLoading,
    status,
    formError,
    closeModal,
    clearStatus,
    notify,
    queryClient,
    t,
    tCommon,
    documentId
  ]);

  // Component state
  const initialState = {
    linkedFile: conclusion?.linkedFile ?? "",
    employeeComments: conclusion?.employeeComments ?? "",
    managerComments: conclusion?.managerComments ?? ""
  };

  const validationSchema = Yup.object({
    linkedFile: Yup.string().url(t("form.errors.link")),
    employeeComments: Yup.string().max(5000, "Must be 5000 characters or less"),
    managerComments: Yup.string().max(5000, "Must be 5000 characters or less")
  });

  return (
    <Modal closeModal={closeModal}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          updateForm({
            formUUID: documentId,
            data: {
              ...values
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="sm:flex sm:items-start">
              <div className="space-y-12 w-full">
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {t("update.title")}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    {t("update.subtitle")}
                  </p>
                  <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    {/* Custom link */}
                    <div className="col-span-full">
                      <InputText
                        label={t("fields.link")}
                        name="linkedFile"
                        placeholder="https://www.partena.be"
                        helperText={t("form.helpers.link")}
                      />
                    </div>

                    {/* Employee conclusion */}
                    <div className="col-span-full">
                      <InputTextArea
                        label={t("fields.description")}
                        name="employeeComments"
                        disabled={!isOwner}
                      />
                    </div>
                    {/* Manager conclusion */}
                    <div className="col-span-full">
                      <InputTextArea
                        label={t("fields.comments")}
                        name="managerComments"
                        disabled={!isManager}
                      />
                    </div>
                  </div>
                  {errorMessage != null && (
                    <div className="mt-5">
                      <ErrorBanner message={errorMessage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
                  isSubmitting ? "cursor-not-allowed opacity-50 ml-2" : ""
                )}
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size="small" />}
                {tCommon("actions.save")}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => closeModal()}
              >
                {tCommon("actions.cancel")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(EditConclusionModal);

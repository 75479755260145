import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorAction } from "../../../models/Actions";
import {
  BaseDevelopmentAction, RawBaseDevelopmentAction
} from "../../../models/Form/BaseDevelopmentAction";
import { RequestStatus } from "../../../models/Request";
import { RiseUpCourse } from "../../../models/integrations/RiseUp/RiseUpCourse";
import { RiseUpCourseRegistration } from "../../../models/integrations/RiseUp/RiseUpCourseRegistration";

interface CurrentDocumentDevActionsState {
  status: RequestStatus;
  developmentActions?: BaseDevelopmentAction[];
  error?: Error;
  actionStatuses: {
    [key: string]: {
      status: RequestStatus;
      error?: string;
    };
  };
}

const initialState: CurrentDocumentDevActionsState = {
  status: RequestStatus.IDLE,
  actionStatuses: {}
};

const currentDocumentDevActionsSlice = createSlice({
  name: "currentDocumentDevActions",
  initialState,
  reducers: {
    // Load the document values
    setDocumentDevActions(
      state,
      action: PayloadAction<{ devActions: BaseDevelopmentAction[] }>
    ) {
      const { devActions } = action.payload;
      state.developmentActions = devActions;
    },

    // Create a new development action for the current document.
    createDocumentDevAction(
      state,
      action: PayloadAction<{
        formUUID: string;
        data: RawBaseDevelopmentAction;
      }>
    ) {
      state.actionStatuses[createDocumentDevAction.type] = {
        status: RequestStatus.PENDING
      };
    },
    createDocumentDevActionSuccess(
      state,
      action: PayloadAction<{ data: BaseDevelopmentAction }>
    ) {
      const { data } = action.payload;
      state.developmentActions?.push(data);
      state.actionStatuses[createDocumentDevAction.type].status =
        RequestStatus.SUCCESS;
    },
    createDocumentDevActionError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[createDocumentDevAction.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearCreateDocumentDevActionStatus(state) {
      delete state.actionStatuses[createDocumentDevAction.type];
    },

    // Update an existing development action for the current document.
    updateDocumentDevAction(
      state,
      action: PayloadAction<{
        formUUID: string;
        devActionId: string;
        data: RawBaseDevelopmentAction;
      }>
    ) {
      state.actionStatuses[updateDocumentDevAction.type] = {
        status: RequestStatus.PENDING
      };
    },
    updateDocumentDevActionSuccess(
      state,
      action: PayloadAction<{ data: BaseDevelopmentAction }>
    ) {
      const { data } = action.payload;
      const index = state.developmentActions?.findIndex(
        (devAction) => devAction._id === data._id
      );
      if (index !== undefined && index !== -1) {
        state.developmentActions?.splice(index, 1, data);
      }
      state.actionStatuses[updateDocumentDevAction.type].status =
        RequestStatus.SUCCESS;
    },
    updateDocumentDevActionError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[updateDocumentDevAction.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearUpdateDocumentDevActionStatus(state) {
      delete state.actionStatuses[updateDocumentDevAction.type];
    },

    // Delete an existing development action for the current document.
    deleteDocumentDevAction(
      state,
      action: PayloadAction<{ formUUID: string; devActionId: string }>
    ) {
      state.actionStatuses[deleteDocumentDevAction.type] = {
        status: RequestStatus.PENDING
      };
    },
    deleteDocumentDevActionSuccess(
      state,
      action: PayloadAction<{ devActionId: string }>
    ) {
      const { devActionId } = action.payload;
      const index = state.developmentActions?.findIndex(
        (devAction) => devAction._id === devActionId
      );
      if (index !== undefined && index !== -1) {
        state.developmentActions?.splice(index, 1);
      }
      state.actionStatuses[deleteDocumentDevAction.type].status =
        RequestStatus.SUCCESS;
    },
    deleteDocumentDevActionError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[deleteDocumentDevAction.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearDeleteDocumentDevActionStatus(state) {
      delete state.actionStatuses[deleteDocumentDevAction.type];
    },

    // Add course to the current to a dev action for the current document.
    addDocumentDevActionCourse(
      state,
      action: PayloadAction<{
        devActionId: string;
        course: RiseUpCourse;
      }>
    ) {
      const { devActionId, course } = action.payload;
      // Find the dev action with the given ID.
      const devAction = state.developmentActions?.find(
        (item) => item._id === devActionId
      );

      // Add the course to the dev action.
      if (devAction) {
        devAction.courses.push(course);
      }
    },
    removeDocumentDevActionCourse(
      state,
      action: PayloadAction<{
        devActionId: string;
        courseId: string;
      }>
    ) {
      const { devActionId, courseId } = action.payload;
      // Find the dev action with the given ID.
      const devAction = state.developmentActions?.find(
        (item) => item._id === devActionId
      );

      // Remove the course from the dev action.
      if (devAction) {
        const index = devAction.courses.findIndex((course) => course._id === courseId);
        if (index !== undefined && index !== -1) {
          devAction.courses.splice(index, 1);
        }
      }
    },

    // Add course registration to the current to a dev action for the current document.
    addDocumentDevActionCourseRegistration(
      state,
      action: PayloadAction<{
        devActionId: string;
        registration: RiseUpCourseRegistration;
      }>
    ) {
      const { devActionId, registration } = action.payload;
      // Find the dev action with the given ID.
      const devAction = state.developmentActions?.find(
        (item) => item._id === devActionId
      );

      // Add the registration to the dev action.
      if (devAction) {
        devAction.courseRegistrations.push(registration);
      }
    },
    removeDocumentDevActionCourseRegistration(
      state,
      action: PayloadAction<{
        devActionId: string;
        registrationId: string;
      }>
    ) {
      const { devActionId, registrationId } = action.payload;
      // Find the dev action with the given ID.
      const devAction = state.developmentActions?.find(
        (item) => item._id === devActionId
      );

      // Remove the course from the dev action.
      if (devAction) {
        const index = devAction.courseRegistrations.findIndex((registration) => registration._id === registrationId);
        if (index !== undefined && index !== -1) {
          devAction.courseRegistrations.splice(index, 1);
        }
      }
    },
  }
});

export const {
  setDocumentDevActions,
  createDocumentDevAction,
  createDocumentDevActionSuccess,
  createDocumentDevActionError,
  clearCreateDocumentDevActionStatus,
  updateDocumentDevAction,
  updateDocumentDevActionSuccess,
  updateDocumentDevActionError,
  clearUpdateDocumentDevActionStatus,
  deleteDocumentDevAction,
  deleteDocumentDevActionSuccess,
  deleteDocumentDevActionError,
  clearDeleteDocumentDevActionStatus,
  addDocumentDevActionCourse,
  removeDocumentDevActionCourse,
  addDocumentDevActionCourseRegistration,
  removeDocumentDevActionCourseRegistration
} = currentDocumentDevActionsSlice.actions;

export default currentDocumentDevActionsSlice.reducer;

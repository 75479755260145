import { useQuery } from "@tanstack/react-query";

import { getAllCourses, getCourse } from "../../../services/RiseUpService";

export const useCourses = () => {
  return useQuery({
    queryKey: ["courses"],
    queryFn: ({ signal }) => getAllCourses(signal)
  });
};

export const useCourse = (courseId: string) => {
  return useQuery({
    queryKey: ["courses", courseId],
    queryFn: ({ signal }) => getCourse(signal, courseId)
  });
};

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addDocumentCoach } from "../../services/FormService";

export const useAddCoach = (formUUID: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (coachId: string) => addDocumentCoach(formUUID, coachId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["form-coach-request", formUUID]
      });
      queryClient.invalidateQueries({
        queryKey: ["user-coach-request"]
      });
      queryClient.invalidateQueries({
        queryKey: ["activities", formUUID]
      });
    }
  });
};

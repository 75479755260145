import { ActivityLog, ActivityLogResourceType } from "../models/Logs/ActivityLog";
import API from "../utils/api";

export const getActivityLogs = async (
  signal: AbortSignal,
  formUUID: string,
  resourceType?: ActivityLogResourceType,
  resourceId?: string
): Promise<ActivityLog[]> => {
  const baseUrl = `/form/${formUUID}/activities`;
  const url =
    resourceType != null && resourceId
      ? `${baseUrl}/${resourceType}/${resourceId}`
      : baseUrl;

  try {
    const response = await API.get<{ data: ActivityLog[] }>(url, {
      signal,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

import { AxiosResponse, CancelToken } from "axios";

import { BaseForm, RawBaseForm } from "../models/Form/BaseForm";
import { ShortCoachRequest } from "../models/Form/CoachRequest";
import API from "../utils/api";

export const getForm = async (
  cancelToken: CancelToken,
  formUUID: string
): Promise<BaseForm> => {
  const url = `/form/${formUUID}`;

  try {
    return await API.get(url, {
      cancelToken
    }).then((response: AxiosResponse) => {
      const document: BaseForm = response.data.data;

      return document;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const updateForm = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseForm
): Promise<true> => {
  const url = `/form/${formUUID}/save`;

  try {
    await API.patch(url, payload, {
      cancelToken
    });

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const notifyForm = async (formUUID: string): Promise<true> => {
  const url = `/form/${formUUID}/notify`;

  try {
    await API.post(url, {});

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const setFormDiscussionDate = async (
  cancelToken: CancelToken,
  formUUID: string,
  discussionDate: Date
): Promise<true> => {
  const url = `/form/${formUUID}/discussion-date`;

  try {
    await API.patch(
      url,
      { date: discussionDate.toISOString() },
      { cancelToken }
    );

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const addDocumentCoach = async (
  formUUID: string,
  selectedCoach: string
): Promise<true> => {
  const url = `/form/${formUUID}/coach`;

  try {
    await API.patch(url, { selectedCoach });

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const deleteDocumentCoach = async (
  formUUID: string,
  coachId: string
): Promise<true> => {
  const url = `/form/${formUUID}/coach/${coachId}`;

  try {
    await API.delete(url);

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const getFormCoachingRequests = async (
  signal: AbortSignal,
  formUUID: string
): Promise<ShortCoachRequest[]> => {
  const url = `/form/${formUUID}/coach-requests`;

  try {
    return await API.get(url, {
      signal
    }).then((response: AxiosResponse) => {
      const requests: ShortCoachRequest[] = response.data.data;

      return requests;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { LinkIcon } from "@heroicons/react/20/solid";

import { RiseUpCourseRegistration } from "../../../models/integrations/RiseUp/RiseUpCourseRegistration";
import ActionButton from "../../buttons/ActionButton";
import DefaultButton from "../../buttons/DefaultButton";
import UserCourseRegistrationSelector from "../../selectors/UserCourseRegistrationSelector";
import CourseRegistrationItem from "./CourseRegistrationItem";

type OwnProps = {
  courseRegistrations: RiseUpCourseRegistration[];
  label: string;
  memberId?: string;
  emptyLabel?: string;
  onLinkRegistration?: (registration: RiseUpCourseRegistration) => void;
  onUnlinkRegistration?: (registration: RiseUpCourseRegistration) => void;
};

const LinkedCourseRegistrationsList: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const {
    memberId,
    courseRegistrations,
    label,
    emptyLabel = t("common.emptyStates.courses"),
    onLinkRegistration,
    onUnlinkRegistration
  } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-l font-medium text-indigo-600">{label}</h3>
        <div className="mt-3 sm:ml-4 sm:mt-0">
          <ActionButton
            label={t("common.actions.linkCourse")}
            onClick={() => setIsEditing(true)}
            disabled={!memberId || isEditing || !onLinkRegistration}
            icon={LinkIcon}
          />
        </div>
      </div>
      {memberId && isEditing && onLinkRegistration && (
        <div className="flex items-center space-between mt-3">
          <div className="w-full mr-2">
            <UserCourseRegistrationSelector
              memberId={memberId}
              onSelectItem={(item) => {
                onLinkRegistration(item);
                setIsEditing(false);
              }}
              exclude={courseRegistrations.map(
                (courseRegistration) => courseRegistration._id
              )}
            />
          </div>
          <DefaultButton
            label={t("common.actions.cancel")}
            onClick={() => setIsEditing(false)}
          />
        </div>
      )}
      <dd className="mt-3 text-sm text-gray-900">
        {courseRegistrations.length ? (
          <ul className="divide-y divide-gray-100">
            {courseRegistrations.map((courseRegistration) => (
              <CourseRegistrationItem
                key={courseRegistration._id}
                courseRegistration={courseRegistration}
                onRemoveItem={onUnlinkRegistration}
              />
            ))}
          </ul>
        ) : (
          <p className="font-medium text-grey-500">{emptyLabel}</p>
        )}
      </dd>
    </>
  );
};

export default LinkedCourseRegistrationsList;

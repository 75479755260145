import { FC, Fragment, ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import { Phase } from "../../models/Form/BaseForm";
import { Cycle } from "../../models/Form/Cycle";
import { SupportedLanguages } from "../../models/LangField";
import { selectCyclesList } from "../../redux/currentApp/selectors";
import { setCurrentCycle } from "../../redux/currentApp/slice";
import { classNames } from "../../utils/styles";

const statuses: Record<string, string> = {
  target: "text-blue-600 bg-blue-50 ring-blue-500/10",
  review: "text-orange-700 bg-orange-50 ring-orange-600/20"
};

const mapStateToProps = createStructuredSelector({
  cycles: selectCyclesList
});

const mapDispatchToProps = {
  setCurrentCycle
};

type OwnProps = {
  lang: SupportedLanguages;
  currentCycle?: Cycle;
} & ReduxProps;

const CycleSelector: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { cycles, lang, currentCycle, setCurrentCycle } = props;

  const [selected, setSelected] = useState<Cycle | null>(currentCycle ?? null);

  const selectorLabel = useMemo(() => {
    return selected != null ? selected?.year : t("common.selectors.cycles");
  }, [selected, t]);

  const onChangeHandler = (cycle: Cycle) => {
    setSelected(cycle);
    setCurrentCycle({ cycle });
  };

  useEffect(() => {
    if (currentCycle != null && selected == null) {
      setSelected(currentCycle ?? null);
    }
  }, [currentCycle, selected]);

  return (
    <Listbox value={selected} onChange={onChangeHandler}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-48 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="inline-flex justify-between w-full truncate">
                <span className="block font-bold truncate">
                  {selectorLabel}
                </span>
                <span
                  className={classNames(
                    statuses[selected?.prOpened ? Phase.review : Phase.target],
                    "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                  )}
                >
                  {selected?.prOpened ? Phase.review : Phase.target}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-44 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {(cycles || []).map((cycle) => (
                  <Listbox.Option
                    key={cycle?._id ?? "cycle"}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={cycle}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {cycle?.name?.[lang]}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(CycleSelector);

import Axios, { CancelTokenSource } from "axios";

import { call, put, takeLatest } from "@redux-saga/core/effects";

import { BaseCompetence } from "../../../models/Form/BaseCompetence";
import {
  createNewCompetence, deleteCompetence, updateCompetence
} from "../../../services/CompetenceService";
import {
  createDocumentCompetence, createDocumentCompetenceError, createDocumentCompetenceSuccess,
  deleteDocumentCompetence, deleteDocumentCompetenceError, deleteDocumentCompetenceSuccess,
  updateDocumentCompetence, updateDocumentCompetenceError, updateDocumentCompetenceSuccess
} from "./slice";

function* createDocumentCompetenceSaga(
  action: ReturnType<typeof createDocumentCompetence>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, data } = action.payload;

  try {
    const response: BaseCompetence = yield call(
      createNewCompetence,
      signal.token,
      formUUID,
      data
    );

    yield put(createDocumentCompetenceSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      createDocumentCompetenceError({
        error: message,
        payload: message
      })
    );
  }
}

function* updateDocumentCompetenceSaga(
  action: ReturnType<typeof updateDocumentCompetence>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, competenceId, data } = action.payload;

  try {
    const response: BaseCompetence = yield call(
      updateCompetence,
      signal.token,
      formUUID,
      competenceId,
      data
    );

    yield put(updateDocumentCompetenceSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      updateDocumentCompetenceError({
        error: message,
        payload: message
      })
    );
  }
}

function* deleteDocumentCompetenceSaga(
  action: ReturnType<typeof deleteDocumentCompetence>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, competenceId } = action.payload;

  try {
    yield call(deleteCompetence, signal.token, formUUID, competenceId);

    yield put(deleteDocumentCompetenceSuccess({ competenceId }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      deleteDocumentCompetenceError({
        error: message,
        payload: message
      })
    );
  }
}

export default function* currentDocumentCompetenceSaga() {
  yield takeLatest(createDocumentCompetence.type, createDocumentCompetenceSaga);
  yield takeLatest(updateDocumentCompetence.type, updateDocumentCompetenceSaga);
  yield takeLatest(deleteDocumentCompetence.type, deleteDocumentCompetenceSaga);
}

import { createSelector } from "reselect";

import { RequestStatus } from "../../../models/Request";
import { RootState } from "../../../store/store";
import {
  createDocumentCompetence, deleteDocumentCompetence, updateDocumentCompetence
} from "./slice";

const selectCurrentDocumentCompetencesState = (state: RootState) =>
  state.currentDocument.competences;

export const selectCurrentDocumentCompetences = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) => state.competences
);

export const isLoadingCreateDocumentCompetence = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) =>
    state.actionStatuses[createDocumentCompetence.type]?.status ===
    RequestStatus.PENDING
);

export const selectCreateDocumentCompetenceStatus = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) => state.actionStatuses[createDocumentCompetence.type]?.status
);

export const selectCreateDocumentCompetenceError = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) => state.actionStatuses[createDocumentCompetence.type]?.error
);

export const isLoadingUpdateDocumentCompetence = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) =>
    state.actionStatuses[updateDocumentCompetence.type]?.status ===
    RequestStatus.PENDING
);

export const selectUpdateDocumentCompetenceStatus = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) => state.actionStatuses[updateDocumentCompetence.type]?.status
);

export const selectUpdateDocumentCompetenceError = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) => state.actionStatuses[updateDocumentCompetence.type]?.error
);

export const isLoadingDeleteDocumentCompetence = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) =>
    state.actionStatuses[deleteDocumentCompetence.type]?.status ===
    RequestStatus.PENDING
);

export const selectDeleteDocumentCompetenceStatus = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) => state.actionStatuses[deleteDocumentCompetence.type]?.status
);

export const selectDeleteDocumentCompetenceError = createSelector(
  selectCurrentDocumentCompetencesState,
  (state) => state.actionStatuses[deleteDocumentCompetence.type]?.error
);

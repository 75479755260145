import { useQuery } from "@tanstack/react-query";

import { ActivityLogResourceType } from "../../models/Logs/ActivityLog";
import { getActivityLogs } from "../../services/ActivityService";

export const useActivityLogs = (
  formUUID: string,
  resourceType?: ActivityLogResourceType,
  resourceId?: string
) => {
  const additionalKeys =
    resourceType && resourceId ? [resourceType, resourceId] : [];

  return useQuery({
    queryKey: ["activities", formUUID, ...additionalKeys],
    queryFn: ({ signal }) =>
      getActivityLogs(signal, formUUID, resourceType, resourceId),
  });
};

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { PencilSquareIcon } from "@heroicons/react/20/solid";

import DefaultButton from "../../../../components/buttons/DefaultButton";
import { BaseValue } from "../../../../models/Form/BaseValue";
import { formatISODateStr } from "../../../../utils/dates";

type OwnProps = {
  value: BaseValue;
  onEdit?: () => void;
};

const ValueHeader: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { value, onEdit } = props;
  const ownerName = `${value.createdBy.name.first} ${value.createdBy.name.last}`;

  return (
    <>
      <div className="flex items-center space-x-5">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            {value.title ?? value.description.slice(0, 50)}
          </h1>
          <p className="mt-2 text-sm text-gray-500">
            {t("common.actions.createdBy")}
            <span className="ml-1 font-medium text-gray-900">
              {ownerName}
            </span>{" "}
            {t("common.datetime.on")}
            <time
              className="ml-1 font-medium text-gray-900"
              dateTime={value.createdAt}
            >
              {formatISODateStr(value.createdAt)}
            </time>
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {onEdit && (
          <DefaultButton
            label={t("values.actions.edit")}
            onClick={onEdit}
            icon={PencilSquareIcon}
          />
        )}
      </div>
    </>
  );
};

export default ValueHeader;

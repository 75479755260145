import Axios, { CancelTokenSource } from "axios";

import { call, put, takeLatest } from "@redux-saga/core/effects";

import { BaseDevelopmentAction } from "../../../models/Form/BaseDevelopmentAction";
import {
  createNewDevAction, deleteDevAction, updateDevAction
} from "../../../services/DevActionService";
import {
  createDocumentDevAction, createDocumentDevActionError, createDocumentDevActionSuccess,
  deleteDocumentDevAction, deleteDocumentDevActionError, deleteDocumentDevActionSuccess,
  updateDocumentDevAction, updateDocumentDevActionError, updateDocumentDevActionSuccess
} from "./slice";

function* createDocumentDevActionSaga(
  action: ReturnType<typeof createDocumentDevAction>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, data } = action.payload;

  try {
    const response: BaseDevelopmentAction = yield call(
      createNewDevAction,
      signal.token,
      formUUID,
      data
    );

    yield put(createDocumentDevActionSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      createDocumentDevActionError({
        error: message,
        payload: message
      })
    );
  }
}

function* updateDocumentDevActionSaga(
  action: ReturnType<typeof updateDocumentDevAction>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, devActionId, data } = action.payload;

  try {
    const response: BaseDevelopmentAction = yield call(
      updateDevAction,
      signal.token,
      formUUID,
      devActionId,
      data
    );

    yield put(updateDocumentDevActionSuccess({ data: response }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      updateDocumentDevActionError({
        error: message,
        payload: message
      })
    );
  }
}

function* deleteDocumentDevActionSaga(
  action: ReturnType<typeof deleteDocumentDevAction>
) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { formUUID, devActionId } = action.payload;

  try {
    yield call(deleteDevAction, signal.token, formUUID, devActionId);

    yield put(deleteDocumentDevActionSuccess({ devActionId }));
  } catch (error: any) {
    const { message } = error.response.data;
    yield put(
      deleteDocumentDevActionError({
        error: message,
        payload: message
      })
    );
  }
}

export default function* currentDocumentDevActionSaga() {
  yield takeLatest(createDocumentDevAction.type, createDocumentDevActionSaga);
  yield takeLatest(updateDocumentDevAction.type, updateDocumentDevActionSaga);
  yield takeLatest(deleteDocumentDevAction.type, deleteDocumentDevActionSaga);
}

import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  CreateMeetingPayload,
  updateMeeting
} from "../../services/MeetingService";

export const useEditMeeting = (formUUID: string, meetingId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    // mutationKey: ["notes", formUUID, resourceType, resourceId, noteId],
    mutationFn: (payload: CreateMeetingPayload) =>
      updateMeeting(formUUID, meetingId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["meetings", formUUID]
      });
    }
  });
};

import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { Radio, RadioGroup } from "@headlessui/react";
import {
  AcademicCapIcon,
  BuildingOfficeIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  Square3Stack3DIcon,
  UserGroupIcon
} from "@heroicons/react/24/outline";

import { useAddCourseToDevAction } from "../../../hooks/integrations/riseUp/useAddCourseToDevAction";
import { useCourse } from "../../../hooks/integrations/riseUp/useCourses";
import { RiseUpCourse } from "../../../models/integrations/RiseUp/RiseUpCourse";
import { RiseUpCourseSession } from "../../../models/integrations/RiseUp/RiseUpCourseSession";
import { NotificationType } from "../../../models/Notification";
import { showNotification } from "../../../redux/currentApp/slice";
import { addDocumentDevActionCourse } from "../../../redux/currentDocument/devActions/slice";
import { formatISODateStr } from "../../../utils/dates";
import Spinner from "../../loaders/Spinner";
import Modal from "../../modal/Modal";
import SafeHTMLText from "../../texts/SafeHTMLText";
import { classNames } from "../../../utils/styles";
import { t } from "i18next";

const mapDispatchToProps = {
  linkCourse: addDocumentDevActionCourse,
  notify: showNotification
};

type OwnProps = {
  documentId: string;
  devActionId: string;
  course: RiseUpCourse;
  isManager: boolean;
  closeModal: () => void;
};

type Props = OwnProps & ReduxProps;

const CourseDetailModal: FC<Props> = (props): ReactElement => {
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    course,
    documentId,
    devActionId,
    isManager,
    closeModal,
    linkCourse,
    notify
  } = props;

  const [selectedSession, setSelectedSession] =
    useState<RiseUpCourseSession | null>(null);

  const mutationLinkCourse = useAddCourseToDevAction(documentId, devActionId);

  // Load course details
  const { isLoading, data: courseDetails } = useCourse(course._id);

  const onLinkCourse = (course: RiseUpCourse) => {
    mutationLinkCourse.mutate(course._id);
    linkCourse({ devActionId: devActionId, course });

    notify({
      type: NotificationType.SUCCESS,
      title: tCommon("notifications.saveSuccess"),
      message: tCommon("notifications.courseLinked")
    });

    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
        <div className="sm:col-span-4 lg:col-span-5">
          <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
            <img
              alt={course.img}
              src={course.img}
              className="object-cover object-center"
            />
          </div>
          <p className="absolute left-4 top-4 text-center sm:static sm:mt-6">
            <a
              href={`https://partena-professional.riseup.ai/Catalog/training/${course.courseId}`}
              className="font-medium text-indigo-600 hover:text-indigo-500"
              target="_blank"
              rel="noreferrer noopener"
            >
              {tCommon("riseUp.viewOnRiseUp")}
            </a>
          </p>
        </div>
        <div className="sm:col-span-8 lg:col-span-7">
          <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">
            {course.title}
          </h2>

          <section aria-labelledby="information-heading" className="mt-4">
            <div className="flex items-center">
              <p className="mr-2 text-center">
                <AcademicCapIcon className="h-5 w-5 inline-block -mt-0.5 mr-1" />
                {course.trainingtype}
              </p>
              <p className="mr-2 text-center">
                <ClockIcon className="h-5 w-5 inline-block -mt-0.5 mr-1" />
                {course.eduduration} mins
              </p>
              <p className="mr-2 text-center">
                <BuildingOfficeIcon className="h-5 w-5 inline-block -mt-0.5 mr-1" />
                {course.type}
              </p>
            </div>
            <div className="flex items-center mt-3">
              <p className="truncate mr-2 text-indigo-600 text-sm">
                <Square3Stack3DIcon className="h-5 w-5 inline-block -mt-0.5 mr-1" />
                {courseDetails?.modules?.length} modules available
              </p>
            </div>
            <div className="mt-6 flex text-gray-600">
              <SafeHTMLText html={course?.description ?? ""} />
            </div>
            <div className="mt-6 flex text-indigo-600 text-italic text-sm">
              <SafeHTMLText html={course.objective} />
            </div>
          </section>

          <section aria-labelledby="options-heading" className="mt-6">
            <form>
              <div className="sm:flex sm:justify-between">
                <fieldset>
                  <legend className="block text-sm font-medium text-gray-700">
                    Next sessions
                  </legend>
                  {isLoading ? (
                    <Spinner size="small" />
                  ) : courseDetails != null ? (
                    <RadioGroup
                      value={selectedSession}
                      onChange={setSelectedSession}
                    >
                      <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {courseDetails.sessions?.map((session) => (
                          <Radio
                            key={session.sessionId}
                            as="div"
                            value={session}
                            aria-label={`${session.sessionId}`}
                            className="group relative block cursor-pointer rounded-lg border border-gray-300 p-4 focus:outline-none data-[focus]:ring-2 data-[focus]:ring-indigo-500"
                          >
                            <p className="text-base text-gray-900">
                              {formatISODateStr(session.startdate)} &gt;{" "}
                              {formatISODateStr(session.enddate)}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {session.description}
                            </p>
                            <div className="flex items-center">
                              <p className="mr-2 text-xs text-center">
                                <BuildingOfficeIcon className="h-3 w-3 inline-block -mt-0.5 mr-1" />
                                {session.session_type}
                              </p>
                              <p className="mr-2 text-xs text-center">
                                <UserGroupIcon className="h-3 w-3 inline-block -mt-0.5 mr-1" />
                                {session.seats}
                              </p>
                              <p className="mr-2 text-xs text-center">
                                <ClockIcon className="h-3 w-3 inline-block -mt-0.5 mr-1" />
                                {session.eduduration}
                              </p>
                            </div>
                            <div
                              aria-hidden="true"
                              className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-500"
                            />
                          </Radio>
                        ))}
                      </div>
                    </RadioGroup>
                  ) : null}
                </fieldset>
              </div>
              <div className="mt-4 flex">
                <a
                  href="#"
                  className="group flex text-sm text-gray-500 hover:text-gray-700"
                >
                  <span>Not found your next training in the catalog?</span>
                  <QuestionMarkCircleIcon
                    aria-hidden="true"
                    className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  />
                </a>
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  className={classNames(
                    "flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50",
                    isLoading || selectedSession == null
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  )}
                  disabled={isLoading || selectedSession == null}
                  onClick={() => {
                    if (selectedSession != null) {
                      onLinkCourse(course);

                      // Open new tab to register on Rise Up
                      window.open(
                        `https://partena-professional.riseup.ai/Catalog/training/view/${course.courseId}/session/${selectedSession?.sessionId}`,
                        "_blank"
                      );
                    }
                  }}
                >
                  {isManager
                    ? tCommon("riseUp.suggestSession")
                    : tCommon("riseUp.registerOnRiseUp")}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </Modal>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(CourseDetailModal);

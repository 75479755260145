import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { useUserCoachRequests } from "../../hooks/coachRequests/useCoachRequests";
import { Employee } from "../../models/Employee/Employee";
import { Cycle } from "../../models/Form/Cycle";
import Spinner from "../loaders/Spinner";
import CoachRequestItem from "./CoachRequestItem";

type OwnProps = {
  employee: Employee;
  cycle: Cycle;
};

const UserCoachRequestsList: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const { employee, cycle } = props;

  const { isLoading: isLoadingRequests, data: coachRequests = [] } =
    useUserCoachRequests(employee._id, cycle._id);

  return (
    <>
      {isLoadingRequests ? (
        <div className="text-center m-10">
          <Spinner />
        </div>
      ) : coachRequests?.length ? (
        <ul className="divide-y divide-gray-100">
          {coachRequests.map((request) => (
            <CoachRequestItem key={request._id} request={request} />
          ))}
        </ul>
      ) : (
        <div>{t("coachRequests.emptyList")}</div>
      )}
    </>
  );
};

export default UserCoachRequestsList;

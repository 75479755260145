import "react-day-picker/dist/style.css";

import { FC, ReactElement, useState } from "react";
import { DayPicker } from "react-day-picker";

const css = `
  .sdp-selected:not([disabled]) { 
    background-color: #4f46e5;
    font-weight: bold;
    color: #ffffff;
  }
  .sdp-selected:hover:not([disabled]) { 
    color: #4f46e5;
  }
`;

type OwnProps = {
  defaultDate?: Date;
  footer?: ReactElement;
  onSelectDate: (date: Date) => void;
};

const SingleDatePicker: FC<OwnProps> = (props): ReactElement => {
  const { defaultDate, onSelectDate, footer } = props;
  const [selected, setSelected] = useState<Date>(defaultDate || new Date());

  const handleSelectDate = (day?: Date): void => {
    if (!day) return;
    setSelected(day);
    onSelectDate(day);
  };

  return (
    <>
      <style>{css}</style>
      <DayPicker
        mode="single"
        selected={selected}
        onSelect={handleSelectDate}
        footer={footer}
        modifiersClassNames={{
          selected: "sdp-selected"
        }}
      />
    </>
  );
};

export default SingleDatePicker;

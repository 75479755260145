import { PayloadAction } from "@reduxjs/toolkit";

export type ErrorPayload<T, P = unknown> = {
  error: T;
  payload?: P;
};

export type ErrorAction<T = Error, P = unknown> = PayloadAction<
  ErrorPayload<T, P>
>;

export enum ActionMode {
  READ_ONLY = "read-only",
  EDIT = "edit"
}

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  ActivityLogActionType,
  ActivityLogResourceType
} from "../../models/Logs/ActivityLog";
import { formatISODateStr } from "../../utils/dates";
import TextDiff from "../texts/TextDiff";

const textKeys = ["title", "description", "comments"];

type OwnProps = {
  resourceType: ActivityLogResourceType;
  actionType: ActivityLogActionType;
  changes: any;
};

const ActivityLogDiffs: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "activities"
  });
  const { changes, resourceType, actionType } = props;
  const keys = Object.keys(changes).filter(
    (k) => !k.startsWith("participants.")
  );

  const formatValue = (key: string, value: string) => {
    if (key != null && key === "lastDiscussionDate" && value != null) {
      return formatISODateStr(value, "MMMM do, yyyy h:mm a");
    }

    return `${value}` ?? "";
  };

  const getLabel = (key: string) => {
    return t(`properties.${key}`) ?? key;
  };

  return (
    <div className="my-2">
      <ul>
        {keys.map((key) => (
          <li
            className="text-xs text-gray-500"
            key={`${resourceType}-${actionType}-${key}`}
          >
            <strong>{getLabel(key)}</strong>:{" "}
            {textKeys.includes(key) ? (
              <TextDiff
                oldValue={formatValue(key, changes[key].from)}
                newValue={formatValue(key, changes[key].to)}
              />
            ) : (
              <>
                <span className="line-through">
                  {formatValue(key, changes[key].from)}
                </span>
                {" > "}
                <strong>{formatValue(key, changes[key].to)}</strong>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityLogDiffs;

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  AcademicCapIcon,
  CalendarDaysIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckCircleIcon,
  CheckIcon,
  CubeTransparentIcon,
  DocumentTextIcon,
  FireIcon,
  MegaphoneIcon,
  PencilIcon,
  PlusIcon,
  PuzzlePieceIcon,
  RocketLaunchIcon,
  StarIcon,
  UsersIcon,
  XCircleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";

import {
  ActivityLog,
  ActivityLogActionType,
  ActivityLogResourceType
} from "../../models/Logs/ActivityLog";
import { formatISODateStrFromNow } from "../../utils/dates";
import { classNames } from "../../utils/styles";
import ActivityLogDiffs from "./ActivityLogDiffs";

type EventType = {
  icon: any;
  bgColorClass: string;
};

const eventTypes: Record<ActivityLogResourceType, EventType> = {
  [ActivityLogResourceType.OBJECTIVE]: {
    icon: RocketLaunchIcon,
    bgColorClass: "bg-teal-500"
  },
  [ActivityLogResourceType.COMPETENCE]: {
    icon: PuzzlePieceIcon,
    bgColorClass: "bg-orange-400"
  },
  [ActivityLogResourceType.DEV_ACTION]: {
    icon: CubeTransparentIcon,
    bgColorClass: "bg-violet-500"
  },
  [ActivityLogResourceType.VALUE]: {
    icon: FireIcon,
    bgColorClass: "bg-rose-500"
  },
  [ActivityLogResourceType.FORM]: {
    icon: DocumentTextIcon,
    bgColorClass: "bg-slate-500"
  },
  [ActivityLogResourceType.MEETING]: {
    icon: CalendarDaysIcon,
    bgColorClass: "bg-sky-400"
  }
};

type actionType = {
  icon: any;
};

const actionsIcons: Record<ActivityLogActionType, actionType> = {
  [ActivityLogActionType.CREATE]: { icon: PlusIcon },
  [ActivityLogActionType.UPDATE]: { icon: PencilIcon },
  [ActivityLogActionType.DELETE]: { icon: XMarkIcon },
  [ActivityLogActionType.SET_AS_COMPLETED]: { icon: CheckIcon },
  [ActivityLogActionType.COMMENT]: { icon: ChatBubbleBottomCenterTextIcon },
  [ActivityLogActionType.COMMENT_DELETED]: { icon: XMarkIcon },
  [ActivityLogActionType.ADD_COACH]: { icon: UsersIcon },
  [ActivityLogActionType.REMOVE_COACH]: { icon: UsersIcon },
  [ActivityLogActionType.ACCEPT_REQUEST]: { icon: CheckCircleIcon },
  [ActivityLogActionType.REJECT_REQUEST]: { icon: XCircleIcon },
  [ActivityLogActionType.NOTIFY]: { icon: MegaphoneIcon },
  [ActivityLogActionType.SET_DISCUSSION_DATE]: { icon: CalendarDaysIcon },
  [ActivityLogActionType.SCORE]: { icon: StarIcon },
  [ActivityLogActionType.ADD_COURSE]: { icon: AcademicCapIcon },
  [ActivityLogActionType.REMOVE_COURSE]: { icon: AcademicCapIcon },
  [ActivityLogActionType.ADD_COURSE_REGISTRATION]: { icon: AcademicCapIcon },
  [ActivityLogActionType.REMOVE_COURSE_REGISTRATION]: { icon: AcademicCapIcon }
};

const diffResourceTypes = [
  ActivityLogResourceType.OBJECTIVE,
  ActivityLogResourceType.COMPETENCE,
  ActivityLogResourceType.DEV_ACTION,
  ActivityLogResourceType.VALUE,
  ActivityLogResourceType.FORM,
  ActivityLogResourceType.MEETING
];

const diffActionTypes = [
  ActivityLogActionType.UPDATE,
  // ActivityLogActionType.COMMENT,
  ActivityLogActionType.SET_DISCUSSION_DATE
];

type OwnProps = {
  activity: ActivityLog;
  isLast: boolean;
};

const ActivityLogItem: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", { keyPrefix: "activities" });
  const { activity, isLast } = props;
  const { actionType, resourceType, relatedEmployee } = activity;

  const eventType: EventType = eventTypes[resourceType];
  const actionTypeIcon: actionType = actionsIcons[actionType];
  const content = t(`actions.${actionType}`, {
    element: t(`types.${resourceType}`)
  });
  const target = `${relatedEmployee.name.first} ${relatedEmployee.name.last}`;
  const showDiff =
    diffResourceTypes.includes(resourceType) &&
    diffActionTypes.includes(actionType);

  return (
    <li key={activity._id}>
      <div className="relative pb-8">
        {!isLast ? (
          <span
            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <div>
            <span
              className={classNames(
                eventType.bgColorClass,
                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
              )}
            >
              {resourceType === ActivityLogResourceType.MEETING ? (
                <eventType.icon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              ) : actionTypeIcon != null ? (
                <actionTypeIcon.icon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              ) : (
                <eventType.icon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              )}
            </span>
          </div>
          <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
            <div>
              <p className="text-sm text-gray-500">
                {content}{" "}
                <span className="font-medium text-gray-900">{target}</span>
              </p>
              {showDiff && activity?.changes != null && (
                <ActivityLogDiffs
                  actionType={actionType}
                  resourceType={resourceType}
                  changes={activity.changes}
                />
              )}
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <time dateTime={activity.createdAt}>
                {formatISODateStrFromNow(activity.createdAt)}
              </time>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ActivityLogItem;

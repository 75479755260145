import { FC, Fragment, ReactElement } from "react";

import { Employee } from "../../models/Employee/Employee";
import { getAvatarUrl } from "../../utils/user";

type OwnProps = {
  users: Partial<Employee>[];
  size?: number;
};

export const UsersList: FC<OwnProps> = (props): ReactElement => {
  const { users, size = 6 } = props;

  return (
    <div className="isolate flex -space-x-2 overflow-hidden">
      {users.map((user, index) => {
        const { _id, name, holaMember } = user;
        const fullName = `${name!.first} ${name!.last}`;
        const initials = `${name!.first[0]}${name!.last[0]}`;
        const pictureUrl = getAvatarUrl(holaMember);
        const ind = (users.length - index) * 10;

        return (
          <Fragment key={_id}>
            {pictureUrl != null ? (
              <img
                className={`relative z-${ind} inline-block h-${size} w-${size} rounded-full bg-gray-50 ring-2 ring-white`}
                src={pictureUrl}
                alt={fullName}
              />
            ) : (
              <div
                className={`relative z-${ind} inline-block h-${size} w-${size} rounded-full bg-gray-50 ring-2 ring-white flex items-center justify-center`}
              >
                <span
                  className="text-sm font-medium leading-5 text-gray-500"
                  title={fullName}
                >
                  {initials}
                </span>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default UsersList;

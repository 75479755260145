import { FC, ReactElement } from "react";
import { Tooltip } from "react-tooltip";

import { classNames } from "../../utils/styles";

type OwnProps = {
  value: number;
  color: string;
  className?: string;
  tooltip?: string;
  isHTMLTooltip?: boolean;
};

const NumericBadge: FC<OwnProps> = (props): ReactElement => {
  const { value, color, className, tooltip, isHTMLTooltip = false } = props;
  const id = `numeric-badge-${Math.random().toString(36).substring(7)}`;

  const tooltipProps = {
    "data-tooltip-id": id,
    ...(isHTMLTooltip
      ? { "data-tooltip-html": tooltip }
      : { "data-tooltip-content": tooltip })
  };

  return (
    <>
      <span
        {...tooltipProps}
        className={classNames(
          "inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ring-1 ring-inset",
          `bg-${color}-50 text-${color}-600 ring-${color}-500/10`,
          className ?? ""
        )}
      >
        {value}
      </span>
      {tooltip && <Tooltip id={id} />}
    </>
  );
};

export default NumericBadge;

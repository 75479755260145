import DiffMatchPatch from "diff-match-patch";
import { FC, ReactElement } from "react";

import { sanitize } from "../../utils/strings";

type OwnProps = {
  newValue: string;
  oldValue: string;
};

const TextDiff: FC<OwnProps> = (props): ReactElement => {
  const { newValue = "", oldValue = "" } = props;

  const dmp = new DiffMatchPatch();
  const diff = dmp.diff_main(oldValue, newValue);
  dmp.diff_cleanupSemantic(diff);
  const ds = dmp.diff_prettyHtml(diff);

  return (
    <div className="mt20">
      <p dangerouslySetInnerHTML={{ __html: sanitize(ds) }} />
    </div>
  );
};

export default TextDiff;

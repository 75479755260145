import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type OwnProps = {
  label: string;
  disabled?: boolean;
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  customClass?: string;
  onClick: () => void;
};

const DefaultButton: FC<OwnProps> = (props): ReactElement => {
  const { disabled = false, label, icon: Icon, customClass, onClick } = props;

  return (
    <button
      type="button"
      className={classNames(
        "inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
        disabled ? "cursor-not-allowed opacity-50" : "",
        customClass ?? ""
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && <Icon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />}
      {label}
    </button>
  );
};

export default DefaultButton;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { notifyForm } from "../../services/FormService";

export const useNotifyStakeholders = (formUUID: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => notifyForm(formUUID),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["activities", formUUID]
      });
    }
  });
};

import { FC, ReactElement } from "react";

import { PlusSmallIcon } from "@heroicons/react/20/solid";

import { classNames } from "../../utils/styles";

type OwnProps = {
  title: string;
  icon?: ReactElement;
  link?: string;
  linkLabel?: string;
  actionLabel?: string;
  actionIcon?: ReactElement;
  onActionClick?: () => void;
};

const SubSectionHeader: FC<OwnProps> = (props): ReactElement => {
  const {
    title,
    icon,
    link,
    linkLabel,
    actionLabel,
    actionIcon,
    onActionClick
  } = props;

  return (
    <div className="flex flex-wrap items-center gap-6 sm:flex-nowrap mb-5">
      <div className="flex items-center space-between font-semibold leading-7 text-gray-900">
        {icon}
        <span className="ml-2">{title}</span>
      </div>
      {link && (
        <div className="order-last flex w-full gap-x-8 text-sm leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
          <a
            href={link}
            className="text-indigo-600"
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkLabel}
          </a>
        </div>
      )}
      {actionLabel && (
        <button
          type="button"
          className={classNames(
            "ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
            !onActionClick ? "cursor-not-allowed opacity-50" : ""
          )}
          onClick={onActionClick}
          disabled={!onActionClick}
        >
          {actionIcon ?? (
            <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
          )}
          {actionLabel}
        </button>
      )}
    </div>
  );
};

export default SubSectionHeader;

import { FormMeeting, MeetingType } from "../models/Form/FormMeeting";
import API from "../utils/api";

export const getFormMeetings = async (
  signal: AbortSignal,
  formUUID: string
): Promise<FormMeeting[]> => {
  const url = `/form/${formUUID}/meetings`;

  try {
    const response = await API.get<{ data: FormMeeting[] }>(url, {
      signal
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export type CreateMeetingPayload = {
  meetingType: MeetingType;
  meetingDate: Date;
  participants: string[];
  body: string;
};

export const createNewMeeting = async (
  // signal: AbortSignal,
  formUUID: string,
  payload: CreateMeetingPayload
): Promise<FormMeeting> => {
  const url = `/form/${formUUID}/meetings`;

  try {
    const response = await API.post<{ data: FormMeeting }>(url, payload);

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const updateMeeting = async (
  formUUID: string,
  meetingId: string,
  payload: { body: string }
): Promise<FormMeeting> => {
  const url = `/form/${formUUID}/meetings/${meetingId}`;

  try {
    const response = await API.put<{ data: FormMeeting }>(url, payload);

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const deleteMeeting = async (
  formUUID: string,
  meetingId: string
): Promise<void> => {
  const url = `/form/${formUUID}/meetings/${meetingId}`;

  try {
    await API.delete(url);
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

import { debounce } from "lodash";
import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import { Combobox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";

import { Employee } from "../../models/Employee/Employee";
import { getFullName } from "../../utils/strings";
import { classNames } from "../../utils/styles";

type OwnProps = {
  employeeOptions: Employee[];
  selectedEmployee?: Employee;
  defaultLabel: string;
  onSelectEmployee: (employee: Employee) => void;
};

const EmployeeSelector: FC<OwnProps> = (props): ReactElement => {
  const { employeeOptions, selectedEmployee, defaultLabel, onSelectEmployee } =
    props;

  const [selected, setSelected] = useState<Employee | null>(
    selectedEmployee ?? null
  );
  const [isFocused, setIsFocused] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  // Debounce function to delay the update of the debounced value
  const debounceInputChange = debounce((value) => {
    setDebouncedValue(value);
  }, 300);

  // Update debounced value when inputValue changes
  useEffect(() => {
    debounceInputChange(query);

    // Cleanup the debounce on component unmount
    return () => {
      debounceInputChange.cancel();
    };
  }, [query, debounceInputChange]);

  const filteredEmployee: Employee[] = useMemo(() => {
    return debouncedValue === ""
      ? employeeOptions.slice(0, 10)
      : employeeOptions.filter((employee) => {
          const fullname = getFullName(employee.name).toLowerCase();
          return fullname.includes(debouncedValue.toLowerCase());
        });
  }, [debouncedValue, employeeOptions]);

  const onChangeHandler = (selection: Employee) => {
    setSelected(selection);
    onSelectEmployee(selection);
  };

  return (
    <Combobox as="div" value={selected} onChange={onChangeHandler}>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(employee: Employee) =>
            selected != null
              ? getFullName(employee.name)
              : !isFocused
                ? defaultLabel
                : ""
          }
          onFocus={() => setIsFocused(true)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredEmployee.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredEmployee.map((employee) => (
              <Combobox.Option
                key={employee._id}
                value={employee}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected ? "font-semibold" : ""
                      )}
                    >
                      {getFullName(employee.name)}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default EmployeeSelector;

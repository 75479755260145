import { FormNote } from "../models/Form/FormNote";
import API from "../utils/api";

export const getResourceNotes = async (
  signal: AbortSignal,
  formUUID: string,
  resourceType: string,
  resourceId: string
): Promise<FormNote[]> => {
  const url = `/form/${formUUID}/notes/${resourceType}/${resourceId}`;

  try {
    const response = await API.get<{ data: FormNote[] }>(url, {
      signal,
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const createNewResourceNote = async (
  // signal: AbortSignal,
  formUUID: string,
  resourceType: string,
  resourceId: string,
  payload: { body: string }
): Promise<FormNote> => {
  const url = `/form/${formUUID}/notes/${resourceType}/${resourceId}`;

  try {
    const response = await API.post<{ data: FormNote }>(url, payload);

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const updateResourceNote = async (
  formUUID: string,
  resourceType: string,
  resourceId: string,
  noteId: string,
  payload: { body: string }
): Promise<FormNote> => {
  const url = `/form/${formUUID}/notes/${resourceType}/${resourceId}/${noteId}`;

  try {
    const response = await API.put<{ data: FormNote }>(url, payload);

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const deleteResourceNote = async (
  formUUID: string,
  resourceType: string,
  resourceId: string,
  noteId: string
): Promise<void> => {
  const url = `/form/${formUUID}/notes/${resourceType}/${resourceId}/${noteId}`;

  try {
    await API.delete(url);
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";

import {
  CheckCircleIcon,
  EyeIcon,
  UsersIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";

import { useAnswerCoachRequest } from "../../hooks/coachRequests/useAnswerCoachRequest";
import {
  CoachRequest,
  CoachRequestStatus
} from "../../models/Form/CoachRequest";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import { baseUrl } from "../../routes";
import { formatISODateStr } from "../../utils/dates";
import { getAvatarUrl } from "../../utils/user";
import UserAvatar from "../users/UserAvatar";
import CoachRequestStatusBadge from "./CoachRequestStatusBadge";

const mapDispatchToProps = {
  notify: showNotification
};

type OwnProps = {
  request: CoachRequest;
};

type Props = OwnProps & ReduxProps;

const CoachRequestItem: FC<Props> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "coachRequests"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { request, notify } = props;
  const [requestStatus, setRequestStatus] = useState(request.status);
  const mutation = useAnswerCoachRequest(request.requestUUID);

  useEffect(() => {
    if (mutation.isPending) return;

    if (mutation.isError) {
      notify({
        type: NotificationType.ERROR,
        title: tCommon("notifications.requestError"),
        message: mutation.error.message
      });
    }

    if (mutation.isSuccess) {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.requestAnswered"),
        message:
          requestStatus === CoachRequestStatus.ACCEPTED
            ? t("messages.acceptSuccess")
            : t("messages.rejectSuccess")
      });
    }
  }, [
    mutation.isPending,
    mutation.isSuccess,
    mutation.isError,
    mutation.error,
    notify,
    requestStatus,
    t,
    tCommon
  ]);

  const handleStatusUpdate = (status: CoachRequestStatus) => {
    setRequestStatus(status);
    mutation.mutate(status);
  };

  return (
    <li
      key={request.requestUUID}
      className="flex flex-wrap items-center justify-between gap-x-6 py-5 rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6 mb-3"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <UsersIcon className="h-6 w-6 text-slate-500" />
          <p className="text-sm font-semibold leading-6 text-gray-900 uppercase">
            {request.relatedForm.relatedPhase === "target"
              ? "target setting"
              : "review"}
          </p>
          <CoachRequestStatusBadge status={request.status} />
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p className="whitespace-nowrap">
            {tCommon("datetime.asked")}{" "}
            <time dateTime={request.createdAt}>
              {formatISODateStr(request.createdAt)}
            </time>
          </p>
          {request.answeredAt != null && (
            <>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="whitespace-nowrap">
                {tCommon("datetime.answered")}{" "}
                <time dateTime={request.answeredAt}>
                  {formatISODateStr(request.answeredAt)}
                </time>
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <dt>{t("requestedBy")}</dt>
        <dd key={request.relatedEmployee._id}>
          <UserAvatar
            name={request.relatedEmployee.name}
            pictureUrl={getAvatarUrl(request.relatedEmployee.holaMember)}
            showName
          />
        </dd>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        {request.status === CoachRequestStatus.PENDING && (
          <>
            <button
              type="button"
              className="px-2.5 py-1.5 text-sm font-semibold text-green-500 flex items-center gap-x-1.5 hover:text-green"
              onClick={() => handleStatusUpdate(CoachRequestStatus.ACCEPTED)}
            >
              <CheckCircleIcon className="h-6 w-6" />
              {t("actions.accept")}
            </button>
            <button
              type="button"
              className="px-2.5 py-1.5 text-sm font-semibold text-red-500 flex items-center gap-x-1.5 hover:text-red"
              onClick={() => handleStatusUpdate(CoachRequestStatus.REJECTED)}
            >
              <XCircleIcon className="h-6 w-6" />
              {t("actions.reject")}
            </button>
          </>
        )}
        {request.status === CoachRequestStatus.ACCEPTED && (
          <Link
            to={`${baseUrl}/performance/${request.relatedForm.formUUID}`}
            className="hidden px-2.5 py-1.5 text-sm font-semibold text-gray-900 sm:block"
          >
            <EyeIcon className="h-6 w-6 hover:text-indigo-600" />
          </Link>
        )}
      </div>
    </li>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(CoachRequestItem);

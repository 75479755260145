import { FC, ReactElement } from "react";

import { XCircleIcon } from "@heroicons/react/24/outline";

type OwnProps = {
  message: string;
  errors?: string[];
};

const ErrorBanner: FC<OwnProps> = (props): ReactElement => {
  const { message, errors } = props;

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{message}</h3>
          {errors && (
            <div className="mt-2 text-sm text-red-700">
              <ul className="list-disc space-y-1 pl-5">
                {errors.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorBanner;

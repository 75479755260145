import { FC, ReactElement } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectNotificationConfig } from "../../redux/currentApp/selectors";
import { closeNotification } from "../../redux/currentApp/slice";
import Notification from "./Notification";

const mapStateToProps = createStructuredSelector({
  notification: selectNotificationConfig,
});

const mapDispatchToProps = {
  closeNotification,
};

type NotificationContainerProps = ReduxProps;

const NotificationContainer: FC<NotificationContainerProps> = (
  props: NotificationContainerProps
): ReactElement => {
  const { notification, closeNotification } = props;

  if (notification) {
    const notificationProps = {
      ...notification,
      close: closeNotification,
    };
    return <Notification {...notificationProps} />;
  }

  return <></>;
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(NotificationContainer);

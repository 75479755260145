import DOMPurify from "dompurify";
import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type OwnProps = {
  html: string;
  className?: string;
};

const SafeHTMLText: FC<OwnProps> = (props): ReactElement => {
  const { html, className } = props;
  const safeHTML = DOMPurify.sanitize(html);

  return (
    <div
      className={classNames("escaped-content", className ?? "")}
      dangerouslySetInnerHTML={{ __html: safeHTML }}
    ></div>
  );
};

export default SafeHTMLText;

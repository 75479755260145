import { FC, ReactElement } from "react";

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";

import { DocumentMetric } from "../../models/Form/DocumentMetrics";
import { classNames } from "../../utils/styles";

type OwnProps = {
  id: string;
  icon: any;
  label: string;
  metric: DocumentMetric;
  color?: string;
};

const DocumentListMetric: FC<OwnProps> = (props): ReactElement => {
  const { id, icon: Icon, label, metric, color } = props;
  const { current, comparator } = metric;
  const comparison = comparator != null ? current - comparator : null;

  return (
    <div
      key={id}
      className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
    >
      <dt>
        <div
          className={classNames(
            "absolute rounded-md p-3",
            color
              ? color === "orange"
                ? "bg-orange-400"
                : `bg-${color}-500`
              : "bg-indigo-500"
          )}
        >
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          {label}
        </p>
      </dt>
      <dd className="ml-16 flex items-baseline justify-between lg:flex sm:pb-6">
        {/* // mt-1 flex items-baseline justify-between md:block lg:flex */}
        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
          <p className="text-2xl font-semibold text-gray-900">
            {metric.current}
          </p>
          {comparator != null && (
            <span className="ml-2 text-sm font-medium text-gray-500">
              from {metric.comparator}
            </span>
          )}
        </div>
        {comparison != null && (
          <div
            className={classNames(
              comparison >= 0
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800",
              "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
            )}
          >
            {comparison >= 0 ? (
              <ArrowUpIcon
                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                aria-hidden="true"
              />
            ) : (
              <ArrowDownIcon
                className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                aria-hidden="true"
              />
            )}

            <span className="sr-only">
              {" "}
              {comparison >= 0 ? "Increased" : "Decreased"} by{" "}
            </span>
            {comparison}
          </div>
        )}
      </dd>
    </div>
  );
};

export default DocumentListMetric;

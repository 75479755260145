import { useQuery } from "@tanstack/react-query";

import { getMemberOKRs } from "../../../services/HolaService";

export const useMemberOKRs = (memberId: string) => {
  return useQuery({
    queryKey: ["okrs", memberId],
    queryFn: ({ signal }) => getMemberOKRs(signal, memberId),
  });
};

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

const TopBannerNew: FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <nav aria-label="Top">
      {/* Top navigation */}
      <div className="bg-indigo-600">
        <div className="mx-auto flex h-10 max-w-7xl items-center justify-center px-4 sm:px-6 lg:px-8">
          {/* Currency selector */}
          <p className="flex-1 text-center text-sm font-medium text-white lg:flex-none">
            <span
              dangerouslySetInnerHTML={{
                __html: t("common.topBanner.title", { link: "/app/" })
              }}
            />
          </p>
        </div>
      </div>
    </nav>
  );
};

export default TopBannerNew;

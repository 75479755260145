import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { CoachRequestStatus } from "../../models/Form/CoachRequest";
import { classNames } from "../../utils/styles";

const statuses: Record<CoachRequestStatus, string> = {
  [CoachRequestStatus.ACCEPTED]: "fill-green-500",
  [CoachRequestStatus.PENDING]: "fill-orange-400",
  [CoachRequestStatus.REJECTED]: "fill-red-500"
};

type OwnProps = {
  status: CoachRequestStatus;
};

const CoachRequestStatusBadge: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "coachRequests"
  });
  const { status } = props;
  const componentClass = statuses[status];

  return (
    <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
      <svg
        className={classNames("h-1.5 w-1.5", componentClass)}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {t(`statuses.${status}`)}
    </span>
  );
};

export default CoachRequestStatusBadge;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorAction } from "../../models/Actions";
import { Employee } from "../../models/Employee/Employee";
import { BaseForm } from "../../models/Form/BaseForm";
import { HolaMember } from "../../models/integrations/HolaSpirit/HolaMember";
import { RequestStatus } from "../../models/Request";

interface CurrentEmployeeState {
  status: RequestStatus;
  data?: Employee;
  holaMember?: HolaMember;
  documents?: BaseForm[];
  error?: Error;
}

const initialState: CurrentEmployeeState = {
  status: RequestStatus.IDLE,
};

const currentEmployeeSlice = createSlice({
  name: "currentEmployee",
  initialState,
  reducers: {
    loadEmployeeDocuments(
      state,
      action: PayloadAction<{ employeeId: string; cycleId: string }>
    ) {
      state.status = RequestStatus.PENDING;
    },
    loadEmployeeDocumentsSuccess(
      state,
      action: PayloadAction<{ data: BaseForm[] }>
    ) {
      const { data } = action.payload;
      state.documents = data;
      state.status = RequestStatus.SUCCESS;
    },
    loadEmployeeDocumentsError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    },
    setEmployeeData(
      state,
      action: PayloadAction<{ data: Employee; holaMember?: HolaMember }>
    ) {
      const { data, holaMember } = action.payload;
      state.data = data;
      state.holaMember = holaMember;
    },
  },
});

export const {
  loadEmployeeDocuments,
  loadEmployeeDocumentsSuccess,
  loadEmployeeDocumentsError,
  setEmployeeData,
} = currentEmployeeSlice.actions;

export default currentEmployeeSlice.reducer;

import { FC, ReactElement, useState } from "react";

import { CheckIcon, PlusIcon, XCircleIcon } from "@heroicons/react/20/solid";

import { Employee } from "../../models/Employee/Employee";
import { getAvatarUrl } from "../../utils/user";
import UserAvatar from "../users/UserAvatar";

type OwnProps = {
  options: Partial<Employee>[];
  selected: Partial<Employee>[];
  disabled?: boolean;
  onChange: (participants: Partial<Employee>[]) => void;
};

const MeetingParticipants: FC<OwnProps> = (props): ReactElement => {
  const { options, selected, disabled = false, onChange } = props;

  const [participants, setParticipants] =
    useState<Partial<Employee>[]>(selected);

  const onRemoveParticipant = (participant: Partial<Employee>) => {
    const updatedParticipants = participants.filter(
      (p) => p._id !== participant._id
    );
    setParticipants(updatedParticipants);
    onChange(updatedParticipants);
  };

  const onAddParticipant = (participant: Partial<Employee>) => {
    const updatedParticipants = [...participants, participant];
    setParticipants(updatedParticipants);
    onChange(updatedParticipants);
  };

  return (
    <div>
      <ul className="mt-2">
        {options.map((participant) => {
          const isParticipant = participants.some(
            (p) => p._id === participant._id
          );

          return (
            <li
              className="flex items-center justify-between py-3"
              key={participant._id}
            >
              <UserAvatar
                name={participant.name!}
                pictureUrl={getAvatarUrl(participant.holaMember)}
                showName
              />
              <div className="participant-actions flex items-center">
                {isParticipant && (
                  <CheckIcon
                    className="ml-6 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                )}
                {!disabled && !isParticipant && (
                  <button
                    type="button"
                    className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => onAddParticipant(participant)}
                  >
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                )}
                {!disabled && isParticipant && (
                  <button
                    type="button"
                    className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => onRemoveParticipant(participant)}
                  >
                    <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MeetingParticipants;

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateResourceNote } from "../../services/NoteService";

export const useEditResourceNote = (
  formUUID: string,
  resourceType: string,
  resourceId: string,
  noteId: string
) => {
  const queryClient = useQueryClient();

  return useMutation({
    // mutationKey: ["notes", formUUID, resourceType, resourceId, noteId],
    mutationFn: (payload: { body: string }) =>
      updateResourceNote(formUUID, resourceType, resourceId, noteId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notes", formUUID, resourceType, resourceId]
      });
    }
  });
};

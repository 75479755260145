import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  CubeTransparentIcon, FireIcon, PuzzlePieceIcon, RocketLaunchIcon
} from "@heroicons/react/24/outline";

import { DocumentMetrics } from "../../models/Form/DocumentMetrics";
import DocumentListMetric from "./DocumentListMetric";

type OwnProps = {
  metrics: DocumentMetrics;
};

const DocumentListMetrics: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { metrics } = props;

  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      <DocumentListMetric
        id={"objectives"}
        icon={RocketLaunchIcon}
        label={t("metrics.totalObjectives")}
        metric={metrics["objectives"]}
        color={"teal"}
      />
      <DocumentListMetric
        id={"competences"}
        icon={PuzzlePieceIcon}
        label={t("metrics.totalCompetences")}
        metric={metrics["competences"]}
        color={"orange"}
      />
      <DocumentListMetric
        id={"devActions"}
        icon={CubeTransparentIcon}
        label={t("metrics.totalDevActions")}
        metric={metrics["developmentActions"]}
        color={"violet"}
      />
      <DocumentListMetric
        id={"values"}
        icon={FireIcon}
        label={t("metrics.totalValues")}
        metric={metrics["values"]}
        color={"rose"}
      />
    </dl>
  );
};

export default DocumentListMetrics;

import { AxiosResponse, CancelToken } from "axios";

import { Employee } from "../models/Employee/Employee";
import { BaseForm } from "../models/Form/BaseForm";
import { ApiV1 } from "../utils/api";

/**
 * Retrieves the team list for a given employee (manager) and cycle.
 * @param cancelToken - A token used to cancel the request if needed.
 * @param employeeId - The ID of the employee whose team list is being retrieved.
 * @param cycleId - The ID of the cycle for which the team list is being retrieved.
 * @returns A promise that resolves to an array of BaseForm objects representing the team list.
 */
export const getTeamlist = async (
  cancelToken: CancelToken,
  employeeId: string,
  cycleId: string
): Promise<BaseForm[]> => {
  // TODO: Use new endpoint
  const url = `/team/${employeeId}/docs?cycle_id=${cycleId}&doctype=target`;

  try {
    return await ApiV1.get(url, {
      cancelToken,
    }).then((response: AxiosResponse) => {
      const documents: BaseForm[] = response.data.data;

      return documents;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const getTeamlistMetrics = async (
  cancelToken: CancelToken,
  employeeId: string,
  cycleId: string
): Promise<BaseForm[]> => {
  const url = `/team/${employeeId}/metrics`;
  const params: Record<string, string> = {
    relatedCycle: cycleId,
    relatedPhase: "target",
    isGlobal: "false",
  };
  const fullUrl = `${url}?${new URLSearchParams(params)}`;

  try {
    return await ApiV1.get(fullUrl, {
      cancelToken,
    }).then((response: AxiosResponse) => {
      const documents: BaseForm[] = response.data.data;

      return documents;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Retrieves the team for a given cycle.
 * @param cancelToken - A token used to cancel the request if needed.
 * @param cycleId - The ID of the cycle for which the team is being retrieved.
 * @returns A promise that resolves to an array of Employee objects representing the team managers.
 */
export const getTeamByCycle = async (
  cancelToken: CancelToken,
  cycleId: string
): Promise<Employee[]> => {
  const url = `/managers?cycle=${cycleId}`;

  try {
    return await ApiV1.get(url, {
      cancelToken,
    }).then((response: AxiosResponse) => {
      const managers: Employee[] = response.data.data;

      return managers;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

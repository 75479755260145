import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorAction } from "../../../models/Actions";
import { BaseObjective, RawBaseObjective } from "../../../models/Form/BaseObjective";
import { HolaOKR } from "../../../models/integrations/HolaSpirit/HolaOKR";
import { RequestStatus } from "../../../models/Request";

interface CurrentDocumentObjectivesState {
  status: RequestStatus;
  objectives?: BaseObjective[];
  error?: Error;
  actionStatuses: {
    [key: string]: {
      status: RequestStatus;
      error?: string;
    };
  };
}

const initialState: CurrentDocumentObjectivesState = {
  status: RequestStatus.IDLE,
  actionStatuses: {}
};

const currentDocumentObjectivesSlice = createSlice({
  name: "currentDocumentObjectives",
  initialState,
  reducers: {
    // Load the document objectives
    setDocumentObjectives(
      state,
      action: PayloadAction<{ objectives: BaseObjective[] }>
    ) {
      const { objectives } = action.payload;
      state.objectives = objectives;
    },

    // Create a new objective for the current document.
    createDocumentObjective(
      state,
      action: PayloadAction<{ formUUID: string; data: RawBaseObjective }>
    ) {
      state.actionStatuses[createDocumentObjective.type] = {
        status: RequestStatus.PENDING
      };
    },
    createDocumentObjectiveSuccess(
      state,
      action: PayloadAction<{ data: BaseObjective }>
    ) {
      const { data } = action.payload;
      state.objectives?.push(data);
      state.actionStatuses[createDocumentObjective.type].status =
        RequestStatus.SUCCESS;
    },
    createDocumentObjectiveError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[createDocumentObjective.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearCreateDocumentObjectiveStatus(state) {
      delete state.actionStatuses[createDocumentObjective.type];
    },

    // Update an existing objective for the current document.
    updateDocumentObjective(
      state,
      action: PayloadAction<{
        formUUID: string;
        objectiveId: string;
        data: RawBaseObjective;
      }>
    ) {
      state.actionStatuses[updateDocumentObjective.type] = {
        status: RequestStatus.PENDING
      };
    },
    updateDocumentObjectiveSuccess(
      state,
      action: PayloadAction<{ data: BaseObjective }>
    ) {
      const { data } = action.payload;
      const index = state.objectives?.findIndex(
        (objective) => objective._id === data._id
      );
      if (index !== undefined && index !== -1) {
        state.objectives?.splice(index, 1, data);
      }
      state.actionStatuses[updateDocumentObjective.type].status =
        RequestStatus.SUCCESS;
    },
    updateDocumentObjectiveError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[updateDocumentObjective.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearUpdateDocumentObjectiveStatus(state) {
      delete state.actionStatuses[updateDocumentObjective.type];
    },

    // Add OKR to the current to an objective for the current document.
    addDocumentObjectiveOKR(
      state,
      action: PayloadAction<{
        objectiveId: string;
        okr: HolaOKR;
      }>
    ) {
      const { objectiveId, okr } = action.payload;
      // Find the objective with the given ID.
      const objective = state.objectives?.find(
        (objective) => objective._id === objectiveId
      );

      // Add the OKR to the objective.
      if (objective) {
        objective.okrs.push(okr);
      }
    },
    removeDocumentObjectiveOKR(
      state,
      action: PayloadAction<{
        objectiveId: string;
        okrId: string;
      }>
    ) {
      const { objectiveId, okrId } = action.payload;
      // Find the objective with the given ID.
      const objective = state.objectives?.find(
        (objective) => objective._id === objectiveId
      );

      // Remove the OKR from the objective.
      if (objective) {
        const index = objective.okrs.findIndex((okr) => okr._id === okrId);
        if (index !== undefined && index !== -1) {
          objective.okrs.splice(index, 1);
        }
      }
    },

    // Set an existing objective as completed for the current document.
    completeDocumentObjective(
      state,
      action: PayloadAction<{ formUUID: string; objectiveId: string }>
    ) {
      state.actionStatuses[completeDocumentObjective.type] = {
        status: RequestStatus.PENDING
      };
    },
    completeDocumentObjectiveSuccess(
      state,
      action: PayloadAction<{ data: BaseObjective }>
    ) {
      const { data } = action.payload;
      const index = state.objectives?.findIndex(
        (objective) => objective._id === data._id
      );
      if (index !== undefined && index !== -1) {
        state.objectives?.splice(index, 1, data);
      }
      state.actionStatuses[completeDocumentObjective.type].status =
        RequestStatus.SUCCESS;
    },
    completeDocumentObjectiveError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[completeDocumentObjective.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearCompleteDocumentObjectiveStatus(state) {
      delete state.actionStatuses[completeDocumentObjective.type];
    },

    // Delete an existing objective for the current document.
    deleteDocumentObjective(
      state,
      action: PayloadAction<{ formUUID: string; objectiveId: string }>
    ) {
      state.actionStatuses[deleteDocumentObjective.type] = {
        status: RequestStatus.PENDING
      };
    },
    deleteDocumentObjectiveSuccess(
      state,
      action: PayloadAction<{ objectiveId: string }>
    ) {
      const { objectiveId } = action.payload;
      const index = state.objectives?.findIndex(
        (objective) => objective._id === objectiveId
      );
      if (index !== undefined && index !== -1) {
        state.objectives?.splice(index, 1);
      }
      state.actionStatuses[deleteDocumentObjective.type].status =
        RequestStatus.SUCCESS;
    },
    deleteDocumentObjectiveError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[deleteDocumentObjective.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearDeleteDocumentObjectiveStatus(state) {
      delete state.actionStatuses[deleteDocumentObjective.type];
    }
  }
});

export const {
  setDocumentObjectives,
  createDocumentObjective,
  createDocumentObjectiveSuccess,
  createDocumentObjectiveError,
  clearCreateDocumentObjectiveStatus,
  updateDocumentObjective,
  updateDocumentObjectiveSuccess,
  updateDocumentObjectiveError,
  clearUpdateDocumentObjectiveStatus,
  addDocumentObjectiveOKR,
  removeDocumentObjectiveOKR,
  completeDocumentObjective,
  completeDocumentObjectiveSuccess,
  completeDocumentObjectiveError,
  clearCompleteDocumentObjectiveStatus,
  deleteDocumentObjective,
  deleteDocumentObjectiveSuccess,
  deleteDocumentObjectiveError,
  clearDeleteDocumentObjectiveStatus
} = currentDocumentObjectivesSlice.actions;

export default currentDocumentObjectivesSlice.reducer;

import { useMutation } from "@tanstack/react-query";

import { addCourseToDevAction } from "../../../services/DevActionService";

export const useAddCourseToDevAction = (
  formUUID: string,
  devActionId: string
) => {
  return useMutation({
    mutationFn: (courseId: string) =>
      addCourseToDevAction(formUUID, devActionId, courseId)
  });
};

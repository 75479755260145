import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import { createStructuredSelector } from "reselect";

import { selectCurrentDocument } from "../../../redux/currentDocument/selectors";
import { selectCurrentEmployee } from "../../../redux/currentEmployee/selectors";
import PerformanceCareer from "./career/PerformanceCareer";
import PerformanceCompetences from "./competences/PerformanceCompetences";
import PerformanceConclusion from "./conclusion/PerformanceConclusion";
import PerformanceDevActions from "./devActions/PerformanceDevActions";
import PerformanceObjectives from "./objectives/PerformanceObjectives";
import PerformanceValues from "./values/PerformanceValues";

const mapStateToProps = createStructuredSelector({
  employee: selectCurrentEmployee,
  document: selectCurrentDocument
});

type OwnProps = ReduxProps;

const PerformanceDetail: FC<OwnProps> = (props) => {
  const { document, employee } = props;
  const { section } = useParams();

  if (!document || !employee) return null;
  // Define roles
  const isManager = employee?._id === document.relatedManager._id;
  const isOwner = employee?._id === document.relatedEmployee._id;

  switch (section) {
    case "objectives":
      return (
        <PerformanceObjectives
          documentId={document.formUUID}
          employee={employee}
          isManager={isManager}
        />
      );
    case "competences":
      return (
        <PerformanceCompetences
          documentId={document.formUUID}
          employee={employee}
        />
      );
    case "development-actions":
      return (
        <PerformanceDevActions
          documentId={document.formUUID}
          employee={employee}
        />
      );
    case "lead":
      return (
        <PerformanceValues documentId={document.formUUID} employee={employee} />
      );
    case "career":
      return (
        <PerformanceCareer
          document={document}
          isOwner={isOwner}
          isManager={isManager}
        />
      );
    case "conclusion":
      return (
        <PerformanceConclusion
          document={document}
          isOwner={isOwner}
          isManager={isManager}
        />
      );
    default:
      return <div>Overview</div>;
  }
};

const withConnect = connect(mapStateToProps, null);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceDetail);

import { CancelToken } from "axios";

import { BaseValue, RawBaseValue, ValueType } from "../models/Form/BaseValue";
import API, { ApiV1 } from "../utils/api";

export const getValueTypes = async (
  cancelToken: CancelToken
): Promise<ValueType[]> => {
  const url = "/valuetypes";

  try {
    const response = await ApiV1.get<{ data: ValueType[] }>(url, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const createNewValue = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseValue
): Promise<BaseValue> => {
  const url = `/form/${formUUID}/values`;

  try {
    const response = await API.post<{ data: BaseValue }>(url, payload, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const updateValue = async (
  cancelToken: CancelToken,
  formUUID: string,
  valueId: string,
  payload: RawBaseValue
): Promise<BaseValue> => {
  const url = `/form/${formUUID}/values/${valueId}`;

  try {
    const response = await API.put<{ data: BaseValue }>(url, payload, {
      cancelToken
    });

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const deleteValue = async (
  cancelToken: CancelToken,
  formUUID: string,
  valueId: string
): Promise<void> => {
  const url = `/form/${formUUID}/values/${valueId}`;

  try {
    await API.delete(url, {
      cancelToken
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

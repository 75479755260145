import { combineReducers } from "redux";

import currentApp from "../redux/currentApp/slice";
import currentDocumentCompetences from "../redux/currentDocument/competences/slice";
import currentDocumentDevActions from "../redux/currentDocument/devActions/slice";
import currentDocuementObjectives from "../redux/currentDocument/objectives/slice";
import currentDocument from "../redux/currentDocument/slice";
import currentDocumentValues from "../redux/currentDocument/values/slice";
import currentEmployee from "../redux/currentEmployee/slice";
import currentTeamlist from "../redux/currentTeamlist/slice";
import currentUser from "../redux/currentUser/slice";

const rootReducer = combineReducers({
  currentApp,
  currentUser,
  currentEmployee,
  currentTeamlist,
  currentDocument: combineReducers({
    document: currentDocument,
    objectives: currentDocuementObjectives,
    competences: currentDocumentCompetences,
    developmentActions: currentDocumentDevActions,
    values: currentDocumentValues
  })
});

export default rootReducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorAction } from "../../models/Actions";
import { RequestStatus } from "../../models/Request";
import { User } from "../../models/User";

interface CurrentUserState {
  status: RequestStatus;
  data?: User;
  error?: string;
}

const initialState: CurrentUserState = {
  status: RequestStatus.IDLE
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    loadCurrentUser(state) {
      state.status = RequestStatus.PENDING;
    },
    loadCurrentUserSuccess(state, action: PayloadAction<{ data: User }>) {
      const { data } = action.payload;
      state.data = data;
      state.status = RequestStatus.SUCCESS;
    },
    loadCurrentUserError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.error = (payload as string) ?? "error";
      state.status = RequestStatus.ERROR;
    }
  }
});

export const { loadCurrentUser, loadCurrentUserSuccess, loadCurrentUserError } =
  currentUserSlice.actions;

export default currentUserSlice.reducer;

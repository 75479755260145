import { createSelector } from "reselect";

import { RequestStatus } from "../../models/Request";
import { RootState } from "../../store/store";
import {
  removeDocumentCoach,
  setDiscussionDate,
  updateDocument
} from "./slice";

export const selectCurrentDocumentState = (state: RootState) =>
  state.currentDocument.document;

export const selectCurrentDocument = createSelector(
  selectCurrentDocumentState,
  (state) => state.document
);

export const isLoadingCurrentDocument = createSelector(
  selectCurrentDocumentState,
  (state) => state.status === RequestStatus.PENDING
);

export const isLoadingUpdateCurrentDocument = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[updateDocument.type]?.status === RequestStatus.PENDING
);

export const selectUpdateCurrentDocumentStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[updateDocument.type]?.status
);

export const selectUpdateCurrentDocumentError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[updateDocument.type]?.error
);

export const isLoadingSetDiscussionDate = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[setDiscussionDate.type]?.status ===
    RequestStatus.PENDING
);

export const selectSetDiscussionDateStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[setDiscussionDate.type]?.status
);

export const selectSetDiscussionDateError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[setDiscussionDate.type]?.error
);

export const selectCurrentDocumentMode = createSelector(
  selectCurrentDocumentState,
  (state) => state.mode
);

export const selectCurrentDocuementPhase = createSelector(
  selectCurrentDocumentState,
  (state) => state.document?.relatedPhase
);

export const isLoadingRemoveDocumentCoach = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[removeDocumentCoach.type]?.status ===
    RequestStatus.PENDING
);

export const selectRemoveDocumentCoachStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[removeDocumentCoach.type]?.status
);

export const selectRemoveDocumentCoachError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[removeDocumentCoach.type]?.error
);

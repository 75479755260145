import { ClassicEditor } from "ckeditor5";
import { Form, Formik } from "formik";
import { FC, ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useEditResourceNote } from "../../hooks/notes/useEditResourceNote";
import { FormNote } from "../../models/Form/FormNote";
import {
  NotificationConfig,
  NotificationType
} from "../../models/Notification";
import InputRichText from "../forms/InputRichText";

type OwnProps = {
  documentId: string;
  note: FormNote;
  onCancel: () => void;
  notify: (notification: NotificationConfig) => void;
};

const EditNote: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", { keyPrefix: "notes" });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { documentId, note, onCancel, notify } = props;

  const mutation = useEditResourceNote(
    documentId,
    note.resourceType,
    note.resourceId,
    note._id
  );

  // Component state
  const editorRef = useRef<ClassicEditor>(null);
  const initialState = {
    body: note.body
  };

  const validationSchema = Yup.object({
    body: Yup.string()
      .max(500, "Must be 500 characters or less")
      .required("Required")
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        // Mutate the note
        mutation.mutate(values);

        // Notify
        notify({
          type: NotificationType.SUCCESS,
          title: tCommon("notifications.editSuccess"),
          message: t("editNoteSuccess")
        });

        // Clear the editor
        if (editorRef?.current != null) {
          editorRef.current.setData("");
        }

        onCancel();
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <InputRichText
            name="body"
            initialValue={note.body}
            onChange={(value: string) => {
              setFieldValue("body", value);
            }}
            innerRef={editorRef}
          />
          <div className="mt-3 flex items-center justify-end">
            <button
              type="button"
              className="inline-flex items-center justify-center mr-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              {tCommon("actions.cancel")}
            </button>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={isSubmitting}
            >
              {t("saveNote")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditNote;

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { CompetenceType } from "../../models/Form/BaseCompetence";
import { classNames } from "../../utils/styles";

const statuses: Record<CompetenceType, string> = {
  [CompetenceType.FUNCTIONAL]: "fill-red-500",
  [CompetenceType.GENERIC]: "fill-green-500",
  [CompetenceType.SPECIFIC]: "fill-blue-500"
};

type OwnProps = {
  type: CompetenceType;
};

const CompetenceTypeBadge: FC<OwnProps> = (props): ReactElement => {
  const { t: tStatuses } = useTranslation("translation", {
    keyPrefix: "competences"
  });
  const { type } = props;
  const componentClass = statuses[type];

  return (
    <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
      <svg
        className={classNames("h-1.5 w-1.5", componentClass)}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {tStatuses(`types.${type}`)}
    </span>
  );
};

export default CompetenceTypeBadge;

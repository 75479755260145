import { useMutation } from "@tanstack/react-query";

import { removeCourseFromDevAction } from "../../../services/DevActionService";

export const useRemoveCourseFromDevAction = (
  formUUID: string,
  devActionId: string
) => {
  return useMutation({
    mutationFn: (courseId: string) =>
      removeCourseFromDevAction(formUUID, devActionId, courseId)
  });
};

import { createSelector } from "reselect";

import { RequestStatus } from "../../models/Request";
import { RootState } from "../../store/store";
import { loadCurrentTeamlist, loadTeamManagers } from "./slice";

const selectCurrentTeamlistState = (state: RootState) => state.currentTeamlist;

export const selectCurrentTeamlist = createSelector(
  selectCurrentTeamlistState,
  (state) => state.data
);

export const isLoadingCurrentTeamlist = createSelector(
  selectCurrentTeamlistState,
  (state) =>
    state.actionStatuses[loadCurrentTeamlist.type]?.status ===
    RequestStatus.PENDING
);

export const selectTeamManagers = createSelector(
  selectCurrentTeamlistState,
  (state) => state.teamManagers
);

export const isLoadingTeamManagers = createSelector(
  selectCurrentTeamlistState,
  (state) =>
    state.actionStatuses[loadTeamManagers.type]?.status ===
    RequestStatus.PENDING
);

export const selectSelectedTeamManager = createSelector(
  selectCurrentTeamlistState,
  (state) => state.selectedTeamManager
);

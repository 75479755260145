import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { PencilSquareIcon } from "@heroicons/react/20/solid";

import ActionButton from "../../../../components/buttons/ActionButton";
import DefaultButton from "../../../../components/buttons/DefaultButton";
import { BaseObjective } from "../../../../models/Form/BaseObjective";
import { formatISODateStr } from "../../../../utils/dates";

type OwnProps = {
  objective: BaseObjective;
  onEdit?: () => void;
  onComplete?: () => void;
};

const ObjectiveHeader: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { objective, onEdit, onComplete } = props;
  const ownerName = `${objective.createdBy.name.first} ${objective.createdBy.name.last}`;

  return (
    <>
      <div className="flex items-center space-x-5">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            {objective.title ?? objective.description.slice(0, 50)}
          </h1>
          <p className="mt-2 text-sm text-gray-500">
            {t("common.actions.createdBy")}
            <span className="ml-1 font-medium text-gray-900">
              {ownerName}
            </span>{" "}
            {t("common.datetime.on")}
            <time
              className="ml-1 font-medium text-gray-900"
              dateTime={objective.createdAt}
            >
              {formatISODateStr(objective.createdAt)}
            </time>
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {onEdit && (
          <DefaultButton
            label={t("objectives.actions.edit")}
            onClick={onEdit}
            icon={PencilSquareIcon}
            disabled={objective.isCompleted}
          />
        )}
        {onComplete && (
          <ActionButton
            label={t("objectives.actions.setAsCompleted")}
            onClick={onComplete}
            disabled={objective.isCompleted}
          />
        )}
      </div>
    </>
  );
};

export default ObjectiveHeader;

import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

import { RawBaseFormConclusion } from "../../../../models/Form/BaseForm";

type OwnProps = {
  conclusion?: RawBaseFormConclusion;
};

const PerformanceConclusionDetail: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "conclusion"
  });
  const { conclusion } = props;

  return (
    <section aria-labelledby="career-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="career-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {t("meta.title")}
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("fields.link")}</p>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {conclusion?.linkedFile ? (
                  <a
                    href={conclusion?.linkedFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {conclusion?.linkedFile}
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block ml-1" />
                  </a>
                ) : (
                  "N/A"
                )}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("fields.description")}</p>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {conclusion?.employeeComments ?? "N/A"}
              </dd>
            </div>
            <div className="sm:col-span-3">
              <dt className="mb-3 text-sm font-medium text-blue-700">
                <p>{t("fields.comments")}</p>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 italic">
                {conclusion?.managerComments ?? "N/A"}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};

export default PerformanceConclusionDetail;

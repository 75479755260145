import { FC, ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import UserCoachRequestsList from "../../components/coachRequests/UserCoachRequestsList";
import DocumentListItem from "../../components/documents/DocumentListItem";
import DocumentListMetrics from "../../components/documents/DocumentListMetrics";
import SubSectionHeader from "../../components/headings/SubSectionHeader";
import Spinner from "../../components/loaders/Spinner";
import SubNavBar from "../../components/navigation/SubNavBar";
import CycleSelector from "../../components/selectors/CycleSelector";
import { selectCurrentCycle } from "../../redux/currentApp/selectors";
import {
  isLoadingEmployeeDocuments,
  selectCurrentEmployee,
  selectEmployeeDocuments
} from "../../redux/currentEmployee/selectors";
import { loadEmployeeDocuments } from "../../redux/currentEmployee/slice";
import { coachLinks, guideLinks, statusLinks } from "../../utils/links";
import { DocumentMetrics } from "../../models/Form/DocumentMetrics";

const mapStateToProps = createStructuredSelector({
  currentEmployee: selectCurrentEmployee,
  currentCycle: selectCurrentCycle,
  isLoading: isLoadingEmployeeDocuments,
  documents: selectEmployeeDocuments
});

const mapDispatchToProps = {
  loadEmployeeDocuments
};

type OwnProps = ReduxProps;

const MyPerformance: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const {
    currentEmployee,
    currentCycle,
    isLoading,
    documents,
    loadEmployeeDocuments
  } = props;

  useEffect(() => {
    if (currentEmployee?._id != null && currentCycle?._id != null) {
      loadEmployeeDocuments({
        employeeId: currentEmployee._id,
        cycleId: currentCycle._id
      });
    }
  }, [loadEmployeeDocuments, currentCycle?._id, currentEmployee?._id]);

  const documentMetrics = useMemo(() => {
    if (documents == null) return null;

    // TODO: add comparator
    const metrics: DocumentMetrics = {
      objectives: {
        current: 0
        // comparator: 0
      },
      competences: {
        current: 0
        // comparator: 0
      },
      developmentActions: {
        current: 0
        // comparator: 0
      },
      values: {
        current: 0
        // comparator: 0
      }
    };

    documents.forEach((document) => {
      metrics.objectives.current += document.objectives.length;
      metrics.competences.current += document.competences.length;
      metrics.developmentActions.current += document.developmentActions.length;
      metrics.values.current += document.values.length;
    });

    return metrics;
  }, [documents]);

  return (
    <>
      {isLoading ? (
        <div className="text-center m-10">
          <Spinner />
        </div>
      ) : (
        <>
          <SubNavBar
            breadcrumbs={[
              t("navigation.myPerformance"),
              t("common.breadcrumps.overview")
            ]}
            linkUrl={guideLinks?.[currentEmployee?.lang ?? "fr"]}
            linkLabel={t("navigation.guide")}
          />
          <main className="flex flex-col mx-auto max-w-7xl">
            {/* Filters: cycle */}
            <div className="py-4 px-4 sm:px-6 lg:px-8">
              <CycleSelector lang={"en"} currentCycle={currentCycle} />
            </div>

            {/* Documents metrics */}
            <div className="border-t border-white/10">
              <h2 className="px-4 text-base font-semibold leading-7 sm:px-6 lg:px-8">
                {t("metrics.title")}
              </h2>
              <div className="px-4 sm:px-6 lg:px-8">
                {documentMetrics != null && (
                  <DocumentListMetrics metrics={documentMetrics} />
                )}
              </div>
            </div>
            {/* Documents */}
            <div className="border-t border-white/10 pt-11">
              <div className="sm:px-6 lg:px-8">
                <SubSectionHeader
                  title={t("myPerformance.sectionTitle")}
                  link={statusLinks?.[currentEmployee?.lang ?? "fr"]}
                  linkLabel={t("myPerformance.infoLinkLabel")}
                />
              </div>
              <div className="mt-5 px-4 sm:px-6 lg:px-8">
                {documents != null ? (
                  <ul className="divide-y divide-gray-100">
                    {documents.map((document) => (
                      <DocumentListItem
                        key={document._id}
                        document={document}
                        readOnly={
                          currentEmployee?.employer.identifiant === 999999
                        }
                      />
                    ))}
                  </ul>
                ) : (
                  <div>No documents found</div>
                )}
              </div>
            </div>
            {/* Pending coach requests */}
            {currentEmployee && currentCycle && (
              <div className="border-t border-white/10 pt-11">
                {/* <h2 className="px-4 text-base font-semibold leading-7 sm:px-6 lg:px-8">
                  {t("coachRequests.title")}
                </h2> */}
                <div className="sm:px-6 lg:px-8">
                  <SubSectionHeader
                    title={t("coachRequests.title")}
                    link={coachLinks?.[currentEmployee?.lang ?? "fr"]}
                    linkLabel={t("myPerformance.coachInfoLinkLabel")}
                  />
                </div>
                <div className="mt-5 px-4 sm:px-6 lg:px-8">
                  <UserCoachRequestsList
                    employee={currentEmployee}
                    cycle={currentCycle}
                  />
                </div>
              </div>
            )}
          </main>
        </>
      )}
    </>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(MyPerformance);

import Axios, { CancelTokenSource } from "axios";

import { call, put, takeLatest } from "@redux-saga/core/effects";

import { UserData } from "../../models/User";
import { getCurrentUser } from "../../services/UserService";
import { setEmployeeData } from "../currentEmployee/slice";
import { loadCurrentUser, loadCurrentUserError, loadCurrentUserSuccess } from "./slice";

function* getcurrentUserSaga() {
  const signal: CancelTokenSource = Axios.CancelToken.source();

  try {
    const data: UserData = yield call(getCurrentUser, signal.token);

    // If user is undefined, redirect to login page.
    if (data == null) {
      window.location.href = "/auth/login";
    }

    const { employeeData, holaMember, ...user } = data;

    const avatarUrl =
      holaMember?.avatarUrl != null
        ? `https://app.holaspirit.com/${holaMember.avatarUrl}`
        : undefined;

    yield put(
      loadCurrentUserSuccess({
        data: { ...user, avatarUrl }
      })
    );
    yield put(setEmployeeData({ data: employeeData, holaMember }));
  } catch (error: any) {
    console.log(error);
    yield put(
      loadCurrentUserError({ error: error.message, payload: error.message })
    );

    // Redirect to auth page if the user is not authenticated.
    if (error.response?.status === 401) {
      window.location.href = "/auth/login";
    }
  }
}

export default function* currentUserSaga() {
  yield takeLatest(loadCurrentUser.type, getcurrentUserSaga);
}

import { ClassicEditor } from "ckeditor5";
import { Form, Formik } from "formik";
import { FC, ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useAddResourceNote } from "../../hooks/notes/useAddResourceNote";
import {
  NotificationConfig,
  NotificationType
} from "../../models/Notification";
import InputRichText from "../forms/InputRichText";

type OwnProps = {
  documentId: string;
  resourceType: string;
  resourceId: string;
  notify: (notification: NotificationConfig) => void;
};

const AddNewNote: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", { keyPrefix: "notes" });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { documentId, resourceType, resourceId, notify } = props;

  const mutation = useAddResourceNote(documentId, resourceType, resourceId);

  // Component state
  const editorRef = useRef<ClassicEditor>(null);
  const initialState = {
    body: ""
  };

  const validationSchema = Yup.object({
    body: Yup.string()
      .max(5000, "Must be 5000 characters or less")
      .required("Required")
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        // Mutate the note
        mutation.mutate(values);

        // Notify
        notify({
          type: NotificationType.SUCCESS,
          title: tCommon("notifications.saveSuccess"),
          message: t("addNoteSuccess")
        });

        // Reset form
        actions.resetForm({ values: initialState });

        // Clear the editor
        if (editorRef?.current != null) {
          editorRef.current.setData("");
        }
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <InputRichText
            name="body"
            onChange={(value: string) => {
              setFieldValue("body", value);
            }}
            innerRef={editorRef}
          />
          <div className="mt-3 flex items-center justify-between">
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={isSubmitting}
            >
              {t("addNote")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewNote;
